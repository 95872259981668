import PageLoader from '../components/PageLoader/PageLoader';
import usePrize from '../hooks/usePrize';
import { useNavigate } from 'react-router-dom';
import { doublePrizeData, singlePrizeData } from '../lib/static-data';
import CtaButtonLayout from '../components/CtaButtonLayout/CtaButtonLayout';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import MetaTags from '../components/meta-tags';
import TicketSelectorContainer from '../containers/ticket-selector/ticket-selector';

function TicketSelectorPage(props) {
  const navigate = useNavigate();
  const {
    raffle,
    prizeId,
    prizeLoading,
    raffles,
    isExpired,
    activeRaffles,
    subscriptionModels,
    isDoublePrize,
  } = usePrize();

  const onCTAClick = () => navigate(`/ticket-selector`);
  const onLearnMoreClick = () => navigate(`/how-it-works`);
  const onLearnMoreCharityClick = () => navigate(`/charity-raffle`);
  const onLogoClick = () => navigate(`/`);

  return (
    <>
      <MetaTags />
      {prizeLoading ? (
        <CtaButtonLayout isDark>
          <Header raffle={raffle} />
          <PageLoader loading />
          <Footer insideComponent={true} />
        </CtaButtonLayout>
      ) : (
        <CtaButtonLayout isDark>
          <Header raffle={raffle} onLogoClick={onLogoClick} />
          <TicketSelectorContainer
            prizeId={prizeId}
            raffle={raffle}
            raffles={raffles}
            subscriptionModels={subscriptionModels}
            activeRaffles={activeRaffles}
            isExpired={isExpired}
            data={isDoublePrize ? doublePrizeData : singlePrizeData}
            onCTAClick={onCTAClick}
            onLearnMoreClick={onLearnMoreClick}
            onLearnMoreCharityClick={onLearnMoreCharityClick}
            onLogoClick={onLogoClick}
          />
        </CtaButtonLayout>
      )}
    </>
  );
}

export default TicketSelectorPage;
