import React from 'react';

import { Routes, Route, Navigate } from 'react-router-dom';
import HomePage from './pages/home';
import TicketSelectorPage from './pages/ticket-selector';
const AppRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<HomePage />} />
      {/* <Route path='/ticket-selector' element={<TicketSelectorPage />} /> */}
      <Route path='*' element={<Navigate to='/' replace />} />
    </Routes>
  );
};

export default AppRoutes;
