import React, { useEffect, useState } from 'react';
import './styles.css';
import { dateDiff } from '../../utils';

const timePeriodsNames = {
  Days: 'DAYS',
  Hours: 'HRS',
  Minutes: 'MIN',
  Seconds: 'SEC',
}

export const Countdown = (props) => {
  const { endsAt } = props;
  const { prizeType } = props;
  const [state, setState] = useState({
    Days: '--',
    Hours: '--',
    Minutes: '--',
    Seconds: '--',
  });

  function getTime() {
    const endRaffleDate = new Date(endsAt);
    const now = Date.now();
    const date = dateDiff(now, endRaffleDate, prizeType);
    return date;
  }
  let interval;
  useEffect(() => {
    const now = new Date().getTime() / 1000;
    const endsAtUnix = new Date(endsAt).getTime() / 1000;

    if (endsAt && endsAtUnix > now) {
      interval = setInterval(() => {
        const date = getTime();
        setState(date);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  });

  const renderTimeString = () => {
    let value = ' ';
    {
      Object.keys(state).map((k, i) => {
        if (k === 'Mths' && state['Mths'] === '00') {
          return null;
        }
        value += `${state[k].substring(0, 1)}${state[k].substring(
          1,
          state[k].length
        )}${timePeriodsNames[k]} `;
      });
    }
    // return ` ${value} (end time is 00:00:00 on feb 27)`;
    return ` ${value}`;
  };

  return (
    <div className={`countdown-block`}>
      <div className='infoModalTimes'>
        <div className='modalTimer'>
          <span>{renderTimeString()}</span>
        </div>
      </div>
    </div>
  );
};
