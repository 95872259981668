import { useEffect, useState } from 'react';
import sessionStorageService from '../services/sessionStorage';
import ticketsService from '../services/tickets';
import { tickets, pagediscountTickets } from '../lib/tickets';
import { googleAnalytics4AddItemToBasket } from '../services/tracking';
import { calculateDiscountPrice } from '../utils';
import TicketsService from '../services/tickets';
import tracking from '../helpers/tracking'

const useTickets = (prizeId) => {
  const [activeTicket, setTicket] = useState(null);
  const [loading, setLoading] = useState(false);
  let [result, setResult] = useState(null);
  let [ticketsBundles, setBundles] = useState([]);
  let [settings, setSettings] = useState(null);
  const type = String(process.env.REACT_APP_TYPE);

  const addToBasket = (ticket) => async () => {
    setLoading(true);
    setTicket(ticket.numOfTickets);
    let order = {
      numOfTickets: ticket.numOfTickets,
      prizeId: prizeId,
      prizeType: ticket.prizeType,
    };
    const createdOrder = await ticketsService.createUnAuthOrder(order);
    // tracking.twitterAddToCartTrack(createdOrder.data.order.totalCost / 100);
    // googleAnalytics4AddItemToBasket(
    //   createdOrder.data,
    //   calculateDiscountPrice(ticket),
    //   ticket.tickets * 2
    // );
    order._id = createdOrder.data.orderId;
    
    const basketData = sessionStorageService.getBasketData();
    let orderIds = [];
    if (basketData != null) {
      if (basketData.length > 0) {
        basketData.push(order);
        sessionStorageService.setBasketData(basketData);
        // const generatedToken = await ticketsService.generateBasketToken(
        //   basketData
        // );
        // ticketsService.redirectByToken(generatedToken.data.fulfillmentValue);
        orderIds = basketData.map(element => element._id);
      } else {
        sessionStorageService.setBasketData([order]);
        // const generatedToken = await ticketsService.generateBasketToken([
        //   order,
        // ]);
        // ticketsService.redirectByToken(generatedToken.data.fulfillmentValue);
        orderIds.push(order._id);
      }
    } else {
      sessionStorageService.setBasketData([order]);
      // const generatedToken = await ticketsService.generateBasketToken([order]);
      // ticketsService.redirectByToken(generatedToken.data.fulfillmentValue);
      orderIds.push(order._id);
    }

    if (orderIds.length > 0) {
      const orderIdsStr = orderIds.join(";");      
      ticketsService.redirectByOrderIds(orderIdsStr);
    }

    setLoading(false);
    setTicket(null);
  };

  useEffect(() => {
    TicketsService.calculateLandingTicketSelector({
      prizeId: prizeId,
      type: 'raffle',
      ticketsCount: 0,
      coupon: null,
      landingType: 'OFFER',
    }).then((result) => {
      let bundles = result.data.generalSettings.ticketsBundles.reverse();
      setBundles(bundles);
      setSettings(result.data.generalSettings);
      setResult(result.data);
      setLoading(false);
    });
  }, []);

  const currentTickets =
    type === 'pagediscount' ? pagediscountTickets : tickets;
  return {
    type,
    activeTicket,
    prizeId,
    loading,
    tickets: currentTickets,
    result,
    addToBasket,
    ticketsBundles,
    settings,
  };
};

export default useTickets;
