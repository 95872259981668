export function computeNewPriceRegardlessDiscount(discountRates, price) {
    const rates = discountRates.reduce((agg, item) => {
      const newPrice = roundedPrice(price - (price / 100) * item.percent);
      item.newPrice = newPrice;
      agg.push(item);
      return agg;
    }, []);
    return rates;
  }
  
  export function filterDiscountRates(discountRates, numOfTickets) {
    let filteredArray = discountRates.filter((i) => i.amountTickets <= numOfTickets);
    filteredArray.sort((a, b) => (a.amountTickets > b.amountTickets ? 1 : b.amountTickets > a.amountTickets ? -1 : 0));
    return filteredArray.pop();
  }
  
  export function filterCreditRatesWithHundreds(creditRates = [], orderTotalCost = 0) {
    let filteredArray = creditRates.filter((i) => i.count <= orderTotalCost / 100);
    filteredArray.sort((a, b) => (a.count > b.count ? 1 : b.count > a.count ? -1 : 0));
    return filteredArray.pop();
  }
  
  export function filterFreeTicketsRates(freeTicketsRates, numOfTickets) {
    let fakeFreeTicketsRates = [];
    for (let i = 0; i < freeTicketsRates.length; i++) {
      fakeFreeTicketsRates.push(freeTicketsRates[i]);
      if (i !== freeTicketsRates.length - 1) {
        let difference = freeTicketsRates[i + 1].extra - freeTicketsRates[i].extra;
        const stepsCount = freeTicketsRates[i + 1].tickets - freeTicketsRates[i].tickets;
        const step = Math.floor(difference / stepsCount);
        let count = 1;
        for (let j = freeTicketsRates[i].tickets; j < freeTicketsRates[i + 1].tickets - 1; j++) {
          const extraTickets = freeTicketsRates[i].extra === 0 ? 0 : freeTicketsRates[i].extra + step * count;
          fakeFreeTicketsRates.push({ tickets: j + 1, extra: extraTickets });
          count++;
        }
      }
    }
  
    let filteredArray = fakeFreeTicketsRates.filter((i) => i.tickets <= numOfTickets);
    filteredArray.sort((a, b) => (a.tickets > b.tickets ? 1 : b.tickets > a.tickets ? -1 : 0));
    return filteredArray.pop();
  }
  
  export function filterCreditRates(creditRates = [], orderTotalCost = 0) {
    let filteredArray = creditRates.filter((i) => i.count <= orderTotalCost);
    filteredArray.sort((a, b) => (a.count > b.count ? 1 : b.count > a.count ? -1 : 0));
    return filteredArray.pop();
  }
  
  export function priceFormat(value) {
    if (!value) {
      return `£0`;
    }
    if (value % 1 === 0) {
      return `£${value}`;
    }
    if (value < 1) {
      return `${parseInt(roundedPrice(value) * 100)}p`;
    } else {
      return `£${roundedPrice(value).toFixed(2)}`;
    }
  }
  
  export function roundedPrice(price) {
    if (!price) {
      return 0;
    }
    return Math.round((price + Number.EPSILON) * 100) / 100;
  }
  
  export function getFreeTickets(prize, general) {
    let freeTickets = [];
    if (prize.isFreeTicketsRates) {
      freeTickets = prize.freeTicketsRates;
    }
    if (freeTickets.length === 0 && general.isFreeTicketsRates) {
      freeTickets = general.freeTicketsRates;
    }
    return freeTickets;
  }
  
  export const calculateSaving = (prizeType, freeTickets = 0, ticketPrice, ticketsQuantity, defaultPrice) => {
    let saving = 0;
    if (prizeType === 'raffle') {
      const totalPrice = roundedPrice(defaultPrice * (ticketsQuantity + freeTickets));
      saving = (totalPrice - ticketPrice * ticketsQuantity).toFixed(2);
      return parseFloat(roundedPrice((saving / totalPrice) * 100).toFixed(2));
    } else {
      saving =
        ticketsQuantity > 0
          ? (roundedPrice(defaultPrice * ticketsQuantity) - roundedPrice(ticketPrice * ticketsQuantity)).toFixed(2)
          : 0;
    }
    return parseFloat(saving);
  };
  
  export const getTicketPriceConsideringFreeTickets = (prize, generalSettings, ticketsCount, discountRates) => {
    const freeTicketsRates = getFreeTickets(prize, generalSettings);
    const item = filterFreeTicketsRates(freeTicketsRates, ticketsCount);
    if (item) {
      const actualDiscount = filterDiscountRates(discountRates, item.tickets);
      const price = actualDiscount
        ? (item.tickets * actualDiscount.newPrice) / (item.tickets + item.extra)
        : (item.tickets * prize.ticketPrice) / (item.tickets + item.extra);
      const result = price >= 1 ? roundedPrice(price) : roundedPrice(price).toFixed(2).split('.')[1] + 'p ';
      return result;
    }
  };
  