import React from 'react';
import './styles.css';

const SectionWrapper = (props) => {
  let classNames = props.bg ? `section-wrapper` + ` ${props.bg}` : `section-wrapper`;

  return <section className={classNames}>{props.children}</section>;
};

export default SectionWrapper;
