import React from "react";
import { useGSAP } from "@gsap/react";
import {
  Timeline,
  TimelineHeader,
  TimelineTitle,
  TimelineSubtitle,
  TimelineMain,
  TimelineSteps,
  TimelineFooter,
} from "raffle-house-uikit";
import { startBadgeAnimation, startHeaderAnimation } from "./script";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import "./styles.css";

const TimelineBlock = (props) => {
  const { data, widget } = props;
  const mobile = useMediaQuery("(max-width: 560px)");
  const laptop = useMediaQuery("(max-width: 1024px)");
  const title = laptop ? data.horizontal.title : data.vertical.title;
  const subtitle = mobile ? data.horizontal.subtitle : data.vertical.subtitle;
  useGSAP(() => !mobile && startHeaderAnimation());
  useGSAP(
    () => {
      !mobile &&
        startBadgeAnimation({
          numberOfSteps: data.vertical.steps.length,
          images: (widget && widget.images) || [],
        });
    },
    { dependencies: [mobile, widget] }
  );
  return (
    <Timeline>
      <TimelineHeader>
        <TimelineTitle>{title}</TimelineTitle>
        <TimelineSubtitle>{subtitle}</TimelineSubtitle>
      </TimelineHeader>
      <TimelineMain>
        <TimelineSteps
          widget={{ data: widget }}
          verticalTimelineSteps={data.vertical.steps}
          horizontalTimelineSteps={data.horizontal.steps}
        />
        <TimelineFooter>Your win. Your dream. Your choice</TimelineFooter>
      </TimelineMain>
    </Timeline>
  );
};

export default TimelineBlock;
