const en = {
  A_TICKET: 'A one-way ticket to',
  A_TICKET_BUNDLE: 'a ticket',
  ABOUT_EXAMPLE_QUESTION: 'Which of these cities has the highest population?',
  ABOUT_INFO_MESSAGE_1: 'Learn more about the simple steps required to enter below',
  ABOUT_INFO_MESSAGE_2: 'and when you’re ready, hit buy tickets to begin.',
  ABOUT_LINE_1: 'Turning dreams into',
  ABOUT_LINE_2: 'bricks and mortar',
  ABOUT: 'About',
  ABOUT_BENNO: 'Raffle House is his baby. And the head of HR is his baby too. We guess he likes looking after things.',
  ABOUT_JAMES:
    'He has a brilliant mind and is our numbers guru. Which is great because we like to keep him locked up in the dark and out of our way.',
  ABOUT_BRIAN:
    'Chairman of 888 (yep, that 888) and a pretty cool guy. Plus he knows his stuff about running a reputable and trustworthy gambling company. We think that’s a great fit.',
  ABOUT_SARA:
    'Sara hosts various property TV shows and also helps us with tours and our jackpot-winner calls. So, if you see a video call from her, do pick up.​',
  ABOUT_PEANUT:
    'Peanut is arguably the most important person in the team. And she’s not even a person so that’s really saying something.',
  ABOUT_PARTNER_3:
    'Civica Election Services, formerly known as Electoral Reform Services (ERS), act as our independent scrutineers to manage and provide oversight of our free postal entries. They have over 100 years’ experience in administering elections, ballots and competitions for a wide range of clients including TV programmes and various lottery projects. That means that you can rest assured that any valid free entry that they receive will be handled fairly by a trusted, third-party organization.',
  ABOUT_PARTNER_2:
    'Checkout.com provide our secure payment processor. Their systems are first rate and they take customer privacy and data security extremely seriously. We let them handle all of your sensitive information and, because of this partnership, you can rest easy knowing that Raffle House never sees, stores or shares any of your financial data. You’re in Barclay’s very safe and capable hands.',
  ABOUT_PARTNER_1:
    'For over 30 years, Sterling Lotteries’ mission has been to help good causes utilize prize-led fundraising. Sterling Lotteries are licensed and regulated by the Gambling Commission and we’ve partnered with them to manage our draw process. This means that every draw is empirically fair and fully managed by an independent, regulated and professional body.',
  ABOUT_INVESTORS:
    'Backers from close to home & abroad helping to finance and build our business – as well as keeping us on the straight and narrow.',
  ABOUT_US: 'About us',
  ACCOUNT_INFO: 'Account Information',
  ADD_PRIZE_PULL_TEXT1: 'Now added to prize pool!',
  ADD_PRIZE_PULL_TEXT2: 'A home & a car, all for a ',
  ADD_PRIZE_PULL_TEXT3: 'ticket',
  ADD_TO_BASKET: 'Add to basket',
  AGE_ERROR: 'You need to confirm that you are 18 years or older to create an account',
  AGREE: 'I agree',
  ALSO: 'also',
  ALL: 'all',
  ALL_FOR: 'all for just one ',
  ALREADY_INVITED: 'You have already invited this user.',
  ALREADY_REGISTERED: 'Already registered? ',
  AMENITIES: 'Amenities',
  ARE_YOU_SMART: 'Are you smart enough to pass?',
  AREA_MAP_1: 'Area',
  AREA_MAP_2: 'Map',
  AREA_TEXT:
    ' The property boasts high ceilings and features direct access to a pretty courtyard for residents to enjoy at the rear. This bright apartment has been meticulously designed throughout by renowned interior designer Fenton Whelan and offers spacious well thought out accommodation.',
  AREA: 'Area',
  BACK_TO_HOME: 'Back to Home',
  AND: ' and ',
  AND_ALL: 'all in less than 2 minutes.',
  AND_GET: 'and get ',
  AUTOMATIC_DONATION: 'Automatic donation to charity',
  AWARDED: 'Awarded in prizes',
  BATHROOMS: 'Bathrooms',
  BEDROOMS: 'Bedrooms',
  BE_ENTERED_1: 'be entered into our weekly £1,000 tax-free, cash-prize draw on top ',
  BE_ENTERED_2: 'of the property ',
  BE_ENTERED_3: 'and Lifestyle competitions',
  BEST_PROPERTIES_ANSWER1:
    'Our specialist lawyers will cover all of the conveyancing and legal stuff and every property that we offer as a prize has been thoroughly reviewed and validated by our experienced team.',
  BEST_PROPERTIES_ANSWER2: ' Raffle House covers all of those costs too. ',
  BEST_PROPERTIES_ANSWER3: 'We have invested a great deal of time and resources into sourcing',
  BEST_PROPERTIES_ANSWER4: ' the very best properties ',
  BEST_PROPERTIES_ANSWER5:
    'while making sure that we can keep the chances of winning reasonable. Over the coming months, our raffles will see our customers have',
  BEST_PROPERTIES_ANSWER6: ' a minimum of 1 in 100,000 to 200,000 chance of winning ',
  BEST_PROPERTIES_ANSWER7:
    ', depending on the property - that’s a far cry from the UK National Lottery’s 1 in 45,000,000 or Euromillions’ 1 in 140,000,000. Raffle House has exceptional properties to offer as prizes throughout 2018 and into the future. So, there’s only question to think about: ',
  BEST_PROPERTIES_ANSWER8: ' do you feel lucky?',
  BEST_PROPERTIES_QUESTION: 'A Seamless Process & The Best Properties',
  BLAST_LINK: 'Blast out your referral link in seconds on Facebook, Twitter or to your WhatsApp contacts!',
  BLOG_TITLE: "What we're up to",
  BUY: 'buy',
  BUY_TICKETS_INFO_1: 'Choose the number of tickets you want and your',
  BUY_TICKETS_INFO_2: 'choice of charitable donation for the homeless.',
  BUY_TICKETS: 'Select tickets',
  BUY_TICKETS_2: ' & charitable donation',
  BUY_YOUR_TICKETS: 'Buy your tickets',
  BUY_YOUR_TICKETS_SIGN_IN: 'Sign in & buy your tickets',
  BUY_TICKETS_TEXT:
    'Choose the number of tickets you want and your choice of homelessness charitable donation covered by us!',
  BLOG: 'Blog',
  BONUS_TICKETS: 'Bonus',
  CANT_ENTER_OUTSIDE_UK_ANSWER1: 'Providing you are 18 years old or over, anyone in the world can enter.',
  CANT_ENTER_OUTSIDE_UK: 'Can I enter the competition if I live outside of the UK?',
  CARDS_LAW_PROHIBIT: 'U.K. law prohibits the use of credit cards for gambling.',
  CHANGE_PASS: 'Change Password',
  CHANCE_TO_WIN1: 'A ',
  CHANCE_TO_WIN2: ' ticket ',
  CHANCE_TO_WIN3: 'for ',
  CHANCE_TO_WIN4: 'both draws!',
  CHOOSE_CHARITY: 'Feel free to select a charitable donation',
  CHARITY_INFORMATION: 'Charity Information',
  CHECKOUT_TEXT_1: '5p ',
  CHECKOUT_TEXT_2: 'of your payment will be donated.',
  CHECKOUT_TEXT_3: 'Each ',
  CHECKOUT_TEXT_4: ' ticket covers a ',
  CHECKOUT_TEXT_5: ' ticket price and ',
  CHECKOUT_TEXT_6: ' bank service charge.',
  CHECKOUT_TEXT_7: 'If you like, we will donate ',
  CHECKOUT_TEXT_8: ' of every ticket you buy to a homelessness charity. Just pick one:',
  CHECKOUT_TEXT: 'If you choose one of the 3 homelessness charities below, ',
  CHECKOUT_NEXT_SCREEN_TEXT: 'On the next screen is the chance ',
  CHECKOUT_NEXT_SCREEN_TEXT_1: 'to win your dream home',
  CHECKOUT_QUESTION_TEXT: 'Which of these cities has the highest population?',
  CHECKOUT_QUESTION_OPTION_1: 'Beijing',
  CHECKOUT_QUESTION_OPTION_2: 'Cardiff',
  CHECKOUT_QUESTION_OPTION_3: 'Dublin',
  CHECK_OUT_OUR: 'Check out our',
  CHECK_WINNERS_1: 'Check out our past winners ',
  CHECK_WINNERS_2: 'here!',
  CHOOSE_PASSWORD: 'Choose a Password*',
  CINEMA: 'Cinema',
  CLOSE: 'You were so close!',
  CONFIRM_EMAIL_ADDRESS: 'Confirm your email*',
  CONFIRM_NEW_PASS: 'Confirm New Password*',
  CONFIRM_PASSWORD: 'Confirm new password',
  CONFIRM_PAY: 'Confirm & Pay',
  CONFIRM_YOUR_PASSWORD: 'Confirm your Password*',
  CONGRATULATIONS: 'Congratulations!',
  CONTACT_INFO_MESSAGE1: 'Have any issues? If you’ve read the',
  CONTACT_INFO_MESSAGE2: ' FAQs section ',
  CONTACT_INFO_MESSAGE3: 'but still have questions, send us a message below and we’ll do our',
  CONTACT_INFO_MESSAGE4: 'utmost to get back to you as soon as possible. While you wait..',
  CONTACT_INFO_MESSAGE5: 'Hit buy tickets for the chance to win your dream home.',
  CONTACT_LINE_1: 'Holla at us through the',
  CONTACT_LINE_2: 'digital air waves',
  CONTACT_PROMPT_1: 'No matter how big or small',
  CONTACT_PROMPT_2: 'send us a message, and we solve it.',
  CONTACT_SUBMITTED1: 'Message sent! ',
  CONTACT_SUBMITTED2: 'We’ll get back to you as soon as possible.',
  CONTACT: 'Contact',
  CONTINUE: 'Continue',
  CONTINUE_FB: 'Continue with Facebook',
  CONTINUE_GOOGLE: 'Continue with Google',
  COOKIES_MESSAGE: 'We use cookies to ensure that we give you the best experience on our website.',
  COPY_LINK: 'Copy Link',
  COPYRIGHT_1_1: '© Raffle House Limited. 2020. ',
  COPYRIGHT_1_2: 'All Rights Reserved.',
  COPYRIGHT_2_1: 'Registered in England & Wales ',
  COPYRIGHT_2_2: 'to no. 10928240.',
  COUNTDOWN: 'Countdown',
  CURRENT_PASS: 'Current Password*',
  CURRENT_RAFFLE: 'Current raffle: ',
  CUSTOMERS: 'Customers',
  DAMM: 'Damm Try Again',
  DATA_PROTECTION: 'DATA PROTECTION',
  DAYS: 'Days',
  DEBIT_CARDS_ONLY: 'Debit cards only.',
  DECIDE_TO_ENTER: 'If you decide to enter online, we’ll automatically add 5 free tickets to your order to ',
  DELETE_ACC: 'Delete Account',
  DELETE_ACCOUNT_MESSAGE:
    'If you do not feel that you will use your Raffle House account again, please do delete it. However, please be aware that this will mean that any ticket history will also be deleted, as well as our ability to contact you should you win a live competition.',
  DELETE_ACCOUNT_PROMPT_1: 'Are you sure you want to delete your account?',
  DELETE_ACCOUNT_PROMPT_2: 'Once you do this it cannot be undone',
  DELETE_ACCOUNT_TEXT:
    'If you do not think you will use Raffel House account again and would like your account deleted, we cantake care of this for you. Keep In mind that you will not be able to reactivate your account or retrieve any of the content or information you have added. If you would still like your account deleted, click below.',
  DELETE_ACCOUNT: 'Delete My Account',
  DOES_THE_WINNER_PAY_STAMP_ANSWER:
    'Raffle House will cover the stamp duty (even if the winner is already a homeowner). We want to make the process as seamless and stress-free as possible. Winning a home should be a dream-come-true and we want to keep it that way. More detail on this are in the Terms & Conditions.',
  DOES_THE_WINNER_PAY_STAMP: 'Does the winner have to pay Stamp Duty Land Tax?',
  DONE: 'Done',
  DOWN_TO_FIND: 'Scroll down to find out more.',
  ELIGIBILITY1: 'Your spend determines which Weekly Lifestyle Prize ',
  ELIGIBILITY2: 'pool you enter',
  DREAM_HOME: 'your dream home',
  EACH_TICKET: 'Each ticket ',
  EACH_TICKET1: 'entered into:',
  EACH_TICKET_1: "This week's £1,000 draw",
  EACH_TICKET_2: '(when spending £20 or more)',
  EACH_TICKETV2_1: 'Each ',
  EACH_TICKETV2_2: ' ticket enters you into:',
  EMAIL_ADDRESS: 'Your email address*',
  EMAIL_COMMINICATION:
    'I would like to opt-in to email communication from Raffle House. We store your data securely, never sell your data, and only communicate infrequently on matters relating to our competitions. You can read our full Privacy Policy ',
  EMAIL_CONFIRM_INVALID: 'Confirm email is invalid',
  EMAIL_INVALID: 'Email is invalid',
  EMAIL_NOT_MATCH: 'Emails do not match',
  EMAIL_REQUIRED: 'Email is required',
  EMAIL_SENT: 'Email sent',
  EMAIL_USED: 'This email is already used',
  ALREADY_HAS_ACCOUNT: 'Account needs activation. Activation email was sent.',
  EMAIL: 'Email address',
  EMAIL_US: 'or email us at ',
  EMPTY_CONFIRM_EMAIL: 'Confirm email is required',
  EMPTY_CONFIRM_PASSWORD: 'Confirm password is required',
  EMPTY_EMAIL_PASSWORD: 'Email and password are required',
  EMPTY_NAME: 'Name is required',
  EMPTY_NAMES: 'Name and Surname are required',
  EMPTY_PASSWORD: 'Password is required',
  EMPTY_PASSWORDS: 'Password is required',
  EMPTY_SURNAME: 'Surname is required',
  ENTER_COMPETITION_INFO_1: 'Answer a multiple choice question on world city',
  ENTER_COMPETITION_INFO_2: 'population within 30 seconds. For example:',
  ENTER_COMPETITION: 'Enter the competition',
  ENTER_EMAIL: 'Enter email',
  ENTER_NOW: 'Buy Tickets',
  ENTER: 'Enter',
  ENTERTAINMENT: 'Entertainment',
  EVERY_WEEK_1: 'And, ',
  EVERY_WEEK_2: 'every week you play',
  EVERY_WEEK_3: ', also ',
  EVERY_WEEK_4: 'win ',
  EVERY_WEEK_5: 'your choice of ',
  EVERY_WEEK_6: 'any of our Lifestyle',
  EVERY_WEEK_7: 'Prizes',
  ERROR: 'Error',
  ERROR_FIRST_NAME: 'First name is required',
  ERROR_SURNAME: 'Surname is required',
  EXTRA_FREE_1: 'Extra ',
  EXTRA_FREE_2: 'free',
  FAIL_MESSAGE_TITLE: 'WHY DIDN’T I GET A RAFFLE TICKET?',
  FAIL_MESSAGE:
    'In order to qualify for a raffle ticket, you must successfully answer the question when you play. But don’t worry, you’ve got a free go on us. Most of our users are successful after, at most, two attempts.',
  FAQ_CONTACT_ANSWER1: 'Of course! Email is the best way to reach us: ',
  FAQ_CONTACT_ANSWER2: 'info@rafflehouse.com',
  FAQ_CONTACT_ANSWER3: '. We’ll do our utmost to get back to you as soon as possible.',
  FAQ_CONTACT: 'Can I contact Raffle House?',
  FAQ_FORGOT_PASSWORD: 'What do I do if I forgot my password?',
  FAQ_INFO_MESSAGE_0: 'Raffle House has been designed to be as simple as possible',
  FAQ_INFO_MESSAGE_1: 'but we know that this may be a totally new concept to you',
  FAQ_INFO_MESSAGE_2: 'so please feel free have a read of our FAQs below',
  FAQ_LINE_1: 'The answer to all of your problems',
  FAQ_LINE_2: 'or just some FAQs',
  FAQ_PROMPT_1: 'If you can’t find the answer then',
  FAQ_PROMPT_2_1: 'send us a ',
  FAQ_PROMPT_2_2: 'message',
  FAQ_PROMPT_2_3: ', and we’ll solve it.',
  FAQ_PROMPT_3_1: 'Can’t find the solution to your issues? Head over',
  FAQ_PROMPT_3_2: 'here.',
  FAQ_PAYMENT_SECURE: 'Is my payment secure?',
  PAYMENT_SECURE_ANSWER1:
    'We use a third-party payment processor, Axcess Merchant Services. We host a server-to-server processor, which means that we never store your card details and never get to see them – it’s totally secure. Also, our merchant bank is the world’s largest, First Data. You’re in safe hands.',
  PRIZES_AND_1: 'prizes and benefit from more ',
  PRIZES_AND_2: 'bonus tickets!',
  FAQ_QUESTION_WRONG: 'I got the question wrong, is my ticket valid?',
  QUESTION_WRONG_ANSWER1:
    'Due to legislation from the UK Gambling Commission, only correctly answered questions may be considered in the draw. However, if you have any issues or frustrations, our support agents are on hand to assist you. Don’t fret! We’re here to help Monday through Sunday.',
  FAQ_WHATS_SPECIAL_ANSWER1:
    'Raffle House is proud to offer superior properties at really affordable ticket prices, while maintaining super attractive odds. We have spent months ensuring that our model is compliant with the UK Gambling Commission’s legislation as well as only sourcing properties that we’d actually want to live in. Our properties are always of the highest standard and we carry out a thorough inspection of every one.',
  FAQ_WHATS_SPECIAL_ANSWER2:
    'We also offer runner-up prizes of £1,000 as well as ensuring that every effort associated with buying a home is taken care of, such as all of the time-consuming legal stuff.',
  FAQ_WHATS_SPECIAL_ANSWER3:
    'We’re also committed to making charitable donations to charities that focus on homelessness. 5% of every ticket purchase will go towards nobles causes. More on that below. ',
  FAQ_WHATS_SPECIAL_ANSWER4:
    'There are also no hidden costs whatsoever. A £5 ticket is just that - there’s no payment processing fee to worry about.',
  FAQ_WHATS_SPECIAL: 'What’s special about Raffle House?',
  FAQ: 'FAQ',
  FAQS: 'FAQs',
  FEATURES_BATHROOMS_TEXT:
    'The flat comes with its own private garden, with a great deal of space for sunbathing and barbecuing and even an outside office space, which is perfect for when you just want to unwind.',
  FEATURES_BEDROOMS_TEXT:
    'The property boasts high ceilings and features direct access to a pretty courtyard for residents to enjoy at the rear. This bright apartment has been meticulously designed throughout by renowned interior designer Fenton Whelan and offers spacious well thought out accommodation.',
  FEATURES_GENERAL_TEXT:
    'The flat comes with its own private garden, with a great deal of space for sunbathing and barbecuing and even an outside office space, which is perfect for when you just want to unwind.',
  FEATURES_OUTSPACE_TEXT:
    'The flat comes with its own private garden, with a great deal of space for sunbathing and barbecuing and even an outside office space, which is perfect for when you just want to unwind.',
  FEATURES: 'Features',
  FIVE_TICKETS: '5 Tickets',
  FIND_LAST_PROPERTY_1: 'Find out about our last property winner ',
  FIND_LAST_PROPERTY_2: 'here',
  FIND_OUT_ABOUT: 'Find out about our past winners ',
  FIND_OUT_MORE: 'Find out more',
  FLOOR_PLAN_1: 'Floor ',
  FLOOR_PLAN_2: 'Plan',
  FNAME: 'Your first name*',
  FORGOT_PASSWORD_ANSWER1:
    'In your account section, found by clicking the profile icon on the top right of the site, you have the ability to reset your password and change other information, such as your email.',
  FORGOT_PASSWORD: "I've forgotten my password",
  FREE: 'free',
  FREE_ENTRIES: ' Free Entries',
  FREE_ENTRY: ' Free Entry',
  FREE_PLAY: 'Free play',
  FRIEND: 'friend',
  FRIENDS: 'friends',
  FRIENDS_EMAIL: "Friend's email address",
  FRIENDS_REFERRED: 'Friends Referred',
  GET: 'get',
  GENERAL: 'General',
  GOOD_LUCK: 'Good luck',
  GOT_IT: 'Got it',
  GRAB_THIS_OFFER: 'grab this offer',
  HEAD_HELP_CENTRE: 'Head to the Help Centre to learn more about our competitions',
  HERE: 'here.',
  HERE_MIDDLE: 'here',
  HI: 'hi',
  HOME_SUBTITLE_LINE_1: 'Enter for your chance to win ',
  HOME_SUBTITLE_LINE_2: 'plus three £1,000 cash prizes ',
  HOME_SUBTITLE_LINE_3: '& your choice of charitable donation!',
  HOME_SUBTITLE: 'Enter for your chance to win plus three £1,000 cash prizes & your choice of charitable donation!',
  DREAMEHOME_TITLE_LINE_1: 'A ',
  DREAMEHOME_TITLE_LINE_2: 'ticket to ',
  DREAMEHOME_TITLE_LINE_3: 'your dream home',
  HOME_TITLE1: 'Win your dream home ',
  HOME_TITLE2: 'and be entered into ',
  HOME_TITLE3: 'this week’s Lifestyle Prize draw,',
  HOME_TITLE4: 'all',
  HOME_TITLE5: 'for',
  HOME: 'Home',
  HOME_LITTLE: 'home',
  HOURS: 'Hours',
  HOUSE_QUIZ_QUESTION: 'What type of house is this?',
  HOW_IS_WINNER_DECIDED_ANSWER1:
    'Raffle House will choose a winner at random once the competition has come to an end. This will be verified by at least 2 independent people from a third-party and a solicitor. A winner will be announced shortly after the draw.',
  HOW_IS_WINNER_DECIDED: 'How is the winner decided?',
  HOW_IT_WORKS: 'How it works',
  HOW_IT_WORKS_2: 'How it Works',
  HOW_IT_WORKS_CHAT: "We're here to chat. Just click the bubble in bottom right-hand corner",
  HOW_MUCH_1: 'Which ',
  HOW_MUCH_2: 'Weekly Lifestyle Prize ',
  HOW_MUCH_3: 'are you going for?',
  HOW_MANY_TICKETS_SOLD_ANSWER1:
    'The current property has a minimum of 150,000 tickets to be sold. These odds are so much better than anything you’ll find elsewhere and about 1000 times better than Euromillions!',
  HOW_MANY_TICKETS_SOLD_ANSWER2:
    'Future properties will have a different number of total tickets to be sold, largely dependent on the value of that property and the associated costs and fees. Raffle House aims to keep this number between 100,000 and 200,000.',
  HOW_MANY_TICKETS_SOLD: 'How many tickets are being sold?',
  HOW_MANY_TIMES_ENTER_ANSWER1:
    'You can enter as many times as you like and on multiple occasions throughout the raffle’s open-competition phase.',
  HOW_MANY_TIMES_ENTER: 'How many times and when can I enter?',
  HOW_TO_ENTER_ANSWER1:
    'You’ll see various "Buy Tickets" buttons throughout the site, so just click one of these. Assuming that you don’t have an account already, that will take you to create one.',
  HOW_TO_ENTER_ANSWER2:
    'You need an account so that we can send you confirmation of your entry, store that information on your account, as well contact you if you win one of the prizes!',
  HOW_TO_ENTER_ANSWER3:
    'Once you have an account, you’ll be able to select how many tickets you want to buy and make your choice of charitable donation. Once you’ve done that, select "Confirm & Pay" and you’ll be directed to a secure payment details page.',
  HOW_TO_ENTER_ANSWER4:
    'Once that’s done, you’re ready for the question! After you click "I’m Ready", you’ll have 30 seconds to answer.',
  HOW_TO_ENTER: 'How do I enter?',
  HOW_WILL_I_BE_NOTIFIED_ANSWER1:
    'Before you buy a ticket you have to register an email address with us. We will use this email address to contact you if you win.',
  HOW_WILL_I_BE_NOTIFIED: 'How will I be notified if I win?',
  HOW_WILL_I_KNOW_LOGGED_ANSWER1:
    'You’ll be emailed a confirmation of your entry shortly after playing. This information will also be saved to your Raffle House account where you can view it at your leisure.',
  HOW_WILL_I_KNOW_LOGGED: 'How will I know Raffle House has logged my entry?',
  I_ACCEPT: 'I Accept',
  IF_WIN_WHAT_DO_ANSWER1:
    'Nothing! Raffle House has a specialist team of lawyers that will be undertaking the sale of the property and the transference of contract to the winner of the raffle. So, long story short, you don’t need to do anything! We’ll contact the winner with details of how we will proceed after the draw has taken place.',
  IMMEDIATELY_EMAIL:
    'Your ticket information will be sent to your email and will also be found in the ‘My Tickets’ section of your account.',
  INCREDABLE_ODDS: 'incredible odds ',
  IF_WIN_WHAT_DO: 'If I win, what do I need to do?',
  INFO: 'Info',
  INVITE_YOUR_FRIENDS: 'Invite your friends',
  INVALID_PASSWORD: 'Password is too short',
  INVALID_PASSWORD2: 'Password is invalid',
  INVITE_MORE_FRIENDS: 'Invite more friends and get more',
  IS_THERE_MAXIMUN_NUMBER_ANSWER1: '100 per account per property.',
  IS_THERE_MAXIMUN_NUMBER: 'Is there a maximum number of tickets I can buy?',
  IS_THIS_LOTTERY_ANSWER1:
    'This is a skill based, prize competition and we’ve worked for months with specialist lawyers to ensure that it falls within the requirements set by the UK’s Gambling Commission. Entrants must display a sufficient level of skill in order to correctly answer the question and only entrants with correct answers will be entered into the draw.',
  IS_THIS_LOTTERY: 'Is this a lottery?',
  EASY: `It's easy`,
  ITS_EASY:
    "It's easy. Just answer a simple question multiple-choice question in 30 seconds. You'll see an example of it when selecting your charitable donation! Correctly answering the question to enter is required by law – so get your thinking caps on. You only pay after correctly answering the question. Give it a go and try before you buy!",
  FREE_TICKETS_PROFILE: 'free tickets',
  FREE_TICKET: 'Free Ticket',
  JACKPOT_PROPERTY: 'for our Jackpot Property and weekly Lifestyle Prizes ',
  JACKPOT_PRIZE: 'The Jackpot Prize Competition',
  JOIN_OUR: 'Join our ',
  JUST_ANSWER:
    'Just answer our simple multiple choice question in 30 seconds. This is designed to exclude some people from getting tickets, as required by UK law - are you smart enough to pass? Since you only pay after correctly answering the question, give it a go and try before you buy.',
  LEARN_MORE: 'Learn more',
  LEGAL_INFO_MESSAGE_0: 'We’ve spent a long time making sure that everything is covered',
  LEGAL_INFO_MESSAGE_1: 'so that you can sit back and relax. ',
  LEGAL_INFO_MESSAGE_2: 'You’re in safe hands.',
  LEGAL_LINE_1: 'Interested in the legal stuff?',
  LEGAL_LINE_2: 'T&Cs and Privacy Policies below',
  LEGAL_PROMPT_1: "It's all legal for further questions.",
  LEGAL_PROMPT_2_1: 'Send us a message ',
  LEGAL_PROMPT_2_2: 'here',
  LEGAL: 'Legal',
  LEGAL_STUFF: 'The legal stuff',
  LEISURE: 'Leisure Facilities',
  LIFESTYLE_PRIZES: 'Lifestyle Prizes!',
  LINK_SENT: 'A verification link has been emailed to you.',
  LOG_IN_HERE: 'Log in here',
  LOG_OUT: 'Log out',
  LOYALTY: 'Loyalty',
  LYHAM_ROAD: 'Lyham Road, Brixton, SW2',
  MAX_TICKETS_EXCEEDED: "Sorry, you've reached the maximum number of tickets in this raffle – 5,000 tickets",
  METRES: 'metres',
  MILES: 'miles',
  MINUTES: 'Minutes',
  MONTHS: 'Months',
  MORE_PRIZES: 'More than £700,000 in prizes already awarded!',
  MY_ACCOUNT: 'My Account',
  MY_TICKETS: 'My Tickets',
  NEW_PASS: 'New Password*',
  NEW_PASSWORD: 'New password',
  NEXT: 'Next',
  NO_ACTIVE_RAFFLES: "You can't use your free entries now, because there is not an active raffle.",
  NO_MORTGAGE_TEXT: 'No mortgage, no Stamp Duty, no strings attached. For ',
  NUMBERS_OF_TICKETS: 'Number of tickets selected',
  OF_ANY: 'of any of our ',
  OOPS: 'Something went wrong. Please try again later.',
  OR: 'OR',
  ORDER_SUMMARY: 'Order Summary',
  OUTSPACE: 'Outspace',
  OUR_CUSTOMERS: '80,000+ our customers ',
  OUR_PARTNERS: 'Our Partners',
  OUR_PEOPLE: 'Our People',
  OUR_WINNERS: 'Our winners!',
  OVER_18: 'I am 18 years old or over',
  PARK: 'Park',
  PARTNERS: 'partners',
  PASS_MESSAGE_TITLE: 'YOU’VE PASSED THE TEST!:',
  PASS_MESSAGE_1: 'On the next screen you can sign in, then buy your tickets and add more without playing again!',
  PASS_MESSAGE: 'Your answer was correct!',
  PASSWORD_REQUIRED: 'Password is required',
  PASSWORD: 'Password',
  PASSWORDS_NOT_MATCH: "Passwords don't match",
  PAY_AFTER_CHECKOUT_PAGE: 'Pay only after answering the question correctly',
  PAYMENT_ACCEPTED: 'Payment accepted.',
  PAYMENT_REJECTED: 'Your payment method was declined. Please enter another payment method.',
  PAY_WITH_CARD: 'Pay with my card',
  PAY_WITH_PHONE: 'Pay with my phone',
  PHONE_NUMBER_ENTER: 'Enter your phone number',
  PHONE_NUMBER_ENTER_TEXT: 'So that we can contact you if you win, please enter your phone number.',
  PHONE_NUMBER: 'Your phone number*',
  PHONE_NUMBER_REQUIRED: 'Phone number is required',
  PHONE_NUMBER_INVALID: 'Phone number is invalid',
  PLAY: 'Play',
  PLAY_GAME: 'To enter, play then pay',
  PLAY_QUIZ: 'Play the Quiz',
  PLUS: 'Plus, every ticket is automatically entered into our weekly £1,000 giveaway. ',
  QUIZ: 'Quiz',
  POSTAL_ENTRY: 'Free Postal Entry',
  POST_ENTER: 'How to enter for free by post',
  POST_ADDRESS_1: 'Raffle House Postal Entry​',
  POST_ADDRESS_2: 'Civica Election Services​',
  POST_ADDRESS_3: '33 Clarendon Road​',
  POST_ADDRESS_4: 'London',
  POST_ADDRESS_5: 'N8 0NW​',
  POST_TEXT_1:
    'Raffle House offers a postal entry for its competitions. Just like the paid method of entering its competitions, the postal method requires that you create an online account and accept our T&Cs. This is so that we can verify your entry details and provide you with an electronic record of your entry.​',
  POST_TEXT_2: 'Click the button below to create an account.​',
  POST_TEXT_3:
    'Then, on a blank sheet of paper write your full name, date of birth, telephone number, and email address. This information must match the information that you use to create your online account and needs to be written legibly otherwise the entry may be deemed invalid. ​',
  POST_TEXT_4:
    'We have appointed Civica Election Services, formerly known as Electoral Reform Services (ERS), as our independent scrutineers to administer this competition. They have over 100 years’ experience in administering elections, ballots and competitions for a wide range of clients including TV programmes and various lottery projects. Please send the sheet of paper in an envelope to the following ​address via first- or second-class post to:​',
  POST_TEXT_5:
    'Due to differing postal timings, entries enclosed in envelopes stamped ​with a second-class stamp will not be considered.​',
  POST_TEXT_6:
    'A maximum of one entry can be made per envelope received. Once a postal entry has been processed it will be entered into the next available £1,000 weekly draw and the property competition. Postal entries are treated in exactly the same way as paid entries for the purposes of determining a winner and bonus tickets awarded. However, there is a difference in the deadlines for postal entries for the property competition. Our property draw closes at midnight on its final day; the corresponding cut-off for postal entries will be that they are received and processed before 5pm two business days later (see our T&Cs for dates). One postal entry has an equal chance of winning as any one paid entry. If a postal entry wins a prize there will be no further purchase or payment necessary to be notified of the win or to receive the prize. If the above steps are not followed as described, then a postal entry will be invalid. You will not be notified if your entry is invalid and only valid entries will appear on your online account.​',
  POWERED_BY: 'Powered by',
  PRIZES_AND_WEEKLY: 'prizes &  weekly Lifestyle and cash-prizes. Oh, and we  donate a fair bit to charity too.',
  PRIZES_ANSWER1: 'We’ll also be giving away three',
  PRIVACY_POLICY: 'Privacy & Cookies Policies',
  PRIVACY_POLICY_1:
    'This Privacy and Cookies Policy applies to the website https://www.rafflehouse.com ("the Site") which is run by Raffle House Ltd ("the Company") (company number 10928240) whose registered office address is 1 Ashmere Grove, London, SW2 5UH.',
  PRIVACY_POLICY_2:
    'The Company recognises the importance of honest and responsible use of your personal information. This Privacy and Cookies Policy ("Policy") explains how the Company collects, uses and discloses personal information about you when you visit this Site and when you contact the Company, whether by e-mail, post, fax or telephone using the contact options on this Site. The information you provide to us through the Site will initially be collected by Raffle House Ltd, but may then be shared with other companies.',
  PRIVACY_POLICY_3: 'It is important to us that you feel comfortable in visiting the Site.',
  PRIVACY_POLICY_4:
    'Our collection of website use information may involve the use of cookies and Web beacons. Please see the "Cookies" section below for more information.',
  PRIVACY_POLICY_5:
    'For the purposes of the Data Protection Act 1998 (the Act) and the General Data Protection Regulation , the data controller is the Company.',
  PRIVACY_POLICY_6: 'LEGAL BASIS FOR THE USE OF PERSONAL DATA',
  PRIVACY_POLICY_7: 'The legal basis on which we rely to process your personal information includes:',
  PRIVACY_POLICY_8:
    'On some occasions, we process your data with your consent (for example, when you agree that we may place cookies or process information that you input into our website).',
  PRIVACY_POLICY_9:
    'On other occasions, we process your data when we need to do this to fulfil a contract with you (for example, if you win a prize in the competitions we promoted or where we are required to do this by law (for example, to comply with record keeping obligations).',
  PRIVACY_POLICY_10:
    'We also process your data when it is in our legitimate interests to do this and when these interests are not overridden by your data protection rights (including for example, when we share data with our affiliates).',
  PRIVACY_POLICY_11:
    'In most cases, the information the Company processes about you is required to deal with your request or registration, or required by law, or is necessary for the exercise of the Company’s legitimate business interests and needs, in which case special care is taken to safeguard your rights and to ensure any such use is proportionate.',
  PRIVACY_POLICY_12:
    'The Company may also convert personal information into anonymous data and use it (normally on an aggregated statistical basis) for research and analysis to improve Company performance.',
  PRIVACY_POLICY_13: 'PERSONAL INFORMATION WE COLLECT FROM YOU',
  PRIVACY_POLICY_14:
    'If you want to contact us or to use certain facilities we provide on this Site, you will need to provide us with some additional personal information so that we can liaise with you and deal with your request, query or application. If you do choose to provide us with your personal information, we will collect that information for our own use and for the purposes described in this Policy.',
  PRIVACY_POLICY_15:
    'Where you choose to provide personal details to us, we may collect, store and process the following personal information from you:',
  PRIVACY_POLICY_16: 'your full name;',
  PRIVACY_POLICY_17: 'your contact details e.g. address and e-mail address;',
  PRIVACY_POLICY_18:
    'the reason for your contact, which may be an enquiry, a request or enquiry on behalf of someone else, providing a comment, or details in relation to a possible or existing competition;',
  PRIVACY_POLICY_19:
    'if you create an account - your password, e-mail address and your full name, address and post code;',
  PRIVACY_POLICY_20: 'if you enter competitions on the Site - title and full name, email address;',
  PRIVACY_POLICY_21: 'your contact and marketing preferences;',
  PRIVACY_POLICY_22:
    'if you take a survey or interact with us in various other ways - demographics information and information about subjects that may interest you;',
  PRIVACY_POLICY_23:
    'standard internet and website log information and details of patterns about how website visitors behave on our Site. The information we may collect includes information about your Internet service provider, your operating system, browser type, domain name, the Internet protocol (IP) address of your computer (or other electronic Internet-enabled device), your access times, the website that referred you to us, the Web pages you request and the date and time of those requests.',
  PRIVACY_POLICY_24: '  where you "like" us or make posts on our pages on social networking websites.',
  PRIVACY_POLICY_25:
    'This information will be collected primarily from you as information voluntarily provided to us, but we may also collect it where lawful to do so from (and combine it with information from) public sources, third party service providers, individuals whom you have indicated have agreed for you to provide their personal information, government, tax or law enforcement agencies, and other third parties. We may also collect personal information about you from your use of other company services.',
  PRIVACY_POLICY_26: 'USES MADE OF YOUR PERSONAL INFORMATION',
  PRIVACY_POLICY_27:
    'The Company may use information about you for purposes described in this Policy or disclosed to you on our Site or with our services. For example, we may use information about you for the following purposes, all of which we believe to be in our legitimate business interests:',
  PRIVACY_POLICY_28: 'to operate competitions promoted on the Site',
  PRIVACY_POLICY_29: 'to respond and/or deal with any request or enquiry you may raise;',
  PRIVACY_POLICY_30:
    'to improve our products and services and to ensure that content from the Site is presented in the most effective manner for you and for your computer (or other electronic Internet-enabled device);',
  PRIVACY_POLICY_31: 'to administer the Site;',
  PRIVACY_POLICY_32: 'for internal record keeping;',
  PRIVACY_POLICY_33:
    'to contact you (directly, either by the Company or through a relevant partner or agent) by e-mail or phone for the above reasons;',
  PRIVACY_POLICY_34:
    'subject to your consent where required under applicable laws, to carry out direct marketing and/or e-mail marketing that you have requested;',
  PRIVACY_POLICY_35: 'to perform any contract the Company has with you; and',
  PRIVACY_POLICY_36: 'for compliance with legal, regulatory and other good governance obligations.',
  PRIVACY_POLICY_37:
    'This list is not intended to be exhaustive and may be updated from time to time as business needs and legal requirements dictate. Some of the personal information that the Company maintains will be kept in paper files, while other personal information will be included in computerised files and electronic databases as set out in more detail below.',
  PRIVACY_POLICY_38: 'DISCLOSURE OF YOUR PERSONAL INFORMATION ',
  PRIVACY_POLICY_39:
    'Your personal information will be made available for the purposes mentioned above (or as otherwise notified to you from time to time), on a ‘need-to-know’ basis and only to responsible management, human resources, accounting, legal, audit, compliance, information technology and other Company staff who properly need to know these details for their functions within the Company and our parent and subsidiary companies (“Group”). Please note that certain individuals who will see your personal information may not be based at the Company or in your country (please see below).',
  PRIVACY_POLICY_40:
    'We may share personal information within the Company as needed for reasonable management, analysis, planning and decision making, including in relation to taking decisions regarding the expansion and promotion of our service offering and for use by the Company for the other purposes described in this Policy.',
  PRIVACY_POLICY_41:
    'Your personal information may also be made available to third parties (within or outside the Company) providing relevant services under contract to the Company, (see below for further details), such as credit card processors, auditors and compliance managers, provider or call centres and IT hosting and IT maintenance providers. These companies may use information about you to perform their functions on our behalf. The Company has put in place various security and data privacy measures, including with such third parties, in order to protect personal information and shall seek to comply with applicable legal requirements.',
  PRIVACY_POLICY_42:
    'The data that we collect from you may be transferred to, and stored at, a destination outside the European Economic Area ("EEA"). It may also be processed by staff operating outside the EEA who work for us or for one of our providers. This may include staff engaged in, among other things, the fulfilment of any contract with you, the processing of your payment details and the provision of support services. By submitting your personal data, you agree to this transfer, storing or processing. We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this privacy policy.',
  PRIVACY_POLICY_43:
    'We may disclose specific information upon lawful request by government authorities, law enforcement and regulatory authorities where required or permitted by law and for tax or other purposes. Personal information may also be released to external parties in response to legal process, and when required to comply with laws, or to enforce our agreements, corporate policies, and terms of use, or to protect the rights, property or safety of the Company, our employees, agents, customers, and others, as well as to parties to whom you authorise the Company to release your personal information.',
  PRIVACY_POLICY_44:
    'We will not sell your personal information to any third party other than as part of any restructuring of the Company or sale of the business.',
  PRIVACY_POLICY_45: 'WHAT CHOICES DOES THE COMPANY OFFER YOU WITH REGARD TO DIRECT MARKETING?',
  PRIVACY_POLICY_46:
    'The Company may wish to provide you with information about new products, services, promotions and offers, which may be of interest to you and may invite you to take part in market research or request feedback on our products and services.  This communication may occur by e-mail, telephone, post, social media or SMS. We will obtain your consent and advise you of how to opt-out of receiving such communications where we are required to do so in accordance with applicable law.',
  PRIVACY_POLICY_47: 'WHAT SAFEGUARDS ARE IN PLACE TO PROTECT YOUR PERSONAL INFORMATION?',
  PRIVACY_POLICY_48:
    'The Company takes reasonable steps to maintain appropriate physical, technical and administrative security to help prevent loss, misuse, unauthorised access, disclosure or modification of personal information. While we take these reasonable efforts to safeguard your personal information, we cannot guarantee the security of any personal information you disclose online. You accept the inherent security implications of dealing online and will not hold us responsible for any breach of security unless such breach has been caused by the specific negligence of the Company, or their agents.',
  PRIVACY_POLICY_49: 'WHAT RIGHTS DO YOU HAVE TO REVIEW AND AMEND PERSONAL INFORMATION?',
  PRIVACY_POLICY_50: 'You have the right to review and access your personal information held by the Company.',
  PRIVACY_POLICY_51:
    'You also have the right to ask us to rectify, block, complete and delete your personal information, to restrict its use, and to ‘port’ your personal information (that is, to ask us to provide it to you in a structured, commonly used and machine readable format and to transmit it directly to another organisation).',
  PRIVACY_POLICY_52:
    'In addition, you have the right to request further information about the handling of your personal information.',
  PRIVACY_POLICY_53:
    'If you wish to do so, or to notify the Company of a change in your details, please contact info@rafflehouse.com and/or post to Raffle House Ltd, 1 Ashmere Grove, London, SW2 5UH. ',
  PRIVACY_POLICY_54:
    'There are exceptions to these rights, however. For example, access to personal information may be denied in some circumstances if making the information available would reveal personal information about another person or if we are legally prevented from disclosing such information. In addition, we may be able to retain data even if you withdraw your consent, where we can demonstrate that we have a legal requirement to process your data.',
  PRIVACY_POLICY_55:
    'If you have a question about the use of your personal information, or wish to file a complaint about it, please contact us using the contact details set out above. ',
  PRIVACY_POLICY_56:
    'Finally, if you have unresolved concerns, you also have the right to complain to data protection authorities',
  PRIVACY_POLICY_57: 'KEEPING YOU INFORMED',
  PRIVACY_POLICY_58:
    'When we store your information, we do so for as long as necessary to (i) fulfil the specific purposes for which your personal information was collected to perform the services set out in this notice and (ii) to comply with our legal obligations or enforce our legal rights.',
  PRIVACY_POLICY_59: 'INFORMATION ABOUT OUR USE OF COOKIES ',
  PRIVACY_POLICY_60:
    'We use cookies for certain areas of our Site. Cookies are small data files stored on your hard drive by a website. Cookies help us improve our Site and your experience. We use cookies to see which areas and features are popular and to count visits to our websites, to recognise you as a returning visitor and to tailor your experience of the Site according to your preferences. Overall, cookies help us provide you with better websites, by enabling us to monitor which pages you find useful and which you do not. We may also use cookies for targeting or advertising purposes. We may use Web beacons on our Site or in our e-mails. Web beacons are electronic images that may be used to deliver cookies, count visits, understand usage of group websites and to tell if an e-mail has been opened and acted upon. Further details about cookie purposes and types are below. To delete or block cookies by browser settings at any time and for more general information about cookies including the difference between session and persistent cookies, visit www.allaboutcookies.org.',
  PRIVACY_POLICY_61:
    'We also use Google Analytics on our Site to collect information about your online activity on the Site, such as the Web pages you visit and the links you click on the Site. We use the information to compile reports and to help us improve the Site. The cookies collect information in an anonymous form, including the number of visitors to the Site, where visitors have come to the Site from and the pages they visited. ',
  PRIVACY_POLICY_62:
    'For more information about the information gathered using Google Analytics please visit http://www.google.com/intl/en/analytics/privacyoverview.html. You can prevent these cookies by selecting the appropriate settings on your browser. If you do this you may not be able to use the full functionality of this Site. You may download and install the Google Analytics Opt-out Browser Add-on available here: http://tools.google.com/dlpage/gaoptout.',
  PRIVACY_POLICY_63:
    'The cookies used on our Site are categorised in accordance with the International Chamber of Commerce United Kingdom Cookie Guide as follows:',
  PRIVACY_POLICY_64: 'Cookies which are ',
  PRIVACY_POLICY_65: ' "strictly necessary" ',
  PRIVACY_POLICY_66:
    'for functionality of the Site’s platforms. These cookies are essential in order to enable users to move around the Site and use its features.',
  PRIVACY_POLICY_67: '"Performance" ',
  PRIVACY_POLICY_68:
    ' cookies which collect information about how users navigate the Site, for example, which pages users access most frequently. They identify how users interact with our Site, any errors that occur, which pages are not used often, which pages take a long time to load, which pages users tend to visit and in what order. These cookies do not collect any information which could identify you and are only used to help us improve how our Site works and understand what interests our Site’s users.',
  PRIVACY_POLICY_69: '"Functionality" ',
  PRIVACY_POLICY_70:
    'cookies which allow the Site to remember choices you make and your preferences as a logged in user (based on e.g. your user name).',
  PRIVACY_POLICY_71: '"Targeting or advertising" ',
  PRIVACY_POLICY_72:
    ' cookies used to deliver adverts relevant to an identified machine or other device (not a named or otherwise identifiable person) which are tailored to interests associated with the website activity tied to that machine or device. For example, if a cookie on a third party website recognises that a particular product was purchased from a particular device, that cookie may “talk to” marketing cookies on the Site to ensure advertisements about similar products displayed on the Site are accessed from that device. These cookies are also used to limit the number of times a user sees an advertisement as well as to help measure the effectiveness of an advertising campaign. They may also remember that the Site has been visited from a device and share that information with marketing organisations. The marketing cookies on our Site are operated by third parties with our permission. Marketing cookies are used to monitor from which advertising source a user was directed towards our Site so that we know whether it is worth us investing in that particular advertising source.',
  PRIVACY_POLICY_73: 'COOKIE SETTINGS',
  PRIVACY_POLICY_74:
    'We will ask for your consent to the use of cookies set out in this Privacy and Cookies Policy when you first access the Site and if we introduce any new cookies to the Site. When you first visit the Site, a box/banner will appear asking you to agree to the cookies that we set on the Site. ',
  PRIVACY_POLICY_75:
    'You can usually choose to set your browser to warn you when a cookie is being sent or to remove or reject cookies. Each browser is a little different, so look at your browser Help menu to learn the correct way to modify your cookie settings. If you choose to remove or reject cookies, it will affect many features or services on our Site.',
  PRIVACY_POLICY_76:
    ' from our Site by clicking on the "I agree" button, we will set cookies from categories A, B, C and D on your device. If you wish to delete the cookies we have set on your device, please refer to your browser Help menu.',
  PRIVACY_POLICY_77:
    ' to indicate your cookie preference for our Site (i.e. if you do not click on "I agree"), we will treat this as acceptance and set category A, B and C cookies (which are, in our reasonable view, not ‘intrusive’ of your privacy) on your device. By default, category D cookies will not be set on your device.  To modify your cookie settings, please refer to the Help menu of your browser. ',
  PRIVACY_POLICY_78:
    ' to cookies on our Site we will not set category A, B C, or D cookies on your device. Please note that if you do this you may not be able to use the full functionality of our Site.',
  PRIVACY_POLICY_79: 'If you agree to cookies',
  PRIVACY_POLICY_80: 'If you do nothing',
  PRIVACY_POLICY_81: 'If you say no ',
  PRIVACY_POLICY_82: 'CHANGES TO THIS PRIVACY AND COOKIES POLICY',
  PRIVACY_POLICY_83:
    'We keep this Policy under regular review. We may change this Policy from time to time by updating this page in order to reflect changes in the law and/or our privacy practices. The date at the top of this Policy will be updated accordingly.',
  PRIVACY_POLICY_84:
    'We encourage you to check the date of this Policy when you visit this Site for any updates or changes. We will notify you of any modified versions of this Policy that might materially affect the way we use or disclose your personal information.',
  PRIVACY_POLICY_85: 'Contact/address details',
  PRIVACY_POLICY_86: 'If you have any questions about this Privacy Policy, please contact us at:',
  PRIVACY_POLICY_87: 'Raffle House Ltd,',
  PRIVACY_POLICY_88: '1 Ashmere Grove,',
  PRIVACY_POLICY_89: 'London',
  PRIVACY_POLICY_90: 'SW2 5UH',
  PRIVACY_POLICY_91: 'Cookie name/type',
  PRIVACY_POLICY_92: 'Expiration Period',
  PRIVACY_POLICY_93: 'Purpose',
  PRIVACY_POLICY_94: 'Category of cookie as defined in the ICC Cookie Guide',
  PRIVACY_POLICY_95: 'ASP.NET_SessionId',
  PRIVACY_POLICY_96: '(these cookies typically expire at the end of your session or at the latest within one week)',
  PRIVACY_POLICY_97:
    'Authentication session to prevent having to login multiple times in one browser session. A unique ID that ties the current session to a database settings table.',
  PRIVACY_POLICY_98: 'A. Strictly necessary',
  PRIVACY_POLICY_99: 'Session',
  PRIVACY_POLICY_100: 'C. Functionality',
  PRIVACY_POLICY_101: 'VSI Cookie Status',
  PRIVACY_POLICY_102: '12 months from set/update',
  PRIVACY_POLICY_103:
    'Enables us to remember if you have seen the cookie information panel and accept cookies from this website.',
  PRIVACY_POLICY_104: 'Google Analytics',
  PRIVACY_POLICY_105: 'utma',
  PRIVACY_POLICY_106: '2 years from set/update',
  PRIVACY_POLICY_107: 'Provides a unique ID for each Web browser that visits this site.',
  PRIVACY_POLICY_108: 'D. Targeting/advertising',
  PRIVACY_POLICY_109: 'utmb',
  PRIVACY_POLICY_110: '30 minutes from set/update',
  PRIVACY_POLICY_111: 'Used to establish and continue a user session with this website.',
  PRIVACY_POLICY_112: 'utmc',
  PRIVACY_POLICY_113: '(these cookies typically expire at the end of your session ie when you close your browser)',
  PRIVACY_POLICY_114: 'Twitter eu_cn',
  PRIVACY_POLICY_115: '1 year from set/update',
  PRIVACY_POLICY_116:
    'Used to collect information about users browsing behaviour for marketing purposes including digital display and social media advertising',
  PRIVACY_POLICY_117: 'Twitter _ga',
  PRIVACY_POLICY_118: 'Twitter guest_id',
  PRIVACY_POLICY_119: 'Twitter',
  PRIVACY_POLICY_120: 'personalization_id',
  PRIVACY_POLICY_121:
    'We embed videos from YouTube using YouTube’s privacy-enhanced mode. This mode may set cookies on your computer once you click on the YouTube video player',
  PRIVACY_POLICY_122: 'D. Targeting',
  PRIVACY_POLICY_123: 'YouTube Cookies',
  RIZES_ANSWER1: 'We’ll also be giving away three of your choosing.',
  PRIZES: 'Prizes',
  PRIZES_ANSWER10: ' contact us ',
  PRIZES_ANSWER11: 'if you have any more questions.',
  PRIZES_ANSWER2: ' £1,000 cash prizes ',
  PRIZES_ANSWER3:
    'to three runners-up in every competition! Raffle House is committed to giving back to the community and recognises',
  PRIZES_ANSWER4: ' the plight of homeless people ',
  PRIZES_ANSWER5: 'in the UK. Every ticket purchase will see a',
  PRIZES_ANSWER6: ' 5% donation to a homeless charity ',
  PRIZES_ANSWER7:
    'of your choosing. We hope to increase that charitable donation as we gain more customers. Feel free to read our',
  PRIZES_ANSWER8: ' FAQs ',
  PRIZES_ANSWER9: 'or',
  PRIZES_QUESTION: 'Cash Prizes & Charitable Donations',
  PROFILE_TICKETS_NUMBER: 'Ticket(s) Number: ',
  PROFILE_TICKETS_COMPETITION: 'Competition - ',
  PROFILE_TICKETS_QUESTION: 'Questions - ',
  PROFILE_TICKETS_OPTIONS: 'Options - ',
  PROFILE_TICKETS_ANSWER: 'Your answer - ',
  PROFILE_TICKETS_STATUS: 'Answer - ',
  PROFILE_TICKETS_CORRECT: 'Correct',
  PROFILE_TICKETS_INCCORECT: 'Incorrect',
  PROFILE_TICKETS_CHARITY: 'Your chosen charity - ',
  PROFILE_TICKETS_QUANTITY: 'Ticket Quantity',
  PROFILE_TICKETS_PRICE: 'Ticket Price',
  PROPERTY_COMPETITION: '£750,000 property competition',
  PROUD_MEMBER_OF: 'Proud member of the',
  QUANTITY: 'Qnty',
  QUIZ_QUESTION_HEADER: 'According to the UN’s "The World\'s Cities" 2018 report: ',
  RAFFLE_HOUSE: 'Raffle House',
  RAISED_CHARITY: 'Raised for charity',
  READ_BLOG: 'Read our Blog to find out what we are up to',
  READ_MORE: 'Read more',
  REFFERALS: ' referrals',
  REFER_DESCR: 'There are no limits to how much you can earn, so invite all your friends',
  REFERRAL_FREEBIES: 'Referral freebies',
  REFER_FRIEND: 'Win £5,000!',
  REFER_FRIENDS: 'Refer Friends',
  REFER: 'Refer',
  REFERRAL: 'Referral',
  REFER_FRIENDS_TEXT: ' Refer friends to earn free tickets!',
  REFER_FRIENDS_TEXT_2: "When each of them buys even a single entry, you'll be rewarded with 10 free tickets!",
  REFER_FRIENDS_TEXT_3: "You can refer as many friends as you like and there's no limit on how many free tickets.",
  REFERRAL_FREEBIE: 'Referral freebie',
  REGISTER: 'Register',
  SAVE: 'Save',
  SAY_HI: 'say hi. You’ll see them in your basket prior to checkout',
  SCHOOLS: 'Schools',
  SCREEN: 'screen',
  SCROLL_TO_ACCEPT: 'Scroll to accept',
  SCROLL_DOWN: 'Scroll down to see all the current prizes on offer!',
  SEE_TICKETS: 'See tickets',
  SECTION_FOR_A_QUICK: 'section for a quick explainer video.',
  SEE_PROPERTY: 'SEE PROPERTY PRIZE',
  SEE_WEEKLY: 'SEE WEEKLY PRIZES',
  SEND: 'Send',
  SELECT_TICKET: 'Select Tickets',
  TICKETS: 'Tickets',
  SEE_LIFESTYLE_PRIZES: 'SEE LIFESTYLE PRIZES',
  SELECT_TICKET_TITLE: 'Select tickets & charitable donation',
  SELECT_TICKET_MESSAGE:
    'Choose the number of tickets you want and your choice of homelessness charitable donation, covered by us!',
  SELECT_DONATION: 'Select a Сharitable Donation',
  SECURE_PAYMENTS:
    'We accept debit card payments through Checkout.com’s secure processor. Raffle House never sees or stores your financial information. Find out more about our ',
  SHARE_TEXT_USE_LINK_1: `Raffle House has awarded hundreds of thousands of pounds in prizes with the last big winner now a homeowner. Tickets to the house draw cost just £2.00 with loads of other amazing prizes. You can also enter for free by post.`,
  SHARE_TEXT_USE_LINK_2: ` or you can enter for free by post. No Mortgage, no Stamp Duty, no strings attached. `,
  SHARE_TEXT_USE_LINK_WHATSAPP: `Hey! Raffle House's last winner now owns a home and the current property and Weekly Lifestyle Prizes NEED to be seen. Tickets cost £2 or you can enter for free by post. No Mortgage, no Stamp Duty, no strings attached. – oh, and I’ll get some referral entries too 😊 Someone's guaranteed to win the property! `,
  SHEPHERDS: "Shepherd's Bush Market",
  SHOPS: 'Shops',

  SIGN_IN: 'Sign In',
  SIGN_IN_WITH_EMAIL: 'Sign in',
  SIGNOUT: 'Sign out',
  SIGN_UP_TITLE_1: 'or sign in here',
  SIGN_UP_TITLE: 'Create an account',
  SIGN_UP_SUB_TITLE:
    'Creating an account enables you to buy then access your tickets with ease, earn free tickets by referring friends & helps us manage your account effectively.',
  SIGN_UP_TITLE_NOT_FULL_DATA: 'Please fill in the details below',
  SPEND: 'Spend £20 to ',
  SIGN_UP_TITLE_APRIL_WIN_TEXT1: 'Join our sofa-based race to win £5,000 this month!',
  SIGN_UP_TITLE_APRIL_WIN_TEXT2: 'Create an account and refer the most friends in April to win the prize. Read ',
  SIGN_UP_TITLE_APRIL_WIN_TEXT3: 'here ',
  SIGN_UP_TITLE_APRIL_WIN_TEXT4: ' to find out how. ',
  SIGN_UP_TITLE_APRIL_WIN_TEXT5: 'Already have an account? ',
  SIGN_UP_TITLE_APRIL_WIN_TEXT6: 'Sign in here.',
  SIGN_UP_WITH_EMAIL: 'Sign up with email',
  SIMPLY_TEXT:
    'Simply text or email your friends the link below and our system will automatically recognise them AND add a free ticket to their first order!',
  SIT_BACK_INFO_1: 'If you answered correctly, you’ll then be directed to buy your tickets.',
  SIT_BACK_INFO_2: 'If not, you can try again before paying anything!',
  SIT_BACK_INFO_3: 'You can also click the refer friends button to receive free entries!',
  SIT_BACK: 'Buy tickets',
  SOCIAL_REGISTERED_MESSAGE: 'The password for this account can be changed via social networks',
  SOMETHING_WENT_WRONG: 'Something went wrong! Please try again.',
  SOUTH_LONDON: 'South London',
  SHARE_THIS_ON: 'Share this on:',
  SHOW: 'Show',
  SHOW_OLD_TICKETS: 'Show old tickets',
  HIDE: 'Hide',
  STEP: 'Step',
  STEPS_CREATE_ACCOUNT: 'Create an account or log in and buy your tickets or enter via our free postal route.',
  STEPS_HIT_1: 'Hit  ',
  STEPS_HIT_2: 'from anywhere to select how many tickets you want.',
  STEPS_JACKPOT1:
    'Every ticket is entered into the Jackpot Prize Competition & the weekly Lifestyle Prize draw that you choose every Sunday. Head',
  STEPS_JACKPOT2: 'to see our current prizes.',
  SUBMIT: 'Submit',
  SUCCESS: 'Success',
  SUCCESSFUL_REFERRAL_PROFILE: 'Every 3 successful referrals will see you awarded with 10 free tickets.',
  SURNAME: 'Your surname*',
  TERMS_CONDITIONS_34: 'Summary',
  SUB_HOME1: 'Just one ',
  SUB_HOME2: "enters you into this week's ",
  SUB_HOME3: 'guaranteed Lifestyle Prize draw ',
  SUB_HOME4: 'of your choise & our ',
  SUB_HOME5: '750,000 dream home competition.',
  SUB_HOME6: 'Find out ',
  SUB_HOME7: 'more about us ',
  SUB_HOME8: 'or scroll down to see what you can win! ',
  SUB_HOME9: 'East.2017',
  SUMMARY_CONDITIONS: 'Subject to the Terms and Conditions detailed in full below:',
  SUMMARY_CONDITIONS_1:
    'No purchase, payment or other financial contribution is necessary to enter or win any Competition and Raffle House’s Postal Entry is available for all Competitions.',
  SUMMARY_CONDITIONS_2:
    'Raffle House Ltd. gives its customers the chance to win Jackpot, Weekly Lifestyle and Cash Prizes through Competitions it displays on its Website.',
  SUMMARY_CONDITIONS_3:
    'Online and Postal Entrants are entered into its Jackpot Prize Competition and that week’s Weekly Lifestyle and Cash Prize Competition.',
  SUMMARY_CONDITIONS_4:
    'The Jackpot Competition Start Date is 1 August, 2020 and the Closing Date is 30 June, 2021. The Weekly Lifestyle and Cash Prize Competitions reset every Sunday at midnight.',
  SUMMARY_CONDITIONS_5:
    'For the Property to be awarded, the Minimum Number of Entries to be sold is 650,000, and more information on this threshold can be found in our Help Centre (click FAQ in the menu and search for “threshold”).',
  SUMMARY_CONDITIONS_6:
    'In the event that the threshold is not met, full details regarding the 90% cash-prize can be found below.',
  SUMMARY_CONDITIONS_7:
    'The Promoter offers an automatic charitable donation option at checkout and urges its customers to select a charity.',
  SUMMARY_CONDITIONS_8:
    'Customers that pay the Entry Fee will be emailed their receipt and Ticket(s) and find their Ticket(s) in their Account. ',
  SUMMARY_CONDITIONS_9:
    'Customers that choose the Postal Entry will find their Ticket(s) in their Account following an administration period but cannot be emailed a receipt.',
  SWEET_HOME: 'Home, sweet home!',
  TAKE_LOOK1: 'Take a look at the Lifestyle Prizes ',
  TAKE_LOOK2: 'on this week',
  TAKE_SARA_TOUR: 'Take a tour with Sara',
  TEN_TICKETS: '10 Tickets',
  TEXT_UNDER_LINK: `Start emailing or texting your link to earn free tickets now! Once your referrals have entered, we'll automatically add your bonus tickets!`,

  TERMS_CONDITIONS: 'Terms & Conditions',
  TERMS_CONDITIONS_TERMS_LABEL: 'Terms',
  TERMS_CONDITIONS_PROMOTERS_LABEL: 'The Promoters',
  TERMS_CONDITIONS_ENTER_LABEL: 'How to enter',
  TERMS_CONDITIONS_ELIGIBILITY_LABEL: 'Eligibility',
  TERMS_CONDITIONS_PRIZE_DRAW_LABEL: 'The Prize and the Draw',
  TERMS_CONDITIONS_WINNER_LABEL: 'Winner Announcement',
  TERMS_CONDITIONS_LIMITATION_LABEL: 'Limitation of Liability',
  TERMS_CONDITIONS_GENERAL_LABEL: 'General',
  TERMS_CONDITIONS_DEFINITIONS_LABEL: 'Definitions',
  TERMS_CONDITIONS_1:
    'The following Terms & Conditions apply to all Entries submitted to the Competition for which is set out in these Terms.',
  TERMS_CONDITIONS_2:
    'In entering the Competition, Entrants will be required to tick on the Website that they are over the age of 18, and have read and accepted these Terms and Conditions and agreed to be bound by them.',
  TERMS_CONDITIONS_3:
    'The Competition is operated by the Promoter. The Promoter is authorised by the owner of the Property to offer the Property as a prize in this Competition. The Promoters are members of the Property Redress Scheme, more information about which can be found here: ',
  TERMS_CONDITIONS_3_LINK: 'www.theprs.co.uk.',
  TERMS_CONDITIONS_4:
    'By submitting an Entry, Entrants agree to be bound by these Terms and Conditions. If you do not so agree, do not submit an Entry.',
  TERMS_CONDITIONS_5:
    'No purchase, payment or other financial contribution is necessary to enter or win any Competition and Raffle House’s Postal Entry is available for all Competitions. To validly enter the Competition Entrants must:',
  TERMS_CONDITIONS_5_2: 'First, create a free Account; and then either:',
  TERMS_CONDITIONS_5_2_1:
    'pay the Entry Fee. The entry will qualify for the Jackpot Competition and the Weekly Lifestyle and Cash Prize Competitions as per their Definitions; or ',
  TERMS_CONDITIONS_5_2_2:
    'Print your full name, phone number and email address on a piece of paper and enclose in an envelope. This information must match with the information provided when creating a free account or your Postal Entry will be deemed invalid. With a first-class stamp, post the completed entry to: Raffle House Postal Entry, Civica Election Services, 33 Clarendon Road, London N80NW. There is a limit of one entry per outer stamped, mailing envelope. Each valid entry made via the Postal Entry will automatically qualify for both the Jackpot Competition and, if the requirements are met, the Weekly Lifestyle and Cash Prize Competition with effect from the date that an entry is received and processed. No mechanically reproduced entries are permitted. Illegible entries are void. Raffle House is not responsible for lost, late, mutilated, delayed, inaccurate, incomplete, postage due, misdirected entries or entries not received by the last day of the applicable entry period. Each Postal Entry will be equivalent to a single entry into each of the Jackpot Competition and the Weekly Lifestyle and Cash Prize Competition that may be awarded via paying an Entry Fee. You must provide all required information to be eligible to enter and win. Automated entries will be disqualified. Postal Entries must be submitted by the Raffle House account holder. No copies, facsimiles, or mechanical reproductions of entries will be accepted. Postal Entries shall have the same status as Entries paid in cash, except they shall not count towards the calculation of the Minimum Number of Entries. Civica Election Services are a third-party entity that provide the Promoter with an independent postal and submission verification service only, with no recourse or liability with regards to providing prizes to entrants as advertised by the Promoter. ',
  TERMS_CONDITIONS_6:
    'At the Promoter’s sole discretion, the requirement to pay the Entry Fee may be satisfied by redeeming a voucher code or credit provided to the Entrant under one of its promotional schemes. Entries so credited as paid shall have the same status as Entries paid in cash excepting that they shall not count towards the calculation of the Minimum Number of Entries.',
  TERMS_CONDITIONS_7_1:
    'Payment of the Entry Fee shall be processed by Checkout.com. Card payments shall be subject to Checkout.com’s terms and conditions, which shall be available to view by following the link: ',
  TERMS_CONDITIONS_7_LINK_2: 'http://checkout.com/ ',
  TERMS_CONDITIONS_7_2:
    ' .The Entry Fee must be received by the Promoter in pounds sterling only and if you make payment in a different currency, the amount you pay in that currency may have currency exchange and international transfer charges added.',
  TERMS_CONDITIONS_8:
    'Entrants will be asked to provide their contact details, including e-mail address and phone number. This information will be processed in accordance with the provisions below and the Promoter’s Data Protection and Privacy Policy. The Promoter’s card service provider, Checkout.com, will require the Entrant’s card payment details. Once the Entry is submitted, the Entrant’s card payment will be electronically approved and the Promoter will not retain any records of the Entrant’s card details. Raffle House never sees or stores any card payment details.',
  TERMS_CONDITIONS_9:
    'The Promoter will store and process the Entrant’s name and personal information (which must include details of at least one form of contact) which will be used for the following purposes:',
  TERMS_CONDITIONS_9_1: 'to notify any Winner or Runner(s) Up that they that they have won a prize in the Competition;',
  TERMS_CONDITIONS_9_2: 'to administer the Website and conduct the Competition; and',
  TERMS_CONDITIONS_9_3:
    "after a Draw, to post the Winner and any Runner(s) Up's name and town of residence on the Website and in publicity about the Competition.",
  TERMS_CONDITIONS_10:
    'All Entrants are solely and completely responsible for providing the Promoters with accurate and current personal and contact details.',
  TERMS_CONDITIONS_11:
    'The Promoters will be in no way liable for any failure or inability to make contact with any Entrant due to any errors, omissions or inaccuracies in the contact details provided by the Entrants or otherwise.',
  TERMS_CONDITIONS_12: 'The Promoters will not accept:',
  TERMS_CONDITIONS_12_1:
    'responsibility for Entries that are lost, mislaid, damaged or delayed in transit, regardless of cause including, for example, equipment failure, technical malfunction, systems, satellite, network, server, computer hardware or software failure of any kind; or',
  TERMS_CONDITIONS_12_2:
    'any other purported proof of entry to the Competition other than as recorded by the Website’s systems.',
  TERMS_CONDITIONS_13:
    'An Entry shall be declared void (without any refund being given) if the Entrant engages in: (a) any form of fraud (actual or apparent); (b) fraudulent misrepresentation; (c) fraudulent concealment; (d) hacking or interference with the proper functioning of the Website; or (e) amending, or unauthorised use of, any of the code that constitutes the Website.',
  TERMS_CONDITIONS_14:
    'Each individual Entrant may submit up to 5,000 (five thousand) paid Entries, and the Entry Fee must be paid on each purchase. If using the Postal Entry, each individual Entrant may submit up to 5,000 (five thousand) valid Entries, but there is a limit of one entry per outer stamped, mailing envelope (pursuant to term 3). Any bonus Tickets applied to online Entries will also be applied to Postal Entries following processing and administration.',
  TERMS_CONDITIONS_15:
    'All entries will be electronically recorded by the Promoter and accessible via the Entrant’s Account. Valid Postal Entry submissions will take a processing time before being visible via the Entrant’s account.',
  TERMS_CONDITIONS_16:
    'All Postal Entries will become the property of the Promoters on receipt and will not be returned. Entrants via this method will not receive notification of their Ticket; instead, it is incumbent on the individual to check their account online.',
  TERMS_CONDITIONS_17:
    'All Entries to the Competition are final and no refunds shall be made at any time or for any reason, except in the case of Entries submitted after the Closing Date for which payment has been taken and pursuant with terms 46 and 47. In the case of Postal Entries, all those entries posted on the last business day of the Competition will be included, based on typical postal service delivery times.',
  TERMS_CONDITIONS_18: 'For help with Entries, please visit the Website.',
  TERMS_CONDITIONS_19:
    'The Competition is open for entry only to private individuals (i.e., not companies or businesses) aged 18 or over, excluding:',
  TERMS_CONDITIONS_19_1: 'the Promoter, their immediate family, agents, employees or professional advisers; or',
  TERMS_CONDITIONS_19_2:
    'anyone else connected with the development or operation of the Website or the conduct or administration of the Competition in any way, shape or form; or',
  TERMS_CONDITIONS_19_3: 'the members of the immediate families or households of the above.',
  TERMS_CONDITIONS_20:
    'In entering the Competition, all Entrants confirm that they are eligible to do so and eligible to claim any prize awarded in this Competition. The Promoters may require any Entrant to provide evidence that they are eligible to enter the Competition.',
  TERMS_CONDITIONS_21: 'The Promoter will not accept Postal Entries that are:',
  TERMS_CONDITIONS_21_1: 'from countries outside of the UK',
  TERMS_CONDITIONS_21_2: 'automatically generated by computer;',
  TERMS_CONDITIONS_21_3: 'illegible, have been altered, reconstructed, forged, tampered with or incomplete',
  TERMS_CONDITIONS_22:
    'The Promoters reserve all rights to disqualify Entrants if their conduct is contrary to the spirit or intention of the Competition.',
  TERMS_CONDITIONS_23:
    'Entries on behalf of another person can be accepted and joint submissions are allowed. However, a single Account holder and person will be required to take ownership of any Prize and be the Ticket holder.',
  TERMS_CONDITIONS_24:
    'Referrals can be made by any Account holder. Any Referral Entries granted to either the referrer or referral will be treated in exactly the same way as any paid or Postal Entries for any Competition and its corresponding Draw, be it the Jackpot Prize Competition or Weekly Cash Prize Competition.',
  TERMS_CONDITIONS_25:
    'If any Entrant, at any time prior to the end of any Competition, deletes their Account, any Entries held by that Account will also be removed from any future Draw. Deleting Accounts is different from logging out, which will not remove any Entries. Deleting Accounts requires 2-step verification for safety. Any Entries removed from any Draw due to Account deletion will not be re-instated and refunds will not be awarded (pursuant to term 15).',
  TERMS_CONDITIONS_26:
    'By entering the Competition, all Entrants warrant that all information which they submit is accurate, true, current and complete. The Promoter reserves the right to disqualify any Entrant (entirely at their own discretion) if there are reasonable grounds to believe the Entrant has acted in breach of any of these Terms and Conditions.',
  TERMS_CONDITIONS_27: 'The title of the Competition is “A £2 ticket to your dream home”.',
  TERMS_CONDITIONS_28:
    'The Winner and Referral Prize Winner will be determined and awarded the Prizes so specified on the Website and in accordance with these Terms and Conditions.',
  TERMS_CONDITIONS_29:
    'The Draw to determine the Winner, the Referral Prize Winner and any Runner(s) Up will be made on the Draw Date (subject to term 28).',
  TERMS_CONDITIONS_30:
    'The Promoter may, at its sole discretion, and at any time during the Competition up to the end of the Closing Date, bring forward the Closing Date to a new Closing Date, to be before and no later than the specified Closing Date. Also, the Promoter may, at its sole discretion, and at any time during the Competition up to the end of the Closing Date, extend the Competition Closing Date.',
  TERMS_CONDITIONS_31:
    'If by the Closing Date (subject to term 28), at least the Minimum Number of Entries has been received, the Competition will close and on the Draw Date:',
  TERMS_CONDITIONS_31_1:
    'there will be a Draw to determine the Winner (and Runner(s) Up in the event that the winner cannot be contacted within the time allocated in Term 31), and the prize shall be the Property, plus the payment of Stamp Duty (as set out in Term 37) and the Bonus Prize ;',
  TERMS_CONDITIONS_31_2:
    'there will be an evaluation to determine the Winner of the Referral Prize, who shall be awarded the Referral Prize.',
  TERMS_CONDITIONS_32:
    'If by the Closing Date (subject to term 28), the actual number of Entries received is less than the Minimum Number of Entries, the Competition will close and on the Draw Date there will be a Draw, at the sole discretion of the Promoter, by reference to the proceeds received from the Entries to:',
  TERMS_CONDITIONS_32_0: 'award to the Winner the Cash Prize and;',
  TERMS_CONDITIONS_32_1:
    'There will be an evaluation to determine the Winner of the Referral Bonus Prize, who shall be awarded the Referral Bonus Prize.',
  TERMS_CONDITIONS_33:
    'If any Jackpot Prize Winner or Runner(s) Up cannot be contacted by the Promoter within 21 days, and any Weekly Lifestyle and Cash Prize Winner cannot be contacted by the Promoter within 7 days of being notified of their status as the Winner by e-mail and/or, telephone to the contact details submitted in their Entry, the Promoter shall be entitled to award their prize to the Entrant next Drawn, with the effect that the first Runner Up (as applicable) would become the Winner, and so on. At the Draw, there shall be such number of additional / reserve Entries Drawn but not actioned or disclosed until required to permit the operation of this alternate Winner / Runner Up arrangement. Any alternate Jackpot Prize Winner or Runner Up shall likewise comply with the above 21-day contact requirement and any alternate Weekly Lifestyle and Cash Prize Winner (or Runner Up) shall likewise comply with the above 7-day contact requirement. ',
  TERMS_CONDITIONS_34:
    'The Promoter shall take all reasonable steps to ensure and preserve the random nature of all Draws but shall not be required to comply with any particular regulatory or other standards in this respect. The Jackpot Prize Competition Draw shall be managed and verified by Sterling Lotteries who are independent of the Promoter and licensed and regulated by the Gambling Commission (More information about them can be found here: ',
  TERMS_CONDITIONS_34_LINK: 'https://sterlinglotteries.co.uk/ ',
  TERMS_CONDITIONS_34_1:
    '. The Weekly Lifestyle and Cash Prize Draws shall be made using random number generating software and independently verified. The result(s) of any Draw are final.',
  TERMS_CONDITIONS_35:
    'On the Draw Date, the Promoter shall calculate the Charity Donation and apportion the same on a pro-rata basis between the Charities in accordance with the aggregated preferences specified by the Entrants at the time of Entry. The Promoter shall publish the details of the Charity Donation made on the Website. The Promoter’s calculations of the Charity Donation apportionment are final.',
  TERMS_CONDITIONS_36:
    'The Winner and any Runner(s) Up will be required to forward a copy of their passport or driving licence together with two utility bills or bank statements (or a combination of the two) dated within the previous three months to the Promoters to prove their identity and, in the event of an online Entry, that their Entry was made using a valid debit/credit card belonging to the Entrant or used with the express authorisation of the card holder and that there is no lawful impediment (subject to any applicable UK or international law) to Winner or any Runner(s) Up being awarded any prize in the Competition. In the event that Promoter reasonably believes that there may be a lawful impediment to awarding a prize to a Winner or any Runner(s) Up, the Promoter may suspend making such award until the legal issue is resolved, or if, in the reasonable opinion of the Promoter such issue cannot be resolved, to award that prize in a like manner as set out in term 31 above.',
  TERMS_CONDITIONS_37:
    'If the prize comprises the Property, the Promoter will arrange for the Property to be transferred by (or on behalf of) the legal owner(s)/proprietor(s) of the Property to the Winner subject to the terms of a separate sale contract and instrument of transfer to be entered into between the owner(s) of the Property and the Winner, free from any charges, mortgages or other encumbrances to its legal or equitable title and subject always to compliance with all applicable law. The Promoter shall not be responsible or liable for any matters arising out of the conduct of the transfer of the Property including without limitation any negligence of any conveyancers.',
  TERMS_CONDITIONS_38:
    'The Promoters take care to only offer properties in good repair though do not in any way guarantee or give any warranties as to the value of the Property, its condition, history or any other matters associated with the Property.',
  TERMS_CONDITIONS_39:
    'Any Stamp Duty Land Tax (SDLT) and contribution to SDLT will only be paid to the extent that SDLT is actually payable. The maximum SDLT that could be payable (if the winner already owns a home) is £35,000. The Winner will be responsible for all other taxes or charges incurred arising out of the transfer to and ownership or occupation of the Property, including council taxes, grounds rents and service charges which are not covered by the Bonus Prize.',
  TERMS_CONDITIONS_40:
    'The Winner shall execute all documents required for the transfer of the Property and take all steps they can to ensure transfer and registration of the Property at the Land Registry within 4 months of the Draw Date.',
  TERMS_CONDITIONS_41:
    'The Promoter shall only deal with any Winner or, as applicable, Runner(s) Up or their estate (in the case of their prior death) and no prize in the Competition is transferable.',
  TERMS_CONDITIONS_42:
    'The Winner(s) and any Runner(s) Up shall be announced and publicised on the Website and via email marketing campaigns, as well as on Raffle House’s associated social media pages/sites, such as Facebook, Instagram and Twitter. Any Winner and Runner(s) Up shall cooperate with and participate in the Promoter’s reasonable publicity requests.',
  TERMS_CONDITIONS_43:
    'Insofar as is permitted by law, the Promoter, their agents or distributors will not in any circumstances be responsible or liable to compensate any Entrant or accept any liability for any loss, damage, personal injury or death occurring as a result of submitting and Entry or in relation to the Property except where it caused by the negligence or fraud of the Promoter, their agents or distributors, or that of their employees. The Winner’s(`) statutory rights are not affected.',
  TERMS_CONDITIONS_44:
    'The liability of the Promoter to each Entrant is limited to the aggregate value of the Entry Fees paid by that Entrant.',
  TERMS_CONDITIONS_45:
    'The Promoters accept no liability for errors or omissions contained within the description of the Property or the Property Value or for any other description or specification or any other part of the Website. It is the responsibility of each Entrant (and in particular the Winner) to satisfy him/herself as to the accuracy of any such details and/or any content of the Website.',
  TERMS_CONDITIONS_46:
    'Each Entrant agrees that the usual requirement under the Consumer Protection (Distance Selling) Regulations 2000 for any goods and services ordered online to be supplied within 30 days will not apply to the Competition.',
  TERMS_CONDITIONS_47: 'The Promoter shall not be a trustee of any Entry Fees received.',
  TERMS_CONDITIONS_48:
    'The Promoter reserves the right to suspend or cancel the Competition at any time either before or after Entries have been received. If the Competition is cancelled, the Promoter will return the Entry Fees to each Entrant by bank card refund or by cheque (at the Promoter’s sole discretion). Any refund of the Entry Fee may be subject to deduction of any irrecoverable acquiring bank service charge fees. Where the Entry Fee is returned, the Promoter shall have no further liability to the Entrant or to any other person.',
  TERMS_CONDITIONS_49:
    'Without prejudice to the operation of term 46 above, the Promoters reserve the right to cancel the Competition in the event that an order is made or a resolution is passed for the winding up of the Promoter, or an order is made for the appointment of an administrator to manage the affairs of the Promoter, or circumstances arise which entitle a court or creditor to appoint a receiver or manager or which entitle a court to make a winding up order, or the Promoter takes or suffers any analogous action in consequence of debt or an application to court for protection from its creditors is made by the Promoter.',
  TERMS_CONDITIONS_50:
    'The Promoters reserve the right to make reasonable amendments to these Terms and Conditions at any time, with immediate effect upon publishing such amendments on the Website. Any such amendments shall not prejudice any Entries received prior to the time of such changes.',
  TERMS_CONDITIONS_52:
    'These Terms and Conditions shall not create or be construed as creating any form of contract, joint venture or other agreement between any Entrant and the Promoters.',
  TERMS_CONDITIONS_53:
    'The Competition, its administration and all associated activities shall be governed by the laws of England and Wales and the parties submit to the exclusive jurisdiction of the courts of England and Wales.',
  TERMS_CONDITIONS_54:
    'Each Entrant should retain a copy of these Terms and Conditions as at the date of their Entry for their future reference.',
  TERMS_CONDITIONS_55: 'The following definitions apply in these Terms and Conditions:',
  TERMS_CONDITIONS_55_1:
    'ACCOUNT: means a free-to-create online Raffle House account, created using personal information, such as name, email address and telephone number or by using a Facebook or Google login, as set out in term 6 and processed in accordance with the Promoter’s Data Protection and Privacy Policy. The Account will display information pertinent to the Jackpot Prize Competition and Weekly Lifestyle and Cash Prize Competitions, such as Tickets, Referral links, account information and management tools.',
  TERMS_CONDITIONS_55_2:
    'BONUS PRIZE: is a sum payable to the Winner (subject to term 29) of up to £3,000 to cover council tax and reasonable utilities for a period of 12 months; and reasonable solicitors’ fees (to include disbursements) incurred in connection with the transfer of the Property to the Winner, capped at £1,500 inclusive of VAT.',
  TERMS_CONDITIONS_55_3:
    'CASH PRIZE: in the event of a Draw under term 30, a cash prize payable to the Winner being calculated as (1) 90% of the total value of the total Entry Fees received up to the Closing Date net the Promoter’s costs and overheads; less (2) the amount of the Charity Donation; and less (3) the payment processor and acquiring bank fees.',
  TERMS_CONDITIONS_55_4:
    'CHARITY(IES): means the charities or charitable causes specified on the Website for the Competition to whom the Charitable Donation will be paid after the Draw.',
  TERMS_CONDITIONS_55_5:
    'CHARITY DONATION: means 2.5% of the total value of Entry Fees received to be payable to the Charity(ies) after the Draw Date - where there is more than one Charity, in proportion to the preferences stated by all the Entrants at the time of submitting their Entry.',
  TERMS_CONDITIONS_55_6: 'CLOSING DATE: means 30 June, 2021, subject to term 28.',
  TERMS_CONDITIONS_55_7:
    'DRAW or DRAWN: means the choice of an Entry or Entries, made at random, which may be made by Postal Entry or by way of a computer function (at the Promoter’s sole discretion) on any Draw Date (for the Weekly Lifestyle and Cash Prize Competitions or Jackpot Prize Competition) to determine a Winner and, where applicable, any Runner(s) Up.',
  TERMS_CONDITIONS_55_8:
    'DRAW DATE: Up to but no more than 21 days after the Closing Date of any Competition and at the sole discretion of the promoter (subject to term 28). The Weekly Lifestyle and Cash Prize Draws will be held the Monday following the closure of the previous Sunday’s weekly competition.',
  TERMS_CONDITIONS_55_9:
    'ENTRANT: any natural person (not including a limited company, partnership or limited liability partnership) who validly submits an Entry to the Competition, in accordance with these Terms and Conditions.',
  TERMS_CONDITIONS_55_10:
    'ENTRY: means a validly submitted and completed entry by the Entrant through the Website, Postal Entry or Referral in order to obtain an opportunity to win any of the Prizes (and ENTRIES means more than one Entry).',
  TERMS_CONDITIONS_55_11:
    'ENTRY FEE: the entry fee of £2 (two pounds) payable as a condition of submitting a single valid Entry online only.',
  TERMS_CONDITIONS_55_12:
    'JACKPOT PRIZE: means the Property to be awarded to the Winner subject to a Draw under term 29 or the Cash Prize subject to a Draw under term 30.',
  TERMS_CONDITIONS_55_13:
    'JACKPOT COMPETITION: means the Jackpot Competition operated by the Promoters to which these Terms and Conditions apply, wherein the Entrants submit Entries via the Website or via Postal Entry to win the Jackpot Prize. All Entries submitted into the Jackpot Competition will also be added into that week’s Weekly Lifestyle and Cash Prize Draw when requirements have been met, and, in the case of Postal Entries received after the cut-off, the next corresponding Draw.',
  TERMS_CONDITIONS_55_14:
    'MINIMUM NUMBER OF ENTRIES: means 650,000 Entries where the Entry Fee has been paid in cash and excepting any Entries credited as paid by the utilisation of a promotional voucher, Postal Entry or credit offered by the Promoter under term 4.',
  TERMS_CONDITIONS_55_15: 'OPENING DATE: means August 1, 2020',
  TERMS_CONDITIONS_55_16:
    'POSTAL ENTRY: the means of entering by post rather than paying online. Like an online entry, a free account must be created in order for a free entry to be applied. A full explanation of the Postal Entry requirements can be found in term 3 and on the Website at ',
  TERMS_CONDITIONS_55_16_LINK: 'https://rafflehouse.com/post',
  TERMS_CONDITIONS_55_17:
    'PROPERTY: means 1A, Eastlake Road, London, SE5 9QJ, as shown on the Promoters’ Website, in the 3D tour, floorplan and photos and other media.',
  TERMS_CONDITIONS_55_18:
    'PROPERTY VALUE: £750,000 being the amount so specified as an asking price by the owner of the Property for the purposes of offering it as a prize in this Jackpot Prize Competition and verified by an independent agent, which is accessible on the Website.',
  TERMS_CONDITIONS_55_19:
    'PROMOTER: means Raffle House Ltd, company number 10928240, whose registered office address is 1B Ashmere Grove, London, SW2 5UH',
  TERMS_CONDITIONS_55_20:
    'REFERRAL: means any new paid Entrant recorded through any account holders’ unique referral link, which can be found and accessed in every Account.',
  TERMS_CONDITIONS_55_21:
    'REFRERRAL PRIZE: means a cash prize of £5,000 payable to the Referral Winner following the closure of the Jackpot Prize Competition (subject to term 28).',
  TERMS_CONDITIONS_55_22:
    'REFERRAL WINNER: means the Winner of the Referral Bonus Prize, as defined by the highest total number of Referrals recorded by the Website for that Account Holder. The Promoter’s decision is final and all referral counts will be available for review on the Website following the closure of the Jackpot Prize Competition.',
  TERMS_CONDITIONS_55_23:
    'RUNNER(S) UP: The Entrant(s) corresponding to the next reasonable number of Tickets Drawn after the Winner on the Draw Date and handled in chronological order reflected in their Draw positions.',
  TERMS_CONDITIONS_55_24:
    'TICKET(S): means a unique identifier used for the purposes of determining a Winner or Runner(s) Up during any Draw. Tickets are Universally Unique Identifiers comprised of 32 letters and numbers conforming to this logic: 32 hexadecimal (base-16) digits, displayed in five groups separated by hyphens, in the form 8-4-4-4-12 for a total of 36 characters (32 hexadecimal characters and 4 hyphens). It should be noted that they are unique identifiers rather than numbers drawn via a lottery style mechanic.',
  TERMS_CONDITIONS_55_25: 'WEBSITE: https://www.rafflehouse.com',
  TERMS_CONDITIONS_55_26:
    'WEEKLY CASH PRIZE COMPETITION: means the weekly £1,000 giveaway that runs from Monday (00:00:00:000) through Sunday (23:59:59:999). Any Ticket purchases of £20 or more entered into the Jackpot Prize Competition will be entered into that week’s Weekly Cash Prize Competition. Weekly Cash Prize draws will be secondary to Weekly Lifestyle Prize Draws, and the winner of the Weekly Lifestyle Prize can not win the Weekly Cash Prize. If 10 Postal Entries are received and processed before close of business Friday, they will also be entered into the corresponding weekly Draw. Any Postal entries received after the corresponding weekly cut-off will be counted towards the next weekly draw should a total of 10 or more be received the following week. There will be no Weekly Cash Prize Competition Draw or Winner for paid or Postal Entries in the final full week of the Jackpot Prize Competition.',
  TERMS_CONDITIONS_55_27: `WEEKLY LIFESTYLE PRIZE COMPETITION: means the weekly Lifestyle Prize Competitions that runs from Monday (00:00:00:000) through Sunday (23:59:59:999). Any Ticket purchases entered into the Jackpot Prize Competition will be entered into that week’s Weekly Lifestyle Prize Competition. The Weekly Lifestyle Prize pool is broken down as follows: Blue, entry: £2-£8, Winner's choice of prize available in the Blue prize pool at time of entry or cash alternative of £250; Bronze, entry: £10-£18, Winner's choice of prize available in the Bronze prize pool at time of entry or cash alternative of £1,000; Silver, entry: £20-£38, Winner's choice of prize available in the Silver prize pool at time of entry or cash alternative of £2,500; Gold, entry: £40+, Winner's choice of prize available in the Gold prize pool at time of entry or cash alternative of £5,000. Weekly Lifestyle Prize draws will take precedence over Weekly Cash Prize Draws, and the winner of the Weekly Lifestyle Prize can not win the Weekly Cash Prize. Postal entries are accepted and valid for any prize pool. If the corresponding number of Postal Entries are received and processed before close of business Friday, they will also be entered into the corresponding weekly Draw (1-4: Blue; 5-9: Bronze; 10-19: Silver; 20+: Gold). Any Postal entries received after the corresponding weekly cut-off will be counted towards the next weekly draw. There will be no Weekly Lifestyle Prize Competition Draw or Winner for paid or Postal Entries in the final full week of the Jackpot Prize Competition.`,
  TERMS_CONDITIONS_55_28:
    'WINNER: The Entrant corresponding to the first Correct Entry Drawn on the Draw Date and in accordance with these Terms and Conditions.',
  TICKETS_TEXT_1: '£1,000 guaranteed to be won every week!',
  TICKETS_TEXT_2: 'Spend £20 or more in the main property competition to be automatically entered.',
  TURNING_DREAMS: 'Turning dreams into bricks and mortar',
  THANKS_FOR_PLAYING: 'Thanks for playing!',
  THANK_YOU_MESSAGE: 'Your tickets have been entered into the draw!',
  THANK_YOU_TICKETS_NUM_LABEL: 'Total no. of tickets claimed',
  THANK_YOU_TOTAL_COST: 'Total order value',
  THEY_ENTER: 'They enter',
  THIS_IS_YOUR_CHANCE_1: 'This is your chance to win a home for just ',
  THIS_IS_YOUR_CHANCE_2: ' Our last jackpot-winner is now a homeowner!',
  THIS_WEEK_DRAW: "This week's Lifestyle Prize competition",
  CHECK_ABOUT_1: 'Check out our ',
  CHECK_ABOUT_2: ' section to understand the',
  CHECK_ABOUT_3: ' simple steps to enter',
  TICKET_AFFILIATE_BONUS_DESCRIPTION: '* We’ve doubled any paid entries to say thank you for your first purchase!',
  TICKET_AFFILIATE_QUANTITY: 'Referral Bonus*',
  TICKET_ERROR: 'Please select a valid ticket number.',
  TICKET_TOTAL_PRICE: 'You pay',
  TICKETS_TOTAL: 'Total tickets',
  TICKET_QUANTITY: 'Tickets selected',
  TICKET_FREE_QUANTITY: 'Free tickets',
  TICKET_LOYALTY_QUANTITY: 'Loyalty bonus*',
  TICKET_TOTAL_QUANTITY: 'Total',
  TICKET_LOYALTY_DESCRIPTION_LOGGED_IN_RETURNING: '* We’ve doubled any paid entries to say thank you for your loyalty!',
  TICKET_REF: 'Ticket Ref-',
  TICKET_TEXT_1:
    'You can buy as many tickets as you like, up to 100 per account. You only have to answer once no matter how many tickets you buy .',
  TICKET_TEXT_2:
    'Once you’ve selected how many and click continue, you’ll be able to select your choice of charitable donation. ',
  TICKET_TEXT_3:
    'Once you’ve paid & played, we’ll email you confirmation of your entry and, don’t forget, at the end, you can refer friends to receive free entries.',
  TICKET: 'Ticket',
  TICKET_LOWER: 'ticket ',
  TICKETS: 'Tickets',
  TO_VERIFY: 'You can pay before you verify but be sure to verify ',
  TO_VERIFY_1: '  your email later so we can contact you if you win!',
  TOKEN: 'Token',
  TOUR: '3D Tour',
  TRANSPORT_TEXT:
    'The property boasts high ceilings and features direct access to a pretty courtyard for residents to enjoy at the rear. This bright apartment has been meticulously designed throughout by renowned interior designer Fenton Whelan and offers spacious well thought out accommodation.',
  TRANSPORT: 'Transport',
  TRY_AGAIN: 'Try Again',
  UNKNOWN_ACCESSOR: 'Failed to authenticate.',
  UNLUCKY: 'Unlucky',
  UNLUCKY_TITLE_1: 'Oh no!',
  UNLUCKY_TITLE_2: 'You have one more attempt before a 24-hour cool down.',
  UNLUCKY_MESSAGE_1: 'How about to trying again?',
  UNLUCKY_MESSAGE_2:
    "Just so you know, we use the UN's \"The World's Cities\" 2018 report as our data set to generate the multiple choice answers. Google and the UN don't always agree...",
  UNLUCKY_MESSAGE_3:
    "Incorrectly answered entries won't be considered in the draw - that's why we don't let you pay for them.",
  UNLUCKY_MESSAGE: "That wasn't quite right but don't worry, you're not the first person to get it wrong!",
  USER_AREA: 'User area!',
  UNAUTHORIZED_FREE_ENTRY: "You're not authorised to use this free entry.",
  USER_INVITED: 'User has been invited.',
  USER_NOT_FOUND: 'User not found',
  ACCOUNT_ALREADY_ACTIVATED: 'Account already activated',
  VERIFY_MESSAGE_1: 'A verification link has been emailed to you.',
  VERIFY_MESSAGE_2: 'You can play before you verify but be sure to verify your email',
  VERIFY_MESSAGE_3: 'later so we can contact you if you win!',
  WARNING: 'Warning',
  WEEKLY_PRIZES: 'Weekly Prizes',
  WEEKLE_TITLE_LINE1: 'Win ',
  WEEKLE_TITLE_LINE2: 'a Weekly Lifestyle Prize ',
  WEEKLE_TITLE_LINE3: 'for ',
  WEEKLE_TITLE_LINE4: 'just £',
  WEEKLY_SUB_TITLE1: 'Bank tickets ',
  WEEKLY_SUB_TITLE2: 'to ',
  WEEKLY_SUB_TITLE3: 'win your Dream Home ',
  WEEKLY_SUB_TITLE4: 'by grabbing the ',
  WEEKLY_SUB_TITLE5: 'Weekly Lifestyle Prize that you want',
  WEEKLY_LIFESTYLE: 'The £750,00 Jackpot Property Prize Competition',
  WELCOME_TO_RAFFLE_HOUSE: 'Welcome to Raffle House!',
  WE_CHANGE: "We change our customer's lives by awarding incredible Jackpot property-",
  WHAT_HAPPENS_IF_NOT_SELL_ANSWER1:
    'We do have the option to extend the raffle, which we will only do if we are close to selling the minimum number of tickets required to close. However, if we are not, we will close the raffle and award a cash prize to one of the raffle’s participants. More information on this can be found in the Terms & Conditions.',
  WHAT_HAPPENS_IF_NOT_SELL: 'What happens if you don’t sell all of the tickets before the competition closes?',
  WHAT_IS_THE_QUESTION_ANSWER1:
    'The question will be based on city population sizes and the answer will be multiple-choice.',
  WHAT_IS_THE_QUESTION_ANSWER2:
    'From three potential answers, you’ll need to choose the city that you believe has the highest population according to the UN’s "The World’s Cities" 2018 report.',
  WHAT_IS_THE_QUESTION_ANSWER3: 'For example: Which of these cities has the highest population? London, Tokyo, Paris',
  WHAT_IS_THE_QUESTION_ANSWER4:
    'If you get the answer right, you’ll automatically be awarded a ticket number that is stored securely by Raffle House. We’ll also email you confirming your entry.',
  WHAT_IS_THE_QUESTION: 'What is the question?',
  WHAT_PRIZES_ARE_AVAILABLE_ANSWER1:
    'When the required number of tickets have been sold, Raffle House will be awarding 4 prizes. There will be one lucky winner that get’s the property, no strings attached, as well as three others that will all receive £1,000 cash!',
  WHAT_PRIZES_ARE_AVAILABLE: 'What prizes are available?',
  WHATS_THE_COST_ANSWER1:
    'A single ticket will cost £5 and there are discounts for buying more. You can see these on the ticket selection screen. Also, should you refer friends that participate, you’ll be awarded with free entries! You’ll see a "Refer Friends" button after completing your entry and you can also access this through your account at the top right of every page.',
  WHATS_THE_COST: 'What’s the cost of a single ticket and can I get a discount by buying more?',
  WHY_RAFFLE_HOUSE_ANSWER1: 'Raffle House has been created to help people get onto the property ladder in an ',
  WHY_RAFFLE_HOUSE_ANSWER2: ' easy & affordable ',
  WHY_RAFFLE_HOUSE_ANSWER3:
    'way. And we believe that it’s fun too. We pride ourselves on not just selling any home but on investing the time to list properties that are truly prize-worthy. ',
  WHY_RAFFLE_HOUSE_ANSWER4: 'Every property we raffle is of the highest standard. ',
  WHY_RAFFLE_HOUSE_ANSWER5: ' A single entry and, should you win,',
  WHY_RAFFLE_HOUSE_ANSWER6: ' we’ll just give you the keys. ',
  WHY_RAFFLE_HOUSE_ANSWER7: '  Oh, and',
  WHY_RAFFLE_HOUSE_ANSWER8: ' stamp duty will be covered ',
  WHY_RAFFLE_HOUSE_ANSWER9: ' by us too. It couldn’t be any simpler.',
  WHY_RAFFLE_HOUSE_QUESTION: 'Why Raffle House?',
  WHY_THE_CHARITABLE_ANSWER1:
    'Part of the reason we created Raffle House is because the prospect of owning a home is unattainable for so many people. So we think it’s right that a little goes to those that can’t even afford a ticket, let alone rent.',
  WHY_THE_CHARITABLE_ANSWER2:
    'Raffle House supports the following charities and 5% of your ticket purchase will be donated to them, as chosen by you. We hope to increase that percentage in the coming months.',
  WHY_THE_CHARITABLE_ANSWER4_5:
    'has been providing homes and services to women and their families across London for nearly a century, enabling women to develop their skills and confidence and live independently in the community.',
  WHY_THE_CHARITABLE_ANSWER4: 'Housing for Women ',
  WHY_THE_CHARITABLE_ANSWER5_5:
    'provides accommodation, health support and life skills to homeless young people, aged 16-25, for up to two years, and keeps supporting them for another six months to make sure they can live independently.',
  WHY_THE_CHARITABLE_ANSWER5: 'Centrepoint ',
  WHY_THE_CHARITABLE: 'Why the charitable donation?',
  WILL_YOU_NEXT: 'Will you be next?',
  WIN_HEADLINE: 'Win £5,000 by referring friends!',
  WIN_SUB_TEXT: `The user to refer the most new users by the competitions' close will win £5,000!`,
  WIN_TEXT_1: `Making a referral takes seconds via email, social media or WhatsApp and all tickets earned are added to both the weekly £1,000 and Jackpot draws. You'll receive email confirmation of all successful referrals when they're made. Full details `,
  WIN_TEXT_2: 'available',
  WIN_DREAM_HOME: 'Win Your Dream Home',
  WIM_THIS_HOME_1: 'Win ',
  WIM_THIS_HOME_2: 'this £750,000 ',
  WIM_THIS_HOME_3: 'dream home.',
  WINNERS: 'Winners',
  WINNER_ITEM_TEXT:
    'It takes less than 2 minutes to be entered into this week’s £1,000 draw & ​our jackpot property competition. You have to be in it to win it.',
  WON_EVERY_SUNDAY_1: 'Win a dream home soon and your choice of  ',
  WON_EVERY_SUNDAY_1_1: 'Lifestyle Prize this week',
  WON_EVERY_SUNDAY_2: 'Spend £20 or more and be entered into our £1,000 cash-prize giveaway ',
  WON_EVERY_SUNDAY_3: 'this week as well',
  YOU_RECEIVE_FREE_TICKETS: 'You receive free tickets!',
  YOU_PAY_1: 'You pay',
  YOU_PAY_2: 'Pay',
  YOUR: 'Your',
  YOUR_BALANCE_PROFILE: 'Your balance:',
  YOUR_TICKET_SUMMARY: 'Your ticket summary',
  YOUR_BASKET: 'Your basket',
  YOU_WILL_HAVE: "You'll have more  chances to win ",
  ZONE: 'Zone',
  BACK: 'Back',
  QUIZ_ERROR_MESSAGE: 'Something went wrong trying to load this Quiz. We’re sorry for the inconvenience.',
  LOADING: 'Loading...',
  INACTIVE_RAFFLE: 'This raffle is no longer active. Please try again later.',
  NO_RAFFLES_TITLE: 'Our Whitechapel competition is now closed!',
  NO_RAFFLES_SUBTITLE:
    "We'll be publishing more information on the next steps in the next few hours along with our next property.",
  PROUDLY_PARTNERED: 'Proudly partnered with',
  PAYMENTS_DISCLAIMER_1:
    'Axcess Merchant Services processes client information and funds that are stored by merchant bank First Data, the trading name of First Data Europe Limited, authorised and regulated by the ',
  PAYMENTS_DISCLAIMER_EM: 'UK Financial Conduct Authority (FCA register No. 582703; CCA No. 739230)',
  PAYMENTS_DISCLAIMER_2: '. Raffle House never sees, stores, or shares your financial information.',
  PAYMENT_REDIRECT_NOTICE: "* You will be directed to Sterling Lotteries' secure hosted payment page",
  CREATE_ACCOUNT: 'Create Account',
  CREATE_ACCOUNT_TRY_AGAIN: 'Please come back in 24 hours to try again.',
  ATTEMPT_ALREADY_HAS_ACCOUNT: 'As you already have an account, we’ll email you tomorrow when the cool down is over!',
  ATTEMPT_CREATE_ACCOUNT:
    "Hit the button below to create an account for easy access and in 24 hours we'll send you a reminder too.",
  DAILY_ATTEMPTS: 'You have now used your 2 daily attempts.',
};

export default en;
