import axios from 'axios';
import config from '../config';

class WidgetService {
  static getWidgetByType = async (type) => {
    try {
      const response = await axios.get(`${config.API_BASE_URL}/widgets/byType/${type}`);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };
}

export default WidgetService;
