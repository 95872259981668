import { roundedPrice } from './calculateDiscountAndCredits';

// Twitter tracking
const twitterTrack = (eventId, payload) => {
  if (
    window.twq !== undefined &&
    typeof window.twq === 'function'
    // && process.env.NODE_ENV === 'production'
  ) {
    window.twq('event', eventId, payload);
  }
};

const twitterAddToCartTrack = (totalCost, email = null) => {
  console.log('Twitter Add to Cart tracking event');
  twitterTrack(process.env.TWITTER_PIXEL_ADD_TO_CART_ID, {
    value: roundedPrice(totalCost),
    currency: 'GBP',
    email_address: email,
  });
};

const twitterPageViewTrack = (pathname) => {
  console.log('Twitter Page View tracking event', process.env.REACT_APP_TWITTER_PIXEL_PAGE_VIEW_ID, pathname);
  twitterTrack(process.env.REACT_APP_TWITTER_PIXEL_PAGE_VIEW_ID, { pathname: pathname });
};

export default {
  twitterAddToCartTrack,
  twitterPageViewTrack,
};
