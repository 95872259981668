class SessionStorageService {
  setBasketData = (basketData) => {
    sessionStorage.setItem('basketData', JSON.stringify(basketData));
  };
  getBasketData = () => {
    const data = sessionStorage.getItem('basketData') ?? null;
    return JSON.parse(data);
  };
}

export default new SessionStorageService();
