import React from 'react';

export const useTwitterHeadScript = () => {
  function initialize(e, t, n, s, u, a) {
    return e.twq ||
      ((s = e.twq =
        function () {
          s.exe ? s.exe.apply(s, arguments) : s.queue.push(arguments);
        }),
      (s.version = '1.1'),
      (s.queue = []),
      (u = t.createElement(n)),
      (u.async = !0),
      (u.src = 'https://static.ads-twitter.com/uwt.js'),
      (a = t.getElementsByTagName(n)[0]),
      a.parentNode.insertBefore(u, a));
  }
  function runScript() {
    initialize(window, document, 'script');
    window.twq('config', process.env.REACT_APP_TWITTER_PIXEL_ID);
  }

  React.useEffect(() => {
    const isProduction = process.env.NODE_ENV === 'production';
    // if (isProduction) {
      runScript();
    // }
  }, []);
  return null;
};
