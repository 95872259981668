import axios from 'axios';
import config from '../config';
import { tickets } from '../lib/tickets';

class TicketsService {
  static controller = new AbortController();

  static getTickets = async (type) => {
    const applicationId = config.APPLICATION_ID;

    return await axios.post(
      `${config.API_BASE_URL}/landing-general/getByType`,
      {
        type: type ? type : 'OFFER',
      },
      {
        headers: {
          'X-Application-Id': applicationId,
        },
      }
    );
  };

  static calculateLandingTicketSelector = async (data) => {
    const applicationId = config.APPLICATION_ID;

    return await axios.post(
      `${config.API_BASE_URL}/orders/landing-selector`,
      data,
      {
        headers: {
          'X-Application-Id': applicationId,
        },
      }
    );
  };
  // static getTickets = async () => {
  //   return new Promise((res, rej) => {
  //     setTimeout(() => {
  //       res(tickets);
  //     }, 1000);
  //   });
  // };

  static createUnAuthOrder = async (order) => {
    const type = process.env.REACT_APP_TYPE;
    const applicationId = config.APPLICATION_ID;

    return await axios.post(
      `${config.API_BASE_URL}/orders/unAuth/create`,
      {
        ...order,
        isLandingPage: type === 'pagediscount' ? false : true,
        isPageDiscount: type === 'pagediscount' ? true : false,
        orderType: 'OFFER',
      },
      {
        headers: {
          'X-Application-Id': applicationId,
        },
      }
    );
  };

  static getCurrentPrizeId = async () => {
    const prizeId = '63737eb1c3e2e50033e5446c';
    return new Promise((res, rej) => {
      setTimeout(() => {
        res(prizeId);
      }, 1000);
    });
  };
  static getActiveRaffles = async () => {
    const applicationId = config.APPLICATION_ID;
    const response = await axios.get(
      `${config.API_BASE_URL}/raffles/active/countdowns`,
      {
        headers: {
          'X-Application-Id': applicationId,
        },
      }
    );
    return response;
  };
  static getActiveSubscriptionModels = async () => {
    const applicationId = config.APPLICATION_ID;
    const response = await axios.get(
      `${config.API_BASE_URL}/subscription-models/active`,
      {
        headers: {
          'X-Application-Id': applicationId,
        },
      }
    );
    return response;
  };
  static getActiveRafflesAndSubscriptionModels = async () => {
    let raffleResponse = await this.getActiveRaffles();
    let subscriptionModels = await this.getActiveSubscriptionModels();
    return {
      raffles: raffleResponse.data ?? [],
      subscriptionModels: subscriptionModels.data.subscriptionModels ?? [],
    };
  };
  static generateBasketToken = async (basketData) => {
    const applicationId = config.APPLICATION_ID;
    return await axios.post(
      `${config.API_BASE_URL}/other-orders/create-order`,
      {
        data: basketData,
      },
      {
        headers: {
          'X-Application-Id': applicationId,
        },
      }
    );
  };
  static redirectByToken = (token) => {
    const link = document.createElement('a');
    link.href = `${config.APP_BASE_URL}/other-orders?token=${token}`;
    link.click();
    link.remove();
  };
  static redirectByOrderIds = (orderIds) => {
    const link = document.createElement('a');
    link.href = `${config.APP_BASE_URL}/other-orders?orderIds=${orderIds}`;
    link.click();
    link.remove();
  };
  static goToPost = () => {
    if (window != null) {
      window.open(`${config.APP_BASE_URL}/post`);
    }
  };
  static goToSubscribe = (id) => {
    if (window != null) {
      window.open(`${config.APP_BASE_URL}/subscription`);
    }
  };
}

export default TicketsService;
