import Main from "../components/Main/Main";
import PageLoader from "../components/PageLoader/PageLoader";
import usePrize from "../hooks/usePrize";
import { useNavigate } from "react-router-dom";
import { doublePrizeData, singlePrizeData } from "../lib/static-data";
import CtaButtonLayout from "../components/CtaButtonLayout/CtaButtonLayout";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import MetaTags from "../components/meta-tags";
import useWidget from "../hooks/useWidget";

function HomePage(props) {
  const navigate = useNavigate();
  const {
    raffle,
    prizeId,
    prizeLoading,
    raffles,
    isExpired,
    activeRaffles,
    subscriptionModels,
    isDoublePrize,
  } = usePrize();
  const { widget: timelineWidget, isWidgetLoading: isTimelineWidgetLoading } =
    useWidget({ type: "timeline-logo-rotation-text" });
  const { widget: footerWidget, isWidgetLoading: isFooterWidgetLoading } =
    useWidget({ type: "footer-logo-rotation-text" });

  const onClickExternalLink = (endpoint) => {
    if (process.env.REACT_APP_BASE_URL) {
      window.open(process.env.REACT_APP_BASE_URL + endpoint, '_self');
    }
  }
  const onCTAClick = () => onClickExternalLink(`/ticket-selector`);
  const onLearnMoreClick = () => onClickExternalLink(`/how-it-works`);
  const onLearnMoreCharityClick = () => onClickExternalLink(`/charity-raffle`);
  const onEnterNowClick = () => onClickExternalLink(`/ticket-selector`);

  const isLoading =
    prizeLoading || isTimelineWidgetLoading || isFooterWidgetLoading;

  return (
    <>
      <MetaTags />
      {isLoading ? (
        <CtaButtonLayout isDark>
          <Header raffle={raffle} />
          <PageLoader loading />
          <Footer insideComponent={true} widget={footerWidget} />
        </CtaButtonLayout>
      ) : (
        <Main
          prize={null}
          prizeId={prizeId}
          raffle={raffle}
          raffles={raffles}
          subscriptionModels={subscriptionModels}
          activeRaffles={activeRaffles}
          isExpired={isExpired}
          data={isDoublePrize ? doublePrizeData : singlePrizeData}
          onCTAClick={onCTAClick}
          onLearnMoreClick={onLearnMoreClick}
          onLearnMoreCharityClick={onLearnMoreCharityClick}
          onEnterNowClick={onEnterNowClick}
          widgets={{ timelineWidget, footerWidget }}
        />
      )}
    </>
  );
}

export default HomePage;
