import { useEffect, useState } from 'react';
import WidgetService from '../services/widget';

function useWidget({ type }) {
  const [isWidgetLoading, setIsWidgetLoading] = useState(false);
  const [widget, setWidget] = useState(null);

  useEffect(() => {
    setIsWidgetLoading(true);
    WidgetService.getWidgetByType(type).then((response) => {
      setWidget(response.data);
      setIsWidgetLoading(false);
    });
  }, [type]);

  return { isWidgetLoading, widget }
}

export default useWidget;
