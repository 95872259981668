import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

function headerAnimation() {
  const timeline = document.querySelector('.timeline-block');
  const timelineHeader = document.querySelector('.timeline-block-header');
  const timelinePoints = gsap.utils.toArray('.timeline-point');

  const mql1080 = matchMedia('(max-width: 1080px)');

  let timelinePointsTopTrigger = '55%';

  if (mql1080.matches) {
    timelinePointsTopTrigger = '46%';
  }

  ScrollTrigger.create({
    trigger: timeline,
    endTrigger: timelinePoints[timelinePoints.length - 1],
    start: 'top top',
    end: `-60px ${timelinePointsTopTrigger}`,
    pin: timelineHeader,
    pinSpacing: false,
  });
}

function badgeAnimation({ numberOfSteps, images, scroller }) {
  const badge = document.getElementById('sticky-point-id');
  const badgeImage = badge.querySelector('.rotation-box__image');
  const timelinePoints = gsap.utils.toArray('.timeline-point');
  const timelineProgress = document.querySelector('.svg-timeline #timeline-progress');
  const verticalTimeline = document.querySelector('.vertical-timeline');
  const verticalTimelineTop = verticalTimeline.getBoundingClientRect().top;
  const timeline = document.querySelector('.timeline-block');
  const header = document.querySelector('.timeline-block-header');
  const headerHeight = header.getBoundingClientRect().height;

  const startPointTop = timelinePoints[0].getBoundingClientRect().top;
  const startPointHeight = timelinePoints[0].getBoundingClientRect().height;
  const endPointTop = timelinePoints[timelinePoints.length - 1].getBoundingClientRect().top;
  const endPointHeight = timelinePoints[timelinePoints.length - 1].getBoundingClientRect().height;

  const pathHeight = endPointTop + endPointHeight / 2 - (startPointTop + startPointHeight / 2);

  const mql1080 = matchMedia('(max-width: 1080px)');
  const mql575 = matchMedia('(max-width: 575px)');

  let offset = 80;
  let timelinePointsTopTrigger = '55%';
  let badgeStart = 20;
  let animationStart = 'top top';
  let endTrigger = timelinePoints[timelinePoints.length - 1];

  if (mql1080.matches) {
    offset = 90;
    badgeStart = 70;
    timelinePointsTopTrigger = '46%';
  }
  if (mql575.matches) {
    offset = 64;
    badgeStart = 46;
    timelinePointsTopTrigger = `${headerHeight + 20}px`;
    endTrigger = undefined;
  }
  let badgeEnd = endPointTop - verticalTimelineTop - offset;

  const badgeImages = Array.from({ length: numberOfSteps }).map((_, i) => {
    if (images[i]) {
      return `${process.env.REACT_APP_AWS_S3_URL}${images[i]}`;
    } else if (images[images.length - 1]) {
      return `${process.env.REACT_APP_AWS_S3_URL}${images[images.length - 1]}`;
    } else {
      return '';
    }
  });

  changeBadgeImage(0);

  ScrollTrigger.create({
    scroller,
    trigger: timeline,
    endTrigger: timelinePoints[timelinePoints.length - 1],
    start: animationStart,
    end: `-60px ${timelinePointsTopTrigger}`,
    onEnter: () => {
      const badgeTop = badge.getBoundingClientRect().top;
      badge.style.position = 'fixed';
      badge.style.top = `${badgeTop}px`;
    },
    onEnterBack: () => {
      const badgeTop = badge.getBoundingClientRect().top;
      badge.style.position = 'fixed';
      badge.style.top = `${badgeTop}px`;
    },
    onLeave: () => {
      badge.style.position = 'absolute';
      badge.style.top = `${badgeEnd}px`;
    },
    onLeaveBack: () => {
      badge.style.position = 'absolute';
      badge.style.top = `-${badgeStart}px`;
    },
    onUpdate: (self) => {
      timelineProgress.setAttribute('y2', `${self.progress * pathHeight}`);
    },
  });

  timelinePoints.slice(1).forEach((point, index) => {
    const parent = point.parentElement;
    const textElements = parent.querySelectorAll('.timeline-text');
    ScrollTrigger.create({
      scroller,
      trigger: point,
      start: `50% ${timelinePointsTopTrigger}%`,
      onEnter: () => {
        // point.classList.remove('empty');
        point.classList.add('checked');
        // point.innerHTML = `
        // <svg width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        //   <path
        //     d="M11.0638 2.79006C12.7842 3.04233 14.4139 3.25927 16.0284 3.52163C16.5934 3.61245 17.0374 3.55695 17.5066 3.16341C18.7881 2.09381 20.1201 1.08474 21.4975 0C22.8194 1.03934 24.1715 2.05344 25.4631 3.13819C25.9575 3.5519 26.4217 3.62254 27.0171 3.52668C28.6316 3.26432 30.2561 3.04233 31.9362 2.79511C32.582 4.4197 33.1875 6.02411 33.8736 7.5932C34.03 7.95647 34.4084 8.31973 34.7717 8.48118C36.3408 9.16734 37.9452 9.78287 39.5748 10.4337C39.3377 12.0785 39.146 13.6779 38.8635 15.2621C38.7373 15.9634 38.8635 16.478 39.3276 17.0229C40.367 18.259 41.3357 19.5456 42.38 20.8674C41.3256 22.2044 40.3064 23.5566 39.2267 24.8482C38.8382 25.3124 38.7525 25.7563 38.8433 26.3214C39.1056 27.941 39.3276 29.5656 39.5849 31.2911C38.48 31.7401 37.4003 32.3153 36.255 32.618C34.6052 33.0519 33.6769 34.0054 33.2379 35.6401C32.9403 36.7501 32.4004 37.7894 31.9362 38.9398C30.3217 38.7026 28.7022 38.4958 27.0927 38.2233C26.4368 38.1123 25.9273 38.2031 25.3975 38.6572C24.1463 39.7167 22.8345 40.7006 21.5076 41.745C20.1756 40.6955 18.8487 39.6915 17.5672 38.6269C17.0828 38.2233 16.6338 38.1174 16.0233 38.2183C14.4088 38.4907 12.7792 38.7026 11.0688 38.9498C10.4482 37.406 9.80243 35.8773 9.23231 34.3233C9.01032 33.7179 8.67733 33.3647 8.0618 33.1376C6.52298 32.5726 5.01443 31.9369 3.42515 31.2961C3.66228 29.6665 3.86409 28.0419 4.14159 26.4375C4.25258 25.7765 4.15168 25.272 3.70264 24.7422C2.64817 23.491 1.66434 22.1792 0.625 20.8573C1.69965 19.4951 2.7289 18.1531 3.80355 16.8463C4.16681 16.4074 4.25258 15.9785 4.16177 15.4387C3.89437 13.7939 3.66733 12.1441 3.41506 10.4337C4.95388 9.81314 6.45739 9.16734 7.99116 8.61236C8.63192 8.38027 9.00023 8.04224 9.23736 7.39644C9.79234 5.85761 10.4381 4.35411 11.0688 2.77492L11.0638 2.79006Z"
        //     fill="#DEE965"
        //   />
        //   <path
        //     d="M20.9813 21.0538C22.5705 19.4595 24.0034 18.0115 25.4514 16.5736C26.6774 15.3526 28.2415 15.2769 29.3413 16.3617C30.411 17.4212 30.3454 18.9499 29.1395 20.1759C27.1466 22.194 25.1436 24.2021 23.1255 26.1899C21.8188 27.4765 20.4313 27.4563 19.1145 26.1597C18.0701 25.1355 17.0308 24.1012 16.0116 23.0568C14.8663 21.8812 14.8209 20.3323 15.8754 19.3132C16.8996 18.3243 18.4233 18.3848 19.5534 19.4898C20.0327 19.959 20.4565 20.4786 20.9813 21.0538Z"
        //     fill="#1C4844"
        //   />
        // </svg>
        // `;
        textElements.forEach((element) => element.classList.add('active'));
        changeBadgeImage(index + 1);
      },
      onLeaveBack: () => {
        point.classList.remove('checked');
        // point.classList.add('empty');
        // point.innerHTML = '';
        textElements.forEach((element) => element.classList.remove('active'));
        changeBadgeImage(index);
      },
    });
  });

  function changeBadgeImage(pointIndex) {
    if (!images.length) return;
    badgeImage.innerHTML = `<img src="${badgeImages[pointIndex]}"/>`;
  }
}

function startHeaderAnimation() {
  setTimeout(headerAnimation, 2000)
}

function startBadgeAnimation(params) {
  setTimeout(() => badgeAnimation(params), 2000)
}

export { startHeaderAnimation, startBadgeAnimation };
