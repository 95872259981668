import React, { useState, useEffect } from 'react';
import './styles.css';
import { dateDiff } from '../../utils/index';

const MobileHeaderCountdown = (props) => {
  const { endsAt } = props;
  const { prizeType } = props;
  const [state, setState] = useState({
    Days: '--',
    Hours: '--',
    Minutes: '--',
    Seconds: '--',
  });

  function getTime() {
    const endRaffleDate = new Date(endsAt);
    const now = Date.now();
    const date = dateDiff(now, endRaffleDate, prizeType);
    return date;
  }
  let interval;
  useEffect(() => {
    const now = new Date().getTime() / 1000;
    const endsAtUnix = new Date(endsAt).getTime() / 1000;

    if (endsAt && endsAtUnix > now) {
      interval = setInterval(() => {
        const date = getTime();
        setState(date);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  });

  const renderTimeString = () => {
    const params = {
      Days: 'days',
      Hours: 'hrs',
      Minutes: 'min',
      Seconds: 'sec',
    };
    let value = ' ';
    {
      Object.keys(state).map((k, i) => {
        if (k === 'Mths' && state['Mths'] === '00') {
          return null;
        }
        // value += `${state[k].substring(0, 1)}${state[k].substring(1, state[k].length)}${k[0]} `;
        value += `${state[k].substring(0, 1)}${state[k].substring(
          1,
          state[k].length
        )}${params[k]} `;
      });
    }
    // return ` ${value} (end time is 00:00:00 on feb 27)`;
    return ` ${value}`;
  };

  // return (
  //   <div className="infoModalTimes">
  //     <div className="modalTimer">
  //       <span>{renderTimeString()}</span>
  //     </div>
  //   </div>
  // );
  return (
    <div className='mobile-header-countdown'>
      {/* <p>£2 MILLION DRAW ENDS IN: 00 DAYS 21 HRS 58MIN 14SEC</p> */}
      <p>
        {props.text}
        {props.text ? <br /> : null}
        <span>{renderTimeString()}</span>
      </p>
    </div>
  );
};

export default MobileHeaderCountdown;
