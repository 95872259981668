import React from 'react';
import useStackAdaptPixel from '../hooks/useStackAdaptPixel';
import AppRoutes from '../routes';
import { useTwitterHeadScript } from '../hooks/useTwitterHeadScript';
import { useLocation } from 'react-router-dom';
import tracking from '../helpers/tracking';

function App() {
  useStackAdaptPixel();
  const location = useLocation();
  useTwitterHeadScript();
  
  React.useEffect(() => {
    tracking.twitterPageViewTrack(location.pathname);
  }, [location]);

  return <AppRoutes />;
}

export default App;
