import React from 'react';
import './Loader.css';

const Loader = (props) => {
  return (
    <div className='loader-components'>
      <div className='spinner'></div> <p className='text'>{props?.text}</p>
    </div>
  );
};

export default Loader;
