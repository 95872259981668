import React, { useRef } from "react";
import Header from "../Header/Header";
import SectionWrapper from "../common/SectionWrapper/SectionWrapper";
import TicketList from "../TicketList/TicketList";
import TimelineBlock from "../TimelineBlock/TimelineBlock";
import Footer from "../Footer/Footer";
import Hero from "../Hero/Hero";
import DreamDraw from "../DreamDraw/DreamDraw";
import CharityBlock from "../CharityBlock/CharityBlock";
import MobileCTAButtonBottom from "../mobile-cta-button-bottom";
import "./styles.css";

const Main = (props) => {
  const {
    prize,
    prizeId,
    raffles,
    raffle,
    isExpired,
    activeRaffles,
    subscriptionModels,
    data,
    onCTAClick,
    onLearnMoreClick,
    onLearnMoreCharityClick,
    onEnterNowClick,
    widgets,
  } = props;
  const ticketSelectorRef = useRef(null);

  const handleScroll = () => {
    if (ticketSelectorRef.current) {
      ticketSelectorRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    }
  };
  return (
    <>
      {prizeId ? (
        <div className="app">
          <Header raffle={raffle} />
          <main>
            <Hero />
            {/* <SectionWrapper bg="bg-1">
              <DreamDraw />
            </SectionWrapper>
            <SectionWrapper bg="bg-1">
              <div className="dream-draw__image">
                <picture>
                  <source
                    srcSet={`${process.env.REACT_APP_AWS_S3_URL}dream-home-draw/dhd-01.11.2024-2x.jpg 1.5x, ${process.env.REACT_APP_AWS_S3_URL}dream-home-draw/dhd-01.11.2024.jpg`}
                    media="(min-width: 576px)"
                  />
                  <source
                    srcSet={`${process.env.REACT_APP_AWS_S3_URL}dream-home-draw/dhd-01.11.2024-sm-2x.jpg 1.5x, ${process.env.REACT_APP_AWS_S3_URL}dream-home-draw/dhd-01.11.2024-sm.jpg`}
                  />
                  <img
                    src={`${process.env.REACT_APP_AWS_S3_URL}dream-home-draw/dhd-01.11.2024.jpg`}
                    width={960}
                    height={540}
                    alt="dream draw"
                  />
                </picture>
                <div className="dream-draw-img__badge">
                  <svg
                    width="875"
                    height="876"
                    viewBox="0 0 875 876"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M875.003 437.697C875.003 679.323 679.127 875.199 437.502 875.199C195.876 875.199 0 679.323 0 437.697C0 196.072 195.876 0.195312 437.502 0.195312C679.127 0.195312 875.003 196.072 875.003 437.697Z"
                      fill="#043B3B"
                    />
                    <circle
                      cx="436.219"
                      cy="436.384"
                      r="338.774"
                      stroke="white"
                    />
                    <path
                      d="M247.755 468.783C257.621 468.783 261.144 465.666 262.632 457.112L263.807 450.157H247.755V425.534H267.956L269.288 417.859C273.985 389.959 293.56 373.97 333.023 373.97C338.191 373.97 343.672 374.21 349.466 374.77V403.23C346.021 402.83 341.714 402.59 338.504 402.59C317.207 402.59 310.473 409.545 307.811 423.375L307.419 425.534H329.187V450.157H302.643C301.312 456.632 299.668 460.709 295.048 464.706H358.862V492.687H247.755V468.783Z"
                      fill="white"
                    />
                    <path
                      d="M368.646 492.687V468.304L402.941 439.364C414.451 429.691 424.63 420.977 424.63 409.945C424.63 404.589 422.203 400.352 416.722 400.352C411.006 400.352 407.639 404.429 407.639 413.542C407.639 414.822 407.639 416.181 407.796 417.699H365.358C366.454 388.6 387.36 375.17 417.818 375.17C444.597 375.17 465.972 386.761 465.972 411.464C465.972 432.089 450.469 444.001 431.834 455.913L417.427 465.106H466.442V492.687H368.646Z"
                      fill="white"
                    />
                    <path
                      d="M475.13 512.353L481.551 464.546H517.334L505.432 512.353H475.13Z"
                      fill="white"
                    />
                    <path
                      d="M578.091 495.644C543.717 495.644 524.378 474.619 524.378 435.607C524.378 395.075 544.109 375.17 578.482 375.17C606.905 375.17 624.836 389.719 625.854 410.824H586.939C586.86 403.949 583.337 399.952 577.465 399.952C569.008 399.952 564.545 406.747 564.545 423.695C564.545 426.014 564.623 428.572 564.937 432.329C569.635 423.535 580.362 418.019 593.516 418.019C613.012 418.019 628.124 430.011 628.124 454.074C628.124 482.694 604.869 495.644 578.091 495.644ZM567.207 455.992C567.207 465.586 570.966 470.702 576.995 470.702C583.102 470.702 586.469 465.586 586.469 455.992C586.469 446.319 582.789 441.523 576.682 441.523C570.574 441.523 567.207 446.319 567.207 455.992Z"
                      fill="white"
                    />
                    <path
                      d="M186 608.038V529.875H223.957L235.435 575.824L247.02 529.875H283.856V608.038H257.804V560.671L245.952 608.038H224.064L212.052 561.434V608.038H186Z"
                      fill="white"
                    />
                    <path
                      d="M295.065 608.038V529.875H323.36V608.038H295.065Z"
                      fill="white"
                    />
                    <path
                      d="M334.515 608.038V529.875H362.81V586.78H395.375V608.038H334.515Z"
                      fill="white"
                    />
                    <path
                      d="M403.648 608.038V529.875H431.943V586.78H464.508V608.038H403.648Z"
                      fill="white"
                    />
                    <path
                      d="M472.781 608.038V529.875H501.075V608.038H472.781Z"
                      fill="white"
                    />
                    <path
                      d="M553.285 610C528.407 610 509.882 597.354 509.882 568.847C509.882 539.959 529.261 527.913 553.285 527.913C577.308 527.913 596.687 539.632 596.687 568.847C596.687 597.354 578.162 610 553.285 610ZM540.525 568.847C540.525 581.384 543.141 592.013 553.285 592.013C563.428 592.013 566.044 581.384 566.044 568.847C566.044 556.42 563.428 545.9 553.285 545.9C543.141 545.9 540.525 556.42 540.525 568.847Z"
                      fill="white"
                    />
                    <path
                      d="M605.494 608.038V529.875H640.675L659.521 571.736V529.875H686V608.038H652.741L631.973 562.197V608.038H605.494Z"
                      fill="white"
                    />
                    <path
                      d="M394.76 342.087C369.883 342.087 351.358 329.442 351.358 300.935C351.358 272.046 370.737 260 394.76 260C418.784 260 438.163 271.719 438.163 300.935C438.163 329.442 419.638 342.087 394.76 342.087ZM382.001 300.935C382.001 313.471 384.617 324.1 394.76 324.1C404.904 324.1 407.52 313.471 407.52 300.935C407.52 288.507 404.904 277.987 394.76 277.987C384.617 277.987 382.001 288.507 382.001 300.935Z"
                      fill="white"
                    />
                    <path
                      d="M446.97 340.125V261.962H486.636C509.004 261.962 519.308 268.776 519.308 283.056C519.308 295.484 511.834 301.262 503.078 303.66C514.076 305.786 519.521 309.383 519.521 320.939V340.125H491.12V320.121C491.12 314.289 487.49 311.618 480.71 311.618H475.264V340.125H446.97ZM475.264 295.484H480.977C487.169 295.484 491.44 293.304 491.44 286.817C491.44 280.985 487.436 278.859 480.977 278.859H475.264V295.484Z"
                      fill="white"
                    />
                    <path
                      d="M83.5544 412.327L32.021 408.688L30.5543 429.532L24.7832 429.124L28.1922 380.68L33.9633 381.087L32.4966 401.93L84.03 405.569L83.5544 412.327Z"
                      fill="white"
                    />
                    <path
                      d="M90.297 365.833C89.7278 368.493 88.7109 370.779 87.2463 372.69C85.74 374.537 83.953 375.879 81.8851 376.715C79.8173 377.551 77.668 377.729 75.4371 377.25C72.5688 376.635 70.465 375.432 69.1257 373.642C67.7979 371.798 67.1473 369.127 67.174 365.626C67.212 362.073 67.8572 357.37 69.1095 351.517L69.8779 347.926L74.1007 348.832L73.3493 352.344C72.6549 355.59 72.1805 358.326 71.9263 360.553C71.672 362.78 71.6738 364.589 71.9317 365.98C72.1897 367.371 72.6867 368.424 73.4227 369.138C74.1587 369.853 75.1641 370.347 76.439 370.621C78.6167 371.088 80.5555 370.725 82.2553 369.532C83.9665 368.285 85.101 366.359 85.6588 363.752C86.1142 361.623 86.0081 359.653 85.3404 357.84C84.6841 355.974 83.5762 354.4 82.0169 353.12C80.469 351.786 78.553 350.873 76.269 350.383L67.186 348.433C63.8927 347.726 61.3867 347.884 59.668 348.906C57.8961 349.917 56.6857 351.939 56.0368 354.971C55.5359 357.312 55.3973 359.648 55.6211 361.978C55.7917 364.296 56.3759 366.759 57.3737 369.366L52.1947 370.591C51.5346 369.003 51.0471 367.256 50.7323 365.352C50.3643 363.437 50.1994 361.481 50.2374 359.486C50.2754 357.491 50.4822 355.615 50.8579 353.86C51.5751 350.508 52.7248 347.861 54.307 345.919C55.8361 343.965 57.83 342.696 60.2886 342.111C62.694 341.514 65.6231 341.587 69.0757 342.328L93.4563 347.562L92.1585 353.627L83.1552 351.695L83.3089 350.976C85.2363 351.835 86.7861 353.031 87.9584 354.562C89.1421 356.041 89.9445 357.743 90.3656 359.67C90.7866 361.597 90.7638 363.651 90.297 365.833Z"
                      fill="white"
                    />
                    <path
                      d="M96.8492 333.697L82.127 309.365L85.3415 310.542L59.9879 319.164L62.6723 311.805L82.0842 305.439L81.3012 307.586L70.5856 290.108L73.2421 282.825L86.9501 305.656L84.1182 304.62L110.774 295.518L108.09 302.878L87.2431 309.847L88.054 307.624L99.5336 326.337L96.8492 333.697Z"
                      fill="white"
                    />
                    <path
                      d="M98.6965 276.684L93.8435 274.179L104.295 253.863L109.148 256.368L98.6965 276.684Z"
                      fill="white"
                    />
                    <path
                      d="M136.42 247.395L107.61 228.442L103.315 234.991L99.0245 232.168L104.303 224.118L104.749 226.56L102.161 224.857C97.7565 221.959 95.1349 218.672 94.296 214.996C93.487 211.273 94.4522 207.026 97.1917 202.253L98.8 199.503L103.336 201.803L101.483 205.076C100.396 207.03 99.8058 208.823 99.7115 210.453C99.6172 212.084 100.027 213.59 100.94 214.972C101.854 216.355 103.241 217.658 105.103 218.883L107.964 220.765L106.405 220.911L113.204 210.541L117.495 213.364L111.233 222.915L140.043 241.869L136.42 247.395Z"
                      fill="white"
                    />
                    <path
                      d="M154.107 221.572L123.254 196.704L127.289 191.681L134.59 197.565L134.079 198.201C132.161 195.468 131.282 192.699 131.443 189.895C131.561 187.057 132.689 184.09 134.827 180.994L136.118 178.997L140.869 182.197L138.645 185.747C136.583 188.835 135.726 191.811 136.074 194.676C136.414 197.464 137.96 199.967 140.711 202.184L158.296 216.358L154.107 221.572Z"
                      fill="white"
                    />
                    <path
                      d="M189.834 182.333C186.841 185.26 183.687 187.203 180.371 188.164C177.057 189.047 173.735 188.987 170.405 187.985C167.114 186.945 163.988 184.906 161.029 181.869C158.145 178.909 156.224 175.769 155.265 172.448C154.268 169.088 154.174 165.797 154.981 162.575C155.827 159.314 157.591 156.373 160.274 153.75C162.839 151.242 165.586 149.659 168.513 149.002C171.441 148.344 174.396 148.573 177.379 149.686C180.401 150.762 183.316 152.74 186.124 155.622L187.888 157.433L165.555 179.268L162.538 176.173L181.723 157.416L181.529 159.087C178.494 155.972 175.442 154.242 172.373 153.897C169.304 153.475 166.449 154.556 163.805 157.141C161.823 159.079 160.587 161.2 160.098 163.502C159.61 165.728 159.813 168.001 160.707 170.321C161.563 172.601 163.035 174.813 165.122 176.954L165.463 177.305C167.778 179.68 170.101 181.324 172.433 182.238C174.804 183.113 177.165 183.238 179.516 182.612C181.829 181.948 184.035 180.589 186.135 178.537C187.806 176.902 189.173 175.072 190.235 173.045C191.297 170.942 191.94 168.602 192.163 166.027L197.04 167.76C196.896 170.183 196.174 172.713 194.874 175.353C193.575 177.992 191.895 180.319 189.834 182.333Z"
                      fill="white"
                    />
                    <path
                      d="M228.084 149.895C224.708 152.369 221.311 153.847 217.894 154.328C214.488 154.734 211.207 154.205 208.052 152.742C204.941 151.247 202.134 148.786 199.632 145.361C197.194 142.024 195.735 138.643 195.254 135.22C194.74 131.753 195.111 128.481 196.364 125.405C197.661 122.297 199.822 119.634 202.847 117.417C205.741 115.297 208.683 114.118 211.674 113.882C214.665 113.645 217.558 114.289 220.354 115.813C223.194 117.305 225.801 119.676 228.174 122.926L229.666 124.968L204.479 143.427L201.929 139.936L223.565 124.079L223.138 125.707C220.572 122.194 217.794 120.049 214.804 119.274C211.827 118.422 208.847 119.089 205.866 121.273C203.63 122.912 202.108 124.837 201.299 127.047C200.502 129.182 200.383 131.461 200.941 133.884C201.467 136.262 202.612 138.66 204.376 141.075L204.665 141.47C206.622 144.149 208.69 146.105 210.87 147.339C213.094 148.541 215.414 148.999 217.83 148.712C220.213 148.381 222.589 147.348 224.956 145.613C226.841 144.231 228.452 142.612 229.789 140.756C231.137 138.824 232.104 136.599 232.687 134.081L237.271 136.486C236.787 138.864 235.715 141.267 234.057 143.697C232.398 146.126 230.407 148.192 228.084 149.895Z"
                      fill="white"
                    />
                    <path
                      d="M293.601 111.95C289.982 113.576 286.504 114.184 283.166 113.776C279.827 113.368 276.806 112.072 274.101 109.888C271.424 107.632 269.24 104.617 267.55 100.844C265.838 97.021 265.053 93.4077 265.196 90.0038C265.388 86.5776 266.464 83.5296 268.424 80.8598C270.384 78.19 273.173 76.0421 276.791 74.4163C279.022 73.4141 281.405 72.8202 283.942 72.6346C286.479 72.449 288.788 72.7531 290.87 73.547L290.756 78.8762C288.696 78.132 286.654 77.8269 284.63 77.961C282.656 78.0728 280.901 78.4739 279.364 79.1644C275.598 80.857 273.232 83.3515 272.267 86.6477C271.28 89.8943 271.775 93.7269 273.754 98.1455C275.689 102.465 278.239 105.434 281.405 107.053C284.549 108.622 288.004 108.56 291.771 106.868C293.308 106.177 294.774 105.131 296.17 103.728C297.616 102.303 298.735 100.548 299.528 98.4622L303.577 101.921C302.784 104.006 301.462 105.972 299.61 107.818C297.785 109.593 295.782 110.97 293.601 111.95Z"
                      fill="white"
                    />
                    <path
                      d="M330.695 97.9413C328.09 98.7134 325.6 98.9125 323.223 98.5388C320.884 98.0974 318.853 97.1673 317.13 95.7484C315.408 94.3295 314.223 92.5243 313.576 90.3328C312.744 87.5152 312.792 85.0892 313.72 83.0548C314.701 81.005 316.732 79.1545 319.813 77.5034C322.947 75.8369 327.378 74.1544 333.108 72.4559L336.624 71.4137L337.85 75.5618L334.412 76.5809C331.234 77.5228 328.608 78.4146 326.534 79.2564C324.46 80.0982 322.875 80.9652 321.779 81.8576C320.683 82.75 319.998 83.6908 319.724 84.68C319.45 85.6693 319.497 86.79 319.867 88.0423C320.499 90.1816 321.745 91.7133 323.604 92.6376C325.516 93.5464 327.748 93.6226 330.3 92.866C332.384 92.2483 334.06 91.2122 335.33 89.7576C336.652 88.2876 337.502 86.5604 337.878 84.576C338.307 82.5763 338.19 80.4545 337.528 78.2109L334.892 69.2885C333.936 66.0535 332.599 63.9246 330.88 62.9018C329.146 61.8269 326.795 61.7295 323.826 62.6096C321.534 63.289 319.42 64.2844 317.485 65.5958C315.534 66.855 313.655 68.547 311.846 70.6719L308.294 66.7027C309.371 65.3621 310.669 64.0978 312.188 62.91C313.691 61.6699 315.326 60.5894 317.093 59.6683C318.861 58.7472 320.604 58.0318 322.323 57.5223C325.605 56.5495 328.475 56.2945 330.935 56.7571C333.379 57.1676 335.445 58.3142 337.134 60.1968C338.808 62.0273 340.146 64.6383 341.148 68.0298L348.223 91.9794L342.285 93.7396L339.672 84.8955L340.376 84.687C340.545 86.7933 340.238 88.7284 339.456 90.4924C338.727 92.2408 337.618 93.7612 336.13 95.0534C334.642 96.3456 332.831 97.3083 330.695 97.9413Z"
                      fill="white"
                    />
                    <path
                      d="M380.142 86.3591C378.102 86.6701 376.082 86.7577 374.083 86.6219C372.146 86.5318 370.292 86.2639 368.521 85.8183C366.796 85.3106 365.265 84.6358 363.928 83.7937L365.324 78.8751C366.768 79.7008 368.295 80.3487 369.905 80.8189C371.507 81.2353 373.111 81.4862 374.717 81.5717C376.324 81.6571 377.933 81.5771 379.544 81.3315C382.605 80.8651 384.828 79.9483 386.214 78.5813C387.6 77.2142 388.146 75.5624 387.852 73.6258C387.623 72.1195 386.933 71.0138 385.782 70.3085C384.623 69.5495 382.905 69.0957 380.63 68.947L373.054 68.4503C369.565 68.2665 366.857 67.4408 364.93 65.973C363.058 64.4971 361.913 62.3873 361.496 59.6438C361.129 57.223 361.432 55.0577 362.406 53.148C363.425 51.1764 365.016 49.5304 367.178 48.2102C369.34 46.89 371.952 45.9967 375.013 45.5302C376.839 45.2519 378.613 45.1742 380.335 45.2971C382.057 45.42 383.7 45.7475 385.265 46.2797C386.875 46.7499 388.299 47.4411 389.536 48.3533L388.06 53.2842C386.892 52.4714 385.615 51.8404 384.228 51.3913C382.833 50.8884 381.413 50.5819 379.967 50.4719C378.567 50.3 377.169 50.3204 375.773 50.5331C372.765 50.9914 370.573 51.931 369.195 53.3518C367.817 54.7727 367.276 56.4514 367.57 58.388C367.798 59.8943 368.47 61.0579 369.584 61.8789C370.697 62.7 372.307 63.1701 374.413 63.2895L381.977 63.7056C385.582 63.9268 388.371 64.7403 390.343 66.146C392.36 67.4898 393.581 69.5604 394.006 72.3578C394.365 74.7248 394.035 76.8941 393.016 78.8658C391.989 80.7836 390.367 82.4067 388.151 83.7352C385.981 85.0016 383.311 85.8762 380.142 86.3591Z"
                      fill="white"
                    />
                    <path
                      d="M412.716 81.4591L412.094 23.9193L418.694 23.8477L418.983 50.5363L417.923 50.5478C419.03 47.3796 420.851 44.9927 423.387 43.387C425.977 41.7264 428.956 40.8778 432.323 40.8413C435.474 40.8071 438.088 41.3501 440.164 42.4703C442.295 43.59 443.889 45.314 444.946 47.6425C446.003 49.9166 446.551 52.822 446.589 56.3587L446.856 81.0886L440.257 81.1602L439.993 56.8384C439.967 54.3899 439.619 52.3802 438.95 50.8094C438.336 49.2379 437.345 48.0787 435.979 47.3317C434.667 46.5841 432.952 46.2218 430.834 46.2448C428.444 46.2707 426.358 46.8103 424.576 47.8636C422.795 48.9168 421.425 50.3738 420.468 52.2344C419.51 54.0949 419.045 56.2767 419.072 58.7796L419.316 81.3874L412.716 81.4591Z"
                      fill="white"
                    />
                    <path
                      d="M504.904 88.6782C502.249 88.1082 499.966 87.0897 498.058 85.6228C496.214 84.1141 494.875 82.3241 494.04 80.2528C493.205 78.1816 493.027 76.0287 493.505 73.794C494.119 70.9208 495.32 68.8134 497.107 67.4718C498.947 66.1416 501.614 65.4897 505.109 65.5163C508.656 65.5542 513.351 66.2001 519.194 67.4541L522.78 68.2236L521.875 72.4535L518.369 71.7011C515.129 71.0057 512.397 70.5308 510.174 70.2762C507.951 70.0216 506.145 70.0236 504.756 70.282C503.367 70.5405 502.316 71.0384 501.603 71.7757C500.89 72.513 500.397 73.5202 500.123 74.7971C499.657 76.9786 500.019 78.9206 501.211 80.6232C502.455 82.3372 504.379 83.4735 506.982 84.032C509.106 84.488 511.073 84.3816 512.883 83.7126C514.746 83.0551 516.317 81.9453 517.595 80.3833C518.927 78.8327 519.838 76.9134 520.327 74.6255L522.273 65.5272C522.979 62.2283 522.821 59.7181 521.8 57.9966C520.791 56.2218 518.773 55.0095 515.745 54.3597C513.408 53.8581 511.076 53.7194 508.751 53.9437C506.436 54.1148 503.978 54.7002 501.375 55.6998L500.151 50.5122C501.737 49.8508 503.48 49.3624 505.381 49.0469C507.294 48.6782 509.246 48.5129 511.237 48.5508C513.229 48.5888 515.102 48.7958 516.855 49.172C520.201 49.8902 522.844 51.0417 524.783 52.6264C526.733 54.158 528 56.1551 528.584 58.6177C529.18 61.0272 529.108 63.9612 528.368 67.4196L523.145 91.8415L517.089 90.542L519.018 81.5234L519.735 81.6773C518.878 83.608 517.685 85.1605 516.156 86.3349C514.68 87.5207 512.98 88.3245 511.056 88.7464C509.133 89.1683 507.082 89.1456 504.904 88.6782Z"
                      fill="white"
                    />
                    <path
                      d="M552.573 101.728C548.764 100.442 546.262 98.3349 545.069 95.4057C543.928 92.494 544.068 88.9239 545.491 84.6955L559.645 42.6178L565.9 44.7289L551.902 86.3425C551.294 88.1473 551.014 89.7467 551.061 91.1406C551.16 92.5519 551.594 93.732 552.364 94.681C553.134 95.6299 554.24 96.3476 555.681 96.8341C556.299 97.0426 556.9 97.2166 557.483 97.3562C558.136 97.4616 558.745 97.5238 559.312 97.5428L557.388 102.75C556.513 102.627 555.671 102.487 554.865 102.329C554.109 102.189 553.345 101.989 552.573 101.728Z"
                      fill="white"
                    />
                    <path
                      d="M583.228 114.13C578.954 112.241 576.253 109.679 575.125 106.444C573.996 103.21 574.364 99.4765 576.228 95.2449L584.616 76.2027L577.534 73.0725L579.606 68.368L586.688 71.4981L591.589 60.3715L597.628 63.0404L592.727 74.167L604.208 79.2412L602.135 83.9457L590.654 78.8716L582.53 97.3164C581.28 100.154 580.922 102.583 581.457 104.604C582.014 106.575 583.634 108.153 586.318 109.34C587.113 109.691 587.952 109.943 588.835 110.095C589.718 110.248 590.527 110.367 591.26 110.453L590.265 115.545C589.465 115.607 588.378 115.514 587.004 115.263C585.63 115.013 584.372 114.635 583.228 114.13Z"
                      fill="white"
                    />
                    <path
                      d="M615.625 131.232C612.029 129.093 609.339 126.543 607.556 123.584C605.847 120.605 605.045 117.375 605.151 113.894C605.303 110.44 606.461 106.889 608.625 103.24C610.733 99.684 613.264 97.013 616.219 95.2266C619.201 93.3935 622.351 92.4508 625.667 92.3986C629.03 92.3742 632.323 93.3206 635.546 95.2379C638.629 97.0718 640.865 99.3201 642.256 101.983C643.647 104.646 644.191 107.564 643.888 110.738C643.632 113.94 642.478 117.272 640.425 120.735L639.135 122.91L612.302 106.947L614.508 103.227L637.557 116.94L635.896 117.185C638.115 113.443 638.994 110.042 638.533 106.983C638.147 103.905 636.365 101.421 633.189 99.5319C630.807 98.1148 628.443 97.4675 626.096 97.59C623.823 97.6935 621.684 98.4777 619.678 99.9425C617.7 101.36 615.948 103.356 614.422 105.929L614.172 106.35C612.48 109.204 611.495 111.878 611.218 114.37C610.987 116.891 611.477 119.208 612.688 121.321C613.927 123.387 615.808 125.17 618.33 126.671C620.338 127.866 622.457 128.715 624.686 129.218C626.989 129.702 629.411 129.719 631.953 129.269L631.543 134.437C629.169 134.923 626.542 134.879 623.66 134.304C620.779 133.729 618.101 132.705 615.625 131.232Z"
                      fill="white"
                    />
                    <path
                      d="M642.895 148.104L667.083 116.695L672.18 120.633L666.456 128.065L665.811 127.567C668.5 125.59 671.245 124.653 674.047 124.756C676.883 124.816 679.867 125.885 683.001 127.963L685.021 129.215L681.925 134.038L678.335 131.883C675.211 129.882 672.223 129.085 669.371 129.493C666.595 129.89 664.128 131.49 661.972 134.29L648.186 152.192L642.895 148.104Z"
                      fill="white"
                    />
                    <path
                      d="M671.08 170.338L698.99 142.195L703.557 146.74L697.354 152.994L696.719 152.361C699.748 150.927 702.724 150.552 705.646 151.235C708.644 151.917 711.338 153.447 713.728 155.825C715.963 158.049 717.411 160.295 718.071 162.563C718.809 164.831 718.72 167.159 717.806 169.548C716.892 171.936 715.191 174.385 712.703 176.895L695.302 194.44L690.62 189.781L707.733 172.525C709.456 170.788 710.641 169.129 711.289 167.549C711.975 166.008 712.104 164.488 711.677 162.989C711.288 161.528 710.342 160.05 708.839 158.554C707.143 156.867 705.296 155.757 703.297 155.225C701.297 154.693 699.3 154.738 697.305 155.36C695.309 155.982 693.431 157.182 691.67 158.957L675.763 174.997L671.08 170.338Z"
                      fill="white"
                    />
                    <path
                      d="M714.932 219.214C713.304 217.037 712.251 214.767 711.774 212.406C711.373 210.055 711.537 207.824 712.266 205.713C712.995 203.602 714.273 201.861 716.099 200.491C718.446 198.729 720.734 197.93 722.961 198.095C725.221 198.303 727.659 199.566 730.276 201.886C732.926 204.249 736.042 207.825 739.625 212.616L741.824 215.555L738.368 218.149L736.218 215.275C734.231 212.618 732.483 210.463 730.974 208.808C729.464 207.153 728.101 205.966 726.885 205.247C725.668 204.528 724.55 204.212 723.528 204.298C722.507 204.385 721.475 204.82 720.431 205.603C718.649 206.94 717.648 208.643 717.43 210.711C717.244 212.822 717.949 214.944 719.545 217.078C720.848 218.82 722.401 220.034 724.204 220.721C726.04 221.451 727.952 221.648 729.941 221.312C731.962 221.019 733.907 220.171 735.776 218.768L743.21 213.189C745.906 211.166 747.433 209.17 747.793 207.2C748.196 205.198 747.469 202.956 745.613 200.473C744.179 198.557 742.512 196.919 740.612 195.557C738.755 194.163 736.517 192.987 733.899 192.028L736.379 187.311C738.008 187.856 739.643 188.635 741.283 189.648C742.967 190.628 744.547 191.788 746.024 193.128C747.501 194.467 748.776 195.855 749.851 197.292C751.903 200.036 753.141 202.643 753.563 205.113C754.029 207.551 753.675 209.891 752.5 212.132C751.369 214.341 749.391 216.506 746.565 218.626L726.61 233.602L722.897 228.638L730.266 223.107L730.705 223.695C728.793 224.587 726.875 224.972 724.952 224.851C723.062 224.774 721.253 224.262 719.526 223.314C717.799 222.366 716.268 221 714.932 219.214Z"
                      fill="white"
                    />
                    <path
                      d="M744.133 263.573C741.799 259.519 741.131 255.854 742.129 252.576C743.127 249.299 745.625 246.505 749.626 244.195L767.626 233.797L763.759 227.08L768.206 224.511L772.073 231.229L782.591 225.154L785.888 230.881L775.37 236.956L781.639 247.845L777.192 250.414L770.923 239.525L753.487 249.596C750.805 251.145 749.084 252.893 748.323 254.84C747.61 256.76 747.986 258.992 749.451 261.538C749.885 262.292 750.413 262.992 751.036 263.637C751.658 264.283 752.24 264.857 752.781 265.362L748.974 268.879C748.292 268.457 747.471 267.738 746.51 266.722C745.549 265.707 744.757 264.657 744.133 263.573Z"
                      fill="white"
                    />
                    <path
                      d="M797.865 258.046L804.579 254.893L808.038 262.283L801.324 265.436L797.865 258.046ZM753.428 279.818L789.284 262.978L792.086 268.964L756.23 285.804L753.428 279.818Z"
                      fill="white"
                    />
                    <path
                      d="M767.827 313.75L799.636 284.607L801.992 291.391L773.863 316.227L773.14 314.146L810.664 316.369L812.886 322.768L769.861 319.609L767.827 313.75Z"
                      fill="white"
                    />
                    <path
                      d="M781.716 366.563C780.916 362.451 781.024 358.743 782.042 355.439C783.123 352.179 784.996 349.43 787.659 347.191C790.333 345.006 793.75 343.507 797.909 342.695C801.961 341.904 805.636 342.018 808.934 343.037C812.285 344.046 815.084 345.773 817.33 348.217C819.587 350.715 821.075 353.806 821.792 357.491C822.478 361.016 822.292 364.185 821.234 366.997C820.176 369.809 818.364 372.159 815.798 374.046C813.242 375.986 809.992 377.341 806.046 378.112L803.566 378.596L797.596 347.912L801.835 347.085L806.964 373.442L805.674 372.363C809.94 371.53 813.059 369.924 815.031 367.543C817.066 365.205 817.731 362.22 817.024 358.588C816.494 355.865 815.402 353.666 813.748 351.993C812.158 350.363 810.15 349.286 807.723 348.762C805.35 348.228 802.697 348.247 799.765 348.819L799.285 348.913C796.032 349.548 793.385 350.591 791.343 352.043C789.312 353.549 787.912 355.458 787.144 357.77C786.43 360.071 786.353 362.664 786.914 365.548C787.361 367.845 788.139 369.993 789.248 371.995C790.421 374.039 792.021 375.861 794.048 377.461L789.925 380.594C787.983 379.144 786.267 377.15 784.777 374.614C783.287 372.077 782.267 369.394 781.716 366.563Z"
                      fill="white"
                    />
                    <path
                      d="M806.823 410.91C806.744 409.509 807.001 408.203 807.591 406.994C808.211 405.783 809.071 404.788 810.173 404.009C811.275 403.23 812.54 402.801 813.967 402.72C815.28 402.647 816.526 402.906 817.705 403.5C818.913 404.12 819.908 404.997 820.687 406.129C821.495 407.26 821.94 408.568 822.024 410.055C822.101 411.428 821.844 412.719 821.253 413.928C820.663 415.166 819.804 416.175 818.673 416.956C817.571 417.735 816.293 418.165 814.837 418.247C813.438 418.326 812.148 418.04 810.966 417.389C809.785 416.767 808.82 415.889 808.069 414.755C807.318 413.621 806.903 412.339 806.823 410.91Z"
                      fill="white"
                    />
                    <path
                      d="M788.15 460.561L839.651 464.642L841.296 443.812L847.063 444.269L843.24 492.683L837.472 492.226L839.117 471.396L787.617 467.315L788.15 460.561Z"
                      fill="white"
                    />
                    <path
                      d="M781.004 507.015C781.596 504.36 782.633 502.083 784.114 500.185C785.636 498.351 787.435 497.025 789.51 496.207C791.585 495.389 793.736 495.23 795.963 495.728C798.826 496.369 800.919 497.59 802.242 499.392C803.554 501.247 804.181 503.924 804.124 507.424C804.055 510.977 803.369 515.674 802.065 521.516L801.266 525.1L797.051 524.157L797.833 520.652C798.556 517.412 799.054 514.68 799.328 512.456C799.601 510.231 799.615 508.422 799.37 507.029C799.124 505.636 798.636 504.578 797.906 503.858C797.176 503.137 796.175 502.634 794.903 502.349C792.729 501.862 790.787 502.208 789.077 503.387C787.355 504.618 786.204 506.535 785.623 509.137C785.15 511.261 785.239 513.232 785.89 515.051C786.53 516.923 787.624 518.506 789.172 519.8C790.709 521.148 792.617 522.077 794.896 522.587L803.962 524.617C807.249 525.352 809.756 525.217 811.484 524.209C813.264 523.214 814.492 521.203 815.168 518.176C815.689 515.84 815.848 513.505 815.645 511.174C815.494 508.854 814.932 506.386 813.957 503.771L819.146 502.591C819.792 504.185 820.264 505.935 820.563 507.842C820.914 509.761 821.062 511.718 821.006 513.712C820.951 515.707 820.728 517.581 820.337 519.333C819.59 522.679 818.418 525.316 816.818 527.244C815.272 529.184 813.268 530.435 810.804 530.999C808.393 531.575 805.465 531.477 802.019 530.705L777.685 525.258L779.036 519.204L788.022 521.215L787.862 521.932C785.942 521.056 784.403 519.848 783.244 518.306C782.073 516.817 781.286 515.107 780.881 513.177C780.477 511.247 780.518 509.193 781.004 507.015Z"
                      fill="white"
                    />
                    <path
                      d="M774.186 539.044L788.698 563.501L785.494 562.297L810.921 553.894L808.173 561.23L788.708 567.428L789.509 565.288L800.074 582.857L797.355 590.117L783.844 567.169L786.667 568.23L759.933 577.102L762.681 569.765L783.587 562.975L782.757 565.192L771.438 546.38L774.186 539.044Z"
                      fill="white"
                    />
                    <path
                      d="M771.84 596.058L776.671 598.604L766.045 618.829L761.214 616.282L771.84 596.058Z"
                      fill="white"
                    />
                    <path
                      d="M733.876 625.005L762.523 644.205L766.873 637.692L771.139 640.552L765.793 648.556L765.367 646.111L767.941 647.836C772.32 650.771 774.914 654.08 775.721 657.764C776.499 661.493 775.497 665.732 772.717 670.481L771.085 673.218L766.569 670.879L768.45 667.622C769.553 665.677 770.159 663.889 770.267 662.26C770.375 660.63 769.979 659.12 769.077 657.73C768.175 656.34 766.799 655.025 764.948 653.784L762.103 651.878L763.663 651.745L756.775 662.056L752.509 659.197L758.853 649.699L730.205 630.5L733.876 625.005Z"
                      fill="white"
                    />
                    <path
                      d="M715.961 650.686L746.601 675.817L742.523 680.806L735.272 674.86L735.789 674.228C737.683 676.978 738.538 679.754 738.354 682.556C738.211 685.393 737.058 688.35 734.893 691.428L733.585 693.414L728.862 690.173L731.117 686.642C733.205 683.573 734.087 680.604 733.763 677.736C733.447 674.945 731.923 672.429 729.191 670.188L711.727 655.865L715.961 650.686Z"
                      fill="white"
                    />
                    <path
                      d="M679.902 689.615C682.921 686.715 686.091 684.798 689.415 683.866C692.736 683.011 696.058 683.099 699.379 684.13C702.661 685.199 705.769 687.264 708.703 690.326C711.562 693.31 713.456 696.467 714.386 699.796C715.354 703.164 715.421 706.456 714.586 709.671C713.712 712.925 711.923 715.851 709.218 718.451C706.631 720.937 703.871 722.496 700.938 723.128C698.005 723.76 695.052 723.507 692.079 722.368C689.067 721.266 686.169 719.263 683.385 716.358L681.636 714.532L704.155 692.888L707.145 696.009L687.802 714.602L688.009 712.932C691.018 716.073 694.055 717.829 697.121 718.2C700.186 718.648 703.05 717.592 705.716 715.03C707.715 713.108 708.969 710.998 709.477 708.7C709.984 706.478 709.8 704.204 708.926 701.877C708.09 699.589 706.637 697.365 704.568 695.206L704.23 694.852C701.936 692.457 699.626 690.793 697.302 689.86C694.938 688.964 692.578 688.819 690.222 689.425C687.904 690.07 685.686 691.409 683.57 693.444C681.884 695.064 680.502 696.882 679.423 698.9C678.342 700.994 677.68 703.328 677.434 705.901L672.572 704.127C672.738 701.706 673.481 699.181 674.803 696.553C676.125 693.925 677.825 691.612 679.902 689.615Z"
                      fill="white"
                    />
                    <path
                      d="M641.372 721.73C644.769 719.285 648.178 717.837 651.599 717.384C655.008 717.008 658.284 717.565 661.427 719.055C664.525 720.577 667.311 723.061 669.784 726.508C672.193 729.866 673.623 733.259 674.075 736.686C674.558 740.158 674.16 743.426 672.881 746.491C671.557 749.588 669.373 752.232 666.329 754.423C663.417 756.519 660.465 757.672 657.473 757.883C654.48 758.094 651.592 757.425 648.809 755.877C645.982 754.36 643.396 751.967 641.05 748.697L639.576 746.642L664.92 728.4L667.44 731.913L645.669 747.583L646.11 745.96C648.646 749.495 651.405 751.663 654.388 752.464C657.359 753.341 660.344 752.7 663.344 750.541C665.594 748.922 667.132 747.01 667.96 744.807C668.775 742.679 668.913 740.401 668.376 737.974C667.871 735.591 666.746 733.184 665.003 730.753L664.717 730.356C662.784 727.66 660.732 725.686 658.562 724.433C656.349 723.212 654.033 722.735 651.615 723.001C649.229 723.312 646.845 724.324 644.462 726.039C642.565 727.404 640.941 729.009 639.588 730.854C638.223 732.774 637.238 734.991 636.633 737.504L632.07 735.059C632.574 732.686 633.666 730.292 635.346 727.877C637.025 725.462 639.034 723.413 641.372 721.73Z"
                      fill="white"
                    />
                    <path
                      d="M575.519 759.113C579.151 757.519 582.634 756.941 585.969 757.378C589.304 757.815 592.314 759.138 595 761.345C597.657 763.624 599.815 766.658 601.472 770.446C603.151 774.283 603.904 777.903 603.732 781.306C603.51 784.73 602.407 787.769 600.424 790.421C598.442 793.074 595.634 795.198 592.002 796.792C589.763 797.775 587.374 798.348 584.836 798.511C582.298 798.675 579.991 798.351 577.916 797.539L578.076 792.211C580.13 792.973 582.169 793.296 584.194 793.179C586.169 793.084 587.928 792.699 589.47 792.022C593.252 790.362 595.639 787.888 596.632 784.6C597.648 781.362 597.185 777.526 595.245 773.09C593.348 768.754 590.823 765.763 587.671 764.116C584.541 762.52 581.085 762.551 577.304 764.211C575.762 764.888 574.286 765.922 572.878 767.312C571.42 768.725 570.286 770.47 569.475 772.549L565.456 769.055C566.266 766.977 567.606 765.022 569.474 763.192C571.315 761.434 573.329 760.074 575.519 759.113Z"
                      fill="white"
                    />
                    <path
                      d="M538.314 772.795C540.925 772.046 543.417 771.868 545.791 772.263C548.127 772.724 550.149 773.672 551.859 775.106C553.569 776.54 554.738 778.355 555.367 780.552C556.175 783.377 556.105 785.802 555.159 787.829C554.161 789.87 552.114 791.703 549.018 793.327C545.871 794.966 541.425 796.61 535.68 798.259L532.155 799.27L530.966 795.112L534.412 794.123C537.598 793.208 540.231 792.339 542.313 791.516C544.394 790.692 545.986 789.839 547.09 788.956C548.194 788.073 548.887 787.138 549.17 786.151C549.453 785.165 549.415 784.043 549.055 782.788C548.442 780.643 547.21 779.101 545.358 778.16C543.455 777.235 541.224 777.139 538.665 777.874C536.576 778.473 534.89 779.495 533.608 780.938C532.273 782.397 531.409 784.117 531.015 786.098C530.569 788.094 530.667 790.216 531.311 792.466L533.869 801.411C534.797 804.654 536.116 806.794 537.825 807.832C539.55 808.922 541.9 809.04 544.877 808.185C547.175 807.526 549.297 806.549 551.243 805.254C553.205 804.012 555.099 802.337 556.926 800.227L560.443 804.227C559.355 805.559 558.046 806.812 556.517 807.986C555.003 809.213 553.359 810.279 551.584 811.185C549.808 812.091 548.059 812.791 546.336 813.286C543.046 814.23 540.173 814.46 537.718 813.976C535.277 813.544 533.221 812.38 531.548 810.482C529.89 808.637 528.575 806.015 527.602 802.615L520.735 778.605L526.688 776.896L529.224 785.763L528.519 785.965C528.368 783.857 528.691 781.925 529.489 780.168C530.233 778.426 531.355 776.915 532.854 775.636C534.353 774.357 536.173 773.41 538.314 772.795Z"
                      fill="white"
                    />
                    <path
                      d="M488.8 783.943C490.843 783.65 492.864 783.579 494.861 783.732C496.797 783.839 498.649 784.123 500.416 784.583C502.137 785.106 503.663 785.794 504.992 786.647L503.555 791.554C502.117 790.716 500.596 790.055 498.99 789.571C497.392 789.141 495.79 788.876 494.184 788.777C492.579 788.678 490.969 788.744 489.356 788.976C486.291 789.416 484.06 790.314 482.662 791.669C481.265 793.024 480.705 794.671 480.982 796.61C481.198 798.118 481.879 799.23 483.024 799.945C484.177 800.714 485.89 801.182 488.164 801.35L495.736 801.912C499.223 802.126 501.924 802.974 503.838 804.459C505.698 805.95 506.825 808.07 507.218 810.817C507.565 813.241 507.243 815.403 506.253 817.305C505.217 819.268 503.613 820.9 501.439 822.201C499.266 823.503 496.646 824.374 493.581 824.814C491.753 825.077 489.979 825.14 488.258 825.002C486.537 824.865 484.896 824.523 483.336 823.977C481.731 823.493 480.313 822.79 479.083 821.867L480.602 816.949C481.762 817.772 483.034 818.414 484.417 818.875C485.808 819.39 487.225 819.708 488.67 819.831C490.068 820.015 491.466 820.006 492.864 819.805C495.876 819.373 498.076 818.452 499.466 817.043C500.856 815.634 501.412 813.96 501.134 812.021C500.918 810.513 500.257 809.343 499.15 808.513C498.043 807.682 496.437 807.198 494.332 807.061L486.773 806.58C483.169 806.328 480.388 805.491 478.428 804.068C476.422 802.707 475.219 800.626 474.818 797.826C474.479 795.456 474.827 793.289 475.863 791.326C476.907 789.417 478.542 787.808 480.769 786.499C482.951 785.251 485.628 784.399 488.8 783.943Z"
                      fill="white"
                    />
                    <path
                      d="M456.103 788.57L456.233 846.113L449.632 846.128L449.572 819.437L450.632 819.435C449.498 822.594 447.656 824.965 445.107 826.549C442.503 828.187 439.517 829.01 436.149 829.017C432.998 829.025 430.389 828.459 428.323 827.321C426.201 826.183 424.622 824.445 423.585 822.108C422.548 819.825 422.025 816.915 422.017 813.378L421.961 788.647L428.562 788.632L428.616 812.955C428.622 815.404 428.952 817.416 429.608 818.993C430.209 820.569 431.189 821.737 432.549 822.496C433.854 823.255 435.566 823.632 437.685 823.627C440.075 823.622 442.166 823.1 443.956 822.062C445.746 821.024 447.128 819.579 448.102 817.727C449.076 815.874 449.56 813.697 449.554 811.194L449.503 788.585L456.103 788.57Z"
                      fill="white"
                    />
                    <path
                      d="M364.004 780.554C366.655 781.147 368.928 782.186 370.824 783.669C372.655 785.194 373.979 786.996 374.795 789.074C375.612 791.153 375.771 793.307 375.274 795.537C374.634 798.405 373.415 800.502 371.617 801.828C369.765 803.142 367.092 803.77 363.598 803.713C360.051 803.644 355.362 802.957 349.53 801.652L345.951 800.851L346.893 796.63L350.392 797.413C353.626 798.136 356.354 798.635 358.575 798.909C360.796 799.183 362.602 799.197 363.992 798.951C365.383 798.704 366.439 798.216 367.158 797.485C367.878 796.754 368.38 795.751 368.664 794.476C369.15 792.299 368.804 790.354 367.628 788.641C366.398 786.916 364.485 785.763 361.887 785.182C359.766 784.707 357.798 784.797 355.983 785.45C354.114 786.091 352.534 787.187 351.242 788.738C349.896 790.276 348.969 792.188 348.46 794.471L346.435 803.552C345.7 806.845 345.836 809.356 346.841 811.087C347.835 812.87 349.843 814.1 352.865 814.776C355.198 815.298 357.528 815.457 359.855 815.254C362.171 815.103 364.635 814.539 367.246 813.562L368.425 818.76C366.833 819.408 365.085 819.881 363.182 820.18C361.267 820.532 359.313 820.68 357.322 820.624C355.33 820.569 353.46 820.346 351.71 819.954C348.37 819.207 345.738 818.032 343.813 816.43C341.876 814.882 340.626 812.874 340.063 810.406C339.488 807.992 339.586 805.058 340.356 801.606L345.793 777.231L351.836 778.584L349.829 787.585L349.113 787.425C349.987 785.502 351.194 783.96 352.733 782.799C354.219 781.626 355.926 780.837 357.853 780.432C359.78 780.027 361.831 780.068 364.004 780.554Z"
                      fill="white"
                    />
                    <path
                      d="M316.492 767.101C320.29 768.42 322.773 770.549 323.941 773.488C325.057 776.41 324.886 779.979 323.427 784.195L308.911 826.148L302.674 823.983L317.031 782.492C317.653 780.693 317.947 779.096 317.912 777.702C317.826 776.289 317.402 775.106 316.64 774.15C315.878 773.195 314.779 772.467 313.342 771.968C312.726 771.755 312.127 771.575 311.544 771.431C310.893 771.32 310.284 771.252 309.717 771.228L311.686 766.037C312.56 766.168 313.4 766.316 314.206 766.481C314.96 766.627 315.722 766.834 316.492 767.101Z"
                      fill="white"
                    />
                    <path
                      d="M285.921 754.425C290.179 756.351 292.858 758.936 293.958 762.18C295.059 765.425 294.658 769.154 292.758 773.37L284.205 792.338L291.26 795.53L289.147 800.216L282.092 797.025L277.095 808.108L271.079 805.387L276.077 794.303L264.641 789.129L266.754 784.443L278.19 789.617L286.474 771.243C287.749 768.417 288.127 765.991 287.61 763.965C287.07 761.99 285.464 760.397 282.79 759.188C281.998 758.829 281.161 758.57 280.28 758.41C279.398 758.25 278.591 758.124 277.858 758.031L278.898 752.949C279.698 752.893 280.784 752.996 282.156 753.258C283.527 753.521 284.783 753.909 285.921 754.425Z"
                      fill="white"
                    />
                    <path
                      d="M253.694 737.054C257.272 739.224 259.94 741.797 261.698 744.772C263.381 747.765 264.155 751.001 264.02 754.482C263.838 757.934 262.65 761.475 260.455 765.106C258.316 768.643 255.762 771.292 252.792 773.053C249.794 774.861 246.637 775.776 243.32 775.8C239.957 775.796 236.672 774.821 233.466 772.876C230.399 771.016 228.182 768.748 226.814 766.074C225.446 763.399 224.927 760.476 225.257 757.304C225.54 754.105 226.723 750.783 228.805 747.338L230.114 745.174L256.809 761.367L254.572 765.068L231.64 751.157L233.304 750.926C231.053 754.65 230.145 758.043 230.579 761.106C230.939 764.187 232.699 766.686 235.859 768.602C238.229 770.04 240.588 770.707 242.936 770.605C245.21 770.521 247.356 769.755 249.374 768.308C251.364 766.907 253.133 764.926 254.68 762.366L254.934 761.948C256.65 759.108 257.658 756.443 257.957 753.953C258.21 751.434 257.739 749.113 256.546 746.99C255.325 744.913 253.46 743.114 250.95 741.592C248.952 740.38 246.841 739.513 244.616 738.99C242.317 738.486 239.895 738.448 237.35 738.877L237.804 733.713C240.182 733.247 242.809 733.314 245.685 733.913C248.562 734.513 251.231 735.56 253.694 737.054Z"
                      fill="white"
                    />
                    <path
                      d="M226.562 719.944L202.107 751.145L197.044 747.163L202.831 739.78L203.471 740.284C200.766 742.238 198.013 743.151 195.212 743.024C192.377 742.939 189.402 741.845 186.286 739.74L184.276 738.471L187.414 733.675L190.985 735.86C194.092 737.888 197.073 738.711 199.928 738.328C202.708 737.954 205.188 736.376 207.368 733.594L221.306 715.811L226.562 719.944Z"
                      fill="white"
                    />
                    <path
                      d="M198.572 697.473L170.424 725.375L165.896 720.792L172.151 714.592L172.781 715.23C169.739 716.638 166.76 716.987 163.845 716.28C160.852 715.571 158.171 714.018 155.802 711.62C153.586 709.377 152.157 707.119 151.516 704.845C150.798 702.571 150.906 700.244 151.841 697.863C152.775 695.483 154.497 693.048 157.007 690.56L174.556 673.164L179.199 677.863L161.939 694.973C160.201 696.695 159.002 698.343 158.341 699.917C157.641 701.453 157.499 702.972 157.914 704.475C158.29 705.939 159.224 707.425 160.714 708.933C162.395 710.635 164.233 711.761 166.228 712.31C168.222 712.859 170.22 712.831 172.221 712.226C174.221 711.62 176.11 710.437 177.886 708.677L193.929 692.773L198.572 697.473Z"
                      fill="white"
                    />
                    <path
                      d="M155.134 648.218C156.744 650.409 157.777 652.688 158.234 655.053C158.614 657.408 158.431 659.637 157.684 661.742C156.937 663.846 155.645 665.576 153.807 666.93C151.444 668.672 149.15 669.451 146.925 669.267C144.667 669.04 142.239 667.756 139.642 665.414C137.013 663.028 133.927 659.425 130.385 654.604L128.212 651.646L131.69 649.082L133.815 651.974C135.779 654.648 137.509 656.818 139.004 658.486C140.499 660.154 141.852 661.353 143.062 662.082C144.272 662.811 145.389 663.137 146.411 663.059C147.433 662.982 148.469 662.556 149.519 661.782C151.313 660.459 152.327 658.765 152.563 656.7C152.767 654.59 152.08 652.462 150.503 650.314C149.215 648.561 147.672 647.334 145.875 646.632C144.046 645.886 142.135 645.672 140.144 645.992C138.121 646.267 136.168 647.098 134.287 648.485L126.805 654C124.092 656 122.548 657.983 122.172 659.949C121.751 661.948 122.459 664.196 124.294 666.695C125.711 668.623 127.364 670.276 129.253 671.653C131.098 673.063 133.325 674.259 135.935 675.24L133.415 679.935C131.79 679.376 130.162 678.584 128.531 677.557C126.856 676.562 125.286 675.388 123.82 674.036C122.355 672.684 121.091 671.285 120.028 669.839C118 667.078 116.785 664.46 116.384 661.986C115.939 659.544 116.313 657.208 117.507 654.977C118.657 652.778 120.653 650.63 123.497 648.534L143.58 633.73L147.25 638.726L139.834 644.193L139.4 643.602C141.32 642.727 143.241 642.358 145.163 642.495C147.052 642.588 148.857 643.116 150.575 644.079C152.294 645.041 153.814 646.421 155.134 648.218Z"
                      fill="white"
                    />
                    <path
                      d="M126.318 603.615C128.617 607.689 129.254 611.36 128.228 614.629C127.202 617.898 124.679 620.67 120.659 622.946L102.57 633.188L106.38 639.938L101.911 642.468L98.1014 635.718L87.5318 641.702L84.284 635.947L94.8536 629.962L88.6787 619.02L93.1478 616.489L99.3226 627.432L116.844 617.512C119.54 615.985 121.276 614.252 122.053 612.312C122.783 610.399 122.426 608.163 120.983 605.605C120.555 604.847 120.033 604.143 119.416 603.492C118.799 602.841 118.222 602.261 117.686 601.753L121.522 598.268C122.201 598.696 123.016 599.422 123.968 600.446C124.92 601.469 125.704 602.526 126.318 603.615Z"
                      fill="white"
                    />
                    <path
                      d="M72.5319 608.661L65.7911 611.756L62.396 604.336L69.1368 601.241L72.5319 608.661ZM117.156 587.276L81.1554 603.804L78.4053 597.794L114.406 581.266L117.156 587.276Z"
                      fill="white"
                    />
                    <path
                      d="M103.059 553.241L70.9994 582.107L68.7025 575.303L97.0443 550.712L97.7491 552.799L60.2457 550.251L58.0793 543.834L101.075 547.365L103.059 553.241Z"
                      fill="white"
                    />
                    <path
                      d="M89.6321 500.345C90.3974 504.464 90.2572 508.171 89.2116 511.466C88.1027 514.716 86.2069 517.45 83.5243 519.666C80.8317 521.828 77.4025 523.298 73.2369 524.074C69.178 524.831 65.5041 524.685 62.2152 523.638C58.8729 522.601 56.0888 520.85 53.863 518.387C51.6272 515.87 50.1665 512.766 49.4807 509.075C48.8247 505.545 49.0375 502.378 50.1192 499.575C51.2008 496.772 53.0327 494.438 55.6147 492.573C58.1868 490.654 61.4489 489.326 65.401 488.59L67.8844 488.127L73.5943 518.86L69.3485 519.652L64.4436 493.251L65.7236 494.341C61.4511 495.138 58.3188 496.718 56.3267 499.082C54.2713 501.402 53.5815 504.381 54.2574 508.019C54.7643 510.747 55.8375 512.955 57.4771 514.642C59.0533 516.285 61.0523 517.379 63.4743 517.924C65.8429 518.479 68.4958 518.482 71.4331 517.935L71.9138 517.845C75.1716 517.238 77.8276 516.217 79.8818 514.782C81.9261 513.294 83.3419 511.397 84.1292 509.092C84.8631 506.797 84.9617 504.205 84.425 501.316C83.9976 499.015 83.238 496.86 82.1462 494.85C80.991 492.796 79.4067 490.96 77.3933 489.343L81.5421 486.245C83.4723 487.712 85.1714 489.719 86.6396 492.269C88.1078 494.818 89.1053 497.51 89.6321 500.345Z"
                      fill="white"
                    />
                    <path
                      d="M65.419 450.615C65.4777 452.017 65.2032 453.318 64.5953 454.519C63.9589 455.721 63.0843 456.704 61.9716 457.467C60.859 458.23 59.5885 458.642 58.1602 458.702C56.8462 458.757 55.604 458.48 54.4338 457.87C53.2339 457.232 52.2522 456.342 51.4889 455.199C50.6971 454.057 50.27 452.742 50.2076 451.254C50.1501 449.881 50.4253 448.594 51.0331 447.393C51.6398 446.163 52.5138 445.166 53.655 444.402C54.7677 443.638 56.0524 443.226 57.5093 443.165C58.909 443.106 60.1952 443.41 61.3678 444.078C62.5392 444.716 63.4923 445.608 64.227 446.752C64.9617 447.897 65.3591 449.184 65.419 450.615Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
            </SectionWrapper> */}
            <SectionWrapper bg="bg-2">
              <TicketList
                reference={ticketSelectorRef}
                prizeId={prizeId}
                subscriptionModels={subscriptionModels}
                raffles={activeRaffles.length > 0 ? activeRaffles : raffles}
                isExpired={isExpired}
                currentRaffle={raffle}
                title={data.ticketSelector.title}
                subtitle={data.ticketSelector.subtitle}
                theme="light"
              />
            </SectionWrapper>
            <SectionWrapper bg="bg-1">
              <CharityBlock
                onCTAClick={onCTAClick}
                onLearnMoreClick={onLearnMoreClick}
                onLearnMoreCharityClick={onLearnMoreCharityClick}
              />
            </SectionWrapper>
            <SectionWrapper bg="bg-2">
              <TimelineBlock
                data={data.stepper}
                title={""}
                subtitle={""}
                widget={widgets.timelineWidget}
                onCTAClick={onCTAClick}
              />
            </SectionWrapper>
            <SectionWrapper bg="bg-1">
              <TicketList
                prizeId={prizeId}
                subscriptionModels={subscriptionModels}
                raffles={activeRaffles.length > 0 ? activeRaffles : raffles}
                isExpired={isExpired}
                currentRaffle={raffle}
                title={data.ticketSelector.title}
                subtitle={data.ticketSelector.subtitle}
                theme="dark"
              />
            </SectionWrapper>
            <Footer insideComponent={true} widget={widgets.footerWidget} />
            <MobileCTAButtonBottom
              variant='variant-2'
              // onClick={onEnterNowClick}
              onClick={handleScroll}
              currentRaffle={raffle}
              withCountdown
              raffles={activeRaffles.length > 0 ? activeRaffles : raffles}
            />
          </main>
        </div>
      ) : (
        <main className="no-prize">
          <h1>No prize</h1>
        </main>
      )}
    </>
  );
};

export default Main;
