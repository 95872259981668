import React, { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
// import { withRouter } from 'react-router-dom';

// class ScrollToTop extends React.Component {
//   componentDidUpdate(prevProps) {
//     if (this.props.location !== prevProps.location) {
//       window.scrollTo(0, 0); // This will stop at 12 or 13 to the top due to the address bar being shown again
//       setTimeout(() => {
//         window.scrollTo(0, 0); // scroll to top again after 25ms!
//       }, 25);
//     }
//   }

//   render() {
//     return this.props.children;
//   }
// }

const ScrollToTop = (props) => {
  const { pathname } = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0); // This will stop at 12 or 13 to the top due to the address bar being shown again
    setTimeout(() => {
      window.scrollTo(0, 0); // scroll to top again after 25ms!
    }, 25);
  }, [pathname]);
  return props.children;
};

export default ScrollToTop;
