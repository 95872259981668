import React, { useEffect, useState } from 'react';
import './styles.css';
import MobileBottomButtonCountdown from '../button-bottom-countdown';
import dayjs from 'dayjs';
const MobileCTAButtonBottom = (props) => {
  const [stepperCountdown, setStepperCountdown] = useState(null);
  useEffect(() => {
    if (props.raffles && props.raffles.length > 0) {
      const raffle = props.raffles[0];
      let activeRaffle = raffle;
      const activeRaffles = props.raffles.filter(
        (raffle) => new Date(raffle.endsAt) > new Date()
      );
      if (activeRaffles.length > 0) {
        activeRaffle = activeRaffles[0];
      } else {
        activeRaffle = props.raffles[props.raffles.length - 1];
      }

      if (activeRaffle) {
        setStepperCountdown(activeRaffle.stepperCountdown);
        // stepperCountdown = activeRaffle.stepperCountdown;
      }
    }
    // let value = stepperCountdown.title;
  }, [props.raffles]);

  return (
    <div
      className={`bottom-cta-button-mobile-container ${
        props.variant ? props.variant : ''
      }`}
      onClick={props.onClick}
    >
      {stepperCountdown &&
        props.withCountdown &&
        dayjs(stepperCountdown.endsAt).isAfter(dayjs(new Date())) && (
          <MobileBottomButtonCountdown
            stepperCountdown={stepperCountdown}
            prizeType='not-raffle'
            endsAt={stepperCountdown.endsAt}
          />
        )}

      {props.buttonText ? props.buttonText : 'Enter now'}
    </div>
  );
};

export default MobileCTAButtonBottom;
