import React from 'react';
import './TicketList.css';

import TicketCard from '../TicketCard/TicketCard';
import PostalEntry from '../PostalEntry/PostalEntry';
import SubscriptionEntry from '../SubscriptionEntry/SubscriptionEntry';
import ReactHtmlParse from 'html-react-parser';
import useTickets from '../../hooks/useTickets';
import {TitleText, SubtitleText} from "raffle-house-uikit";
import NewPrizeCard from '../common/NewTicketCard';

const TicketList = (props) => {
  const {
    prizeId,
    raffles,
    isExpired,
    currentRaffle,
    subscriptionModels,
    title,
    subtitle,
    theme = 'light',
    reference,
  } = props;
  const {
    type,
    tickets,
    loading,
    activeTicket,
    addToBasket,
    result,
    ticketsBundles,
    settings,
  } = useTickets(prizeId);

  return (
    <div className='ticket-container' ref={reference}>
      {title || subtitle ? (
        <div className="ticket-text-container">
          {Boolean(title) && (
            <TitleText
              isCentered
              as="h2"
              variant={"primary"}
              color={`${theme === "light" ? "color-1" : "color-5"}`}
            >
              {title ? ReactHtmlParse(title) : null}
            </TitleText>
          )}
          {Boolean(subtitle) && (
            <SubtitleText
              isCentered
              color={`${theme === "light" ? "color-1" : "color-5"}`}
            >
              {subtitle ? ReactHtmlParse(subtitle) : null}
            </SubtitleText>
          )}
        </div>
      ) : null}
      <div className='ticket-list'>
        <>
          {prizeId &&
            ticketsBundles?.map((ticket, index) => {
              let label = '';
              if (ticket === 15) {
                label = 'MOST POPULAR';
              }
              if (ticket === 25) {
                label = 'LOWEST TICKET PRICE';
              }
              // if (ticket === 5) {
              //   return (
              //     <React.Fragment>
              //       <TicketCard
              //         type={type}
              //         key={index}
              //         activeTicket={activeTicket}
              //         ticket={ticket}
              //         ticketsCount={ticket}
              //         loading={loading}
              //         onClick={addToBasket}
              //         raffles={raffles}
              //         isExpired={isExpired}
              //         currentRaffle={currentRaffle}
              //         theme={theme}
              //         label={label}
              //         result={result}
              //       />
              //       {subscriptionModels.map((sub, index) => {
              //         return (
              //           <SubscriptionEntry
              //             key={index}
              //             type={'win'}
              //             raffles={raffles}
              //             loading={loading}
              //             sub={sub}
              //             pricePerMonth={`£${sub.totalCost / 100}`}
              //             ticket={{ tickets: sub.numOfTickets + sub.extra }}
              //             theme={theme}
              //           />
              //         );
              //       })}
              //     </React.Fragment>
              //   );
              // }
              return (
                <NewPrizeCard
                  type={type}
                  key={index}
                  activeTicket={activeTicket}
                  ticket={ticket}
                  ticketsCount={ticket}
                  loading={loading}
                  onClick={addToBasket}
                  raffles={raffles}
                  isExpired={isExpired}
                  currentRaffle={currentRaffle}
                  theme={theme}
                  label={label}
                  result={result}
                />
              );
            })}
          <PostalEntry loading={loading} theme={raffles.length > 1 ? 'light' : theme} />
        </>
      </div>
    </div>
  );
};

export default TicketList;
