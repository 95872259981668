export const tickets = [
  {
    id: '1',
    title: '900 London tickets + 900 Home Farm tickets',
    numOfTickets: 150,
    prizeType: 'raffle',
    tickets: 2000,
    totalCost: 300,
    discount: 75,
    discountValue: 0.25,
  },
  {
    id: '2',
    title: '250 London tickets + 250 Home Farm tickets',
    numOfTickets: 50,
    prizeType: 'raffle',
    tickets: 500,
    totalCost: 100,
    discount: 50,
    discountValue: 0.5,
  },
  {
    id: '3',
    title: '100 London tickets + 100 Home Farm tickets',
    numOfTickets: 15,
    prizeType: 'raffle',
    tickets: 50,
    totalCost: 25,
    discount: 20,
    discountValue: 0.8,
  },
  {
    id: '4',
    title: '100 London tickets + 100 Home Farm tickets',
    numOfTickets: 5,
    prizeType: 'raffle',
    tickets: 15,
    totalCost: 10,
    discount: 0,
    discountValue: 1,
  },
];

export const pagediscountTickets = [
  {
    id: '1',
    title: '900 London tickets + 900 Home Farm tickets',
    numOfTickets: 150,
    prizeType: 'raffle',
    tickets: 2000,
    totalCost: 300,
    discount: 75,
    discountValue: 0.25,
  },
  {
    id: '2',
    title: '250 London tickets + 250 Home Farm tickets',
    numOfTickets: 50,
    prizeType: 'raffle',
    tickets: 500,
    totalCost: 100,
    discount: 50,
    discountValue: 0.5,
  },
  {
    id: '3',
    title: '100 London tickets + 100 Home Farm tickets',
    numOfTickets: 15,
    prizeType: 'raffle',
    tickets: 50,
    totalCost: 25,
    discount: 20,
    discountValue: 0.8,
  },
];
