import dayjs from 'dayjs';

export const calculateTotalCost = (order) => {
  return '£' + (order.pricePerTicket * order.numOfTickets) / 100;
};

export const calculatePerTicketCost = (order) => {
  const result = calculateDiscountPrice(order) / order.numOfTickets;
  const converted = result > 100 ? result * 100 : result;
  const price = converted.toString().includes('.')
    ? converted.toFixed(2)
    : converted;
  const icon = price >= 1 ? '£' : 'p';
  return price >= 1
    ? `${icon}${price} per ticket`
    : `${(price * 100).toFixed(0)}${icon} per ticket`;
};

export const calculateDiscountPrice = (order) => {
  return order.totalCost * order.discountValue;
};

/**
 * Reference: http://embed.plnkr.co/YovdOb/
 */
export const dateDiff = (startdate, enddate, prizeType) => {
  const startdateMoment = dayjs(startdate);
  const enddateMoment = dayjs(enddate);

  if (startdateMoment.isValid() === true && enddateMoment.isValid() === true) {
    let monthsString;
    let addMonthToDate;

    const months = enddateMoment.diff(startdateMoment, 'months');
    monthsString = months >= 10 ? '' + months : '0' + months;
    addMonthToDate = startdateMoment.add(months, 'months');

    const days = enddateMoment.diff(addMonthToDate, 'days');
    const daysString = days >= 10 ? '' + days : '0' + days;

    let addDaysToDate = addMonthToDate.add(days, 'days');
    const hours = enddateMoment.diff(addDaysToDate, 'hours');
    const hoursString = hours >= 10 ? '' + hours : '0' + hours;

    let addHoursToDate = addDaysToDate.add(hours, 'hours');
    const minutes = enddateMoment.diff(addHoursToDate, 'minutes');
    const minutesString = minutes >= 10 ? '' + minutes : '0' + minutes;

    let addMinutesToDate = addHoursToDate.add(minutes, 'minutes');
    const seconds = enddateMoment.diff(addMinutesToDate, 'seconds');
    const secondsString = seconds >= 10 ? '' + seconds : '0' + seconds;

    if (prizeType !== 'raffle') {
      return {
        Days: daysString,
        Hours: hoursString,
        Minutes: minutesString,
        Seconds: secondsString,
      };
    } else {
      return {
        Months: monthsString,
        Days: daysString,
        Hours: hoursString,
        Minutes: minutesString,
        Seconds: secondsString,
      };
    }
  }

  return {
    Months: null,
    Days: '--',
    Hours: '--',
    Minutes: '--',
    Seconds: '--',
  };
};

const MINUTE = 1000 * 60;

export const checkDayTimeout = ({
  timeout,
  callbackSuccess,
  callbackError,
}) => {
  const now = new Date().getTime();

  const blockTimeout = new Date(timeout);
  blockTimeout.setDate(blockTimeout.getDate() + 1);

  if (now < blockTimeout.getTime()) {
    if (callbackError) {
      return callbackError();
    }

    return setTimeout(() => {
      return checkDayTimeout({ timeout, callbackSuccess });
    }, MINUTE);
  }

  if (callbackSuccess) {
    return callbackSuccess();
  }
};

export const getDate = (date) => {
  if (!date) {
    return '';
  }
  let currentDate = date.toLocaleDateString();
  let currentTime = new Date(date).toLocaleTimeString();
  const arr = currentTime.split(':');
  let hours = parseInt(arr[0]);
  let midnight = hours >= 12 ? 'PM' : 'AM';
  if (navigator.platform.indexOf('Mac') > -1) {
    midnight = '';
  }
  if (hours > 12) {
    hours = hours - 12;
  }
  currentTime = hours.toString() + ':' + arr[1] + ':' + arr[2] + ' ' + midnight;
  const result = currentDate + ',' + currentTime;
  return result;
};
