import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/reset.css';
import './assets/styles/styles.css';
import './index.css';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './components/scroll-to-top';
// Build the store and history.

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter
    onUpdate={() => {
      window.scrollTo(0, 0); // This will stop at 12 or 13 to the top due to the address bar being shown again
      setTimeout(() => {
        window.scrollTo(0, 0); // scroll to top again after 25ms!
      }, 25);
      // This should be any number larger than 16.8
    }}
  >
    <ScrollToTop>
      <App />
    </ScrollToTop>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
