import React from 'react';
import Slider from 'react-slick';
import Marquee from 'react-fast-marquee';
import image1 from "../../assets/images/partners-image/bbc-new.png";
import image2 from "../../assets/images/partners-image/business-new.png";
import image3 from "../../assets/images/partners-image/buy-new.png";
import image4 from "../../assets/images/partners-image/calvinayre-new.png";
import image5 from "../../assets/images/partners-image/daily_mail-new.png";
import image6 from "../../assets/images/partners-image/douthlondon-new.png";
import image7 from "../../assets/images/partners-image/eastate-new.png";
import image8 from "../../assets/images/partners-image/eugaming-new.png";
import image9 from "../../assets/images/partners-image/evening-new.png";
import image10 from "../../assets/images/partners-image/findmy-new.png";
import image11 from "../../assets/images/partners-image/houseb-new.png";
import image12 from "../../assets/images/partners-image/inside-new.png";
import image13 from "../../assets/images/partners-image/metro-new.png";
import image14 from "../../assets/images/partners-image/mirror-new.png";
import image15 from "../../assets/images/partners-image/moneymagpie-new.png";
import image16 from "../../assets/images/partners-image/portal_watch-new.png";
import image17 from "../../assets/images/partners-image/quax-new.png";
import image18 from "../../assets/images/partners-image/sundat_express-new.png";
import image19 from "../../assets/images/partners-image/the_assisted-new.png";
import image20 from "../../assets/images/partners-image/the_sun-new.png";
import image21 from "../../assets/images/partners-image/the_telegraph-new.png";
import image22 from "../../assets/images/partners-image/this_is_money-new.png";
import image23 from "../../assets/images/partners-image/ukf-new.png";
import image24 from "../../assets/images/partners-image/which-new.png";
import image25 from "../../assets/images/partners-image/mylondon-new.png";

const PartnersSlider = (props) => {
  const config = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    arrows: false,
    lazyLoad: 'progressive',
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const images = [
    { src: image1 },
    { src: image2 },
    { src: image3 },
    { src: image4 },
    { src: image5 },
    { src: image6 },
    { src: image7 },
    { src: image8 },
    { src: image9 },
    { src: image10 },
    { src: image11 },
    { src: image12 },
    { src: image13 },
    { src: image14 },
    { src: image15 },
    { src: image16 },
    { src: image17 },
    { src: image18 },
    { src: image19 },
    { src: image20 },
    { src: image21 },
    { src: image22 },
    { src: image23 },
    { src: image24 },
    { src: image25 },
  ];

  let imagesArray = props.images ? props.images : images;
  if (props.isTicker) {
    return (
      <Marquee speed={70} autoFill>
        {imagesArray.map((x, i) => {
          return (
            <div key={i} data-index={i} className={props.isCharitySlider ? 'charity-rounded-image' : undefined}>
              <img
                alt="partner"
                src={x.src}
                style={{ marginLeft: props.isCharitySlider ? '0px' : '40px' }}
                className={x.isRounded ? 'rounded' : undefined}
              />
            </div>
          );
        })}
      </Marquee>
    );
  }
  return (
    <Slider
      {...config}
      className={props.isDark ? 'partnersSlider dark slick-custom-dots' : 'partnersSlider slick-custom-dots'}
    >
      {imagesArray.map((x, i) => {
        return (
          <div key={i} data-index={i} className={props.isCharitySlider ? 'charity-rounded-image' : undefined}>
            <img alt="partner" src={x.src} />
          </div>
        );
      })}
    </Slider>
  );
};

export default PartnersSlider;
