import React from 'react';
import ticketsService from '../../services/tickets';
import './PostalEntry.css';


const PostalEntry = ({ loading, theme }) => {
  return (
    <div className={`postal-card ${theme === 'light' ? 'postal-card_white' : 'postal-card_green'}`}>
      <div className='info'>
        <div className='details details-rows'>
          <p className="details-row">Postal entry</p>
        </div>
        <div className='price'>
          {/* <p>More Info</p> */}
          <p>No purchase necessary</p>
        </div>
      </div>
      <button onClick={ticketsService.goToPost} disabled={loading} className="postal-card__btn">
        <>
         More info
        </>
      </button>
    </div>
  );
};

export default PostalEntry;
